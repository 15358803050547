import React from 'react';
import WrapHeader from './Header';

//import WrapListsOfMoviesSQ from './movieslist/ListsOfMoviesSQ';
import WrapListsOfMovies from './movieslist/ListsOfMovies';
import WrapMovieInfoDialog from '../Info/MovieInfoDialog';
import WrapTVInfoDialog from '../Info/TVInfoDialog';
import { connect } from 'react-redux';
import { unstable_Box as Box } from '@material-ui/core/Box';
import BounceLoader from 'react-spinners/SyncLoader';
import './NewBrowser.css';


class Browser extends React.Component {
  state = {
    open: true,
  };

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = value => {
    this.setState({ open: false });
  };

  shouldComponentUpdate(nextProps, nextState) {
    //console.log('Browser: showMovieInfo: ', this.props.showMovieInfo, this.props.movieID)
    /*if ( (nextProps.currentUser.pending != this.props.currentUser.pending)  || 
       (nextProps.showMovieInfo != this.props.showMovieInfo) || 
        (nextProps.showTVInfo != this.props.showTVInfo) ) 

      return true;
    else 
      return false; */
    return true;
  }

  render() {
    /*//console.log(
      'Browser render shuffle: ',
      this.props.currentUser.pending,
      this.props.currentUser.uid,
      this.props.movieID,
      this.props.movieShuffleSeed,
    );*/

    //console.log('Browser: showMovieInfo: ', this.props.currentUser.currentfilter);
    return (
      <div>
        {/*!this.props.currentUser.pending ? (
          <WrapListsOfMovies username={this.props.currentUser.uid} filterChoices={this.props.filterChoices} movieShuffleSeed={this.props.movieShuffleSeed} />
        ) : null*/}
        {(!this.props.currentUser.pending) ? (
          <div>
            <WrapListsOfMovies username={this.props.currentUser.uid} region={this.props.region} filterChoices={this.props.currentUser.currentfilter} movieShuffleSeed={this.props.movieShuffleSeed} />
            {/*<WrapListsOfMoviesSQ username={this.props.currentUser.uid} filterChoices={this.props.filterChoices} movieShuffleSeed={this.props.movieShuffleSeed} />*/}
            {/* FIXME: Should only call if showMovie is true - but this fails to update timeline */}

            <WrapMovieInfoDialog open={this.props.showMovieInfo} movieID={this.props.movieID} region={this.props.region} />
            <WrapTVInfoDialog open={this.props.showTVInfo} region={this.props.region} />

          </div>) :

          <div className="moviesLoading">
            <BounceLoader />
            <div className="loadingText">Content Loading</div>
          </div>
        }

      </div>
    );
  }
}



const mapStateToProps = state => {
  return {
    movieID: state.movies_redux.movieID,
    showMovieInfo: state.movies_redux.showMovieInfo,
    showTVInfo: state.movies_redux.showTVInfo,
    currentUser: state.currentUser,
  };
};



const WrapBrowser = connect(mapStateToProps)(Browser);

export default WrapBrowser;


