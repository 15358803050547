// src/js/actions/index.js

// MOVIE INFO
import FireBaseTools from '../../utils/firebase';
import * as actionTypes from '../constants/action-types';


export function showMovieInfo() {
  return { type: actionTypes.SHOW_MOVIEINFO };
}

export function showTVInfo() {
  return { type: actionTypes.SHOW_TVINFO };
}

export function closeMovieInfo() {
  return { type: actionTypes.CLOSE_MOVIEINFO };
}

export function closeTVInfo() {
  return { type: actionTypes.CLOSE_TVINFO };
}

export function setMovieID(payload) {
  return { type: actionTypes.SET_MOVIEID, payload };
}

export function setTVID(payload) {
  return { type: actionTypes.SET_TVID, payload };
}

export function setMovieInfo(payload) {
  return { type: actionTypes.SET_MOVIEINFO, payload };
}

export function setTVInfo(payload) {
  return { type: actionTypes.SET_TVINFO, payload };
}

export function setMovieSQLInfo(payload) {
  return { type: actionTypes.SET_MOVIESQLINFO, payload };
}

export function setCreditsInfo(payload) {
  return { type: actionTypes.SET_CREDITSINFO, payload };
}

export function setVideoInfo(payload) {
  return { type: actionTypes.SET_VIDEOINFO, payload };
}

export function setBlockedInfo(payload) {
  return { type: actionTypes.SET_BLOCKEDINFO, payload };
}

export function setTVSeriesInfo(payload) {
  return { type: actionTypes.SET_TVSERIESINFO, payload };
}

export function addBookmarkTitle(payload) {
  return { type: actionTypes.ADD_BOOKMARKTITLE, payload };
}

export function delBookmarkTitle(payload) {
  return { type: actionTypes.DEL_BOOKMARKTITLE, payload };
}

export function initBookmarkTitle(payload) {
  return { type: actionTypes.INIT_BOOKMARKTITLE, payload };
}

export function bookmarkDBAddSuccess(payload) {
  return { type: actionTypes.ADD_BOOKMARKTITLE_SUCCESS, payload };
}

export function bookmarkDBAddFailure(payload) {
  return { type: actionTypes.ADD_BOOKMARKTITLE_FAILURE, payload };
}

export function zeroBookmarkTitle() {
  return { type: actionTypes.ZERO_BOOKMARKTITLE};
}



// USER AUTH


export function userLoginRequest(user) {
  return {
    type: actionTypes.USER_LOGIN_REQUEST,
    payload: user,
  };
}

export function userLoginSuccess(user) {
  return {
    type: actionTypes.USER_LOGIN_SUCCESS,
    payload: user,
  };
}

export function userLoginFailure(error) {
  return {
    type: actionTypes.USER_LOGIN_FAILURE,
    error,
  };
}

// / AUTHENTICATION WITH PROVIDER
export function userLoginWithProviderRequest(user) {
  return {
    type: actionTypes.USER_LOGIN_WITH_PROVIDER_REQUEST,
    payload: user,
  };
}
export function userLoginWithProviderSuccess(user) {
  return {
    type: actionTypes.USER_LOGIN_WITH_PROVIDER_SUCCESS,
    payload: user,
  };
}

export function userLoginWithProviderFailure(error) {
  return {
    type: actionTypes.USER_LOGIN_WITH_PROVIDER_FAILURE,
    error,
  };
}

// USER REGISTER

export function registerUser(user) {
  return {
    type: actionTypes.REGISTER_FIREBASE_USER,
    payload: user,
  };
}


export function userRegisterSuccess(user) {
  return {
    type: actionTypes.USER_REGISTER_SUCCESS,
    payload: user
  };
}

export function registerUserWithProvider(provider) {
  return {
    type: actionTypes.REGISTER_FIREBASE_USER_WITH_PROVIDER,
    payload: provider,
  };
}


export function userRegisterWithProviderSuccess(user) {
  return {
    type: actionTypes.USER_REGISTER_WITH_PROVIDER_SUCCESS,
    payload: user
  };
}

export function userRegisterWithProviderFailure(error) {
  return {
    type: actionTypes.USER_REGISTER_WITH_PROVIDER_FAILURE,
    error
  };
}

// END OF AUTH WITH PROVIDER

//  FETCH USER
export function fetchUserRequest() {
  //console.log('redux-actions: USER_FETCH_REQUEST')
  return {
    type: actionTypes.USER_FETCH_REQUEST,
  };
}

export function fetchUserSuccess(user) {
  return {
    type: actionTypes.USER_FETCH_SUCCESS,
    payload: user,
  };
}
export function fetchUserFailure() {
  return {
    type: actionTypes.USER_FETCH_FAILURE,
  };
}


// USER DB


export function fetchUserDBSuccess(user) {
  return {
    type: actionTypes.USERDB_FETCH_SUCCESS,
    payload: user,
  };
}

export function fetchUserDBSuccessWithFilter(user) {
  return {
    type: actionTypes.USERDB_FETCH_SUCCESS_WITH_FILTER,
    payload: user,
  };
}

export function userDBAddSuccess(user) {
  return {
    type: actionTypes.USERDB_ADD_SUCCESS,
    payload: user,
  };
}


/*export function fetchUserDBFailure() {
  return {
    type: actionTypes.USERDB_FETCH_FAILURE,
  };
}*/

// FETCH COUNTRY

export function fetchCountrySuccess(user) {
  return {
    type: actionTypes.COUNTRY_FETCH_SUCCESS,
    payload: user,
  };
}
export function fetchCountryFailure() {
  return {
    type: actionTypes.COUNTRY_FETCH_FAILURE,
  };
}



export function setRegion(payload) {
  return { type: actionTypes.SET_REGION, payload };
}



// LOGOUT user
export function userLogoutRequest() {
  return {
    type: actionTypes.USER_LOGOUT_REQUEST,
  };
}

export function userLogoutSuccess() {
  return {
    type: actionTypes.USER_LOGOUT_SUCCESS,
  };
}
export function userLogoutFailure(error) {
  return {
    type: actionTypes.USER_LOGIN_FAILURE,
    error,
  };
}




// / UPDATE USER
export function userUpdateRequest(data) {
  return {
    type: actionTypes.USER_UPDATE_REQUEST,
    payload: data
  };
}


export function userUpdateSuccess(data) {
  return {
    type: actionTypes.USER_UPDATE_SUCCESS,
    payload: data
  };
}
export function userUpdateFailure(error) {
  return {
    type: actionTypes.USER_UPDATE_FAILURE,
    payload: error,
  };
}




// UPDATE PASSWORD

export function userUpdatePassword(data ) {
  return {
    type: actionTypes.CHANGE_FIREBASE_USER_PASSWORD,
    payload: data
  };
}

export function passwordUpdateSuccess(data) {
  return {
    type: actionTypes.PASSWORD_UPDATE_SUCCESS,
    payload: data
  };
}

export function passwordUpdateFailure(error) {
  return {
    type: actionTypes.PASSWORD_UPDATE_FAILURE,
    payload: error,
  };
}

export function resetPasswordEmail(email) {
  //console.log('firebase_actions: email: ', email);
  const request = FireBaseTools.resetPasswordEmail(email);
  return {
    type: actionTypes.FIREBASE_PASSWORD_RESET_EMAIL,
    payload: request,
  };
}

// USER FILTERS

export function userUpdateFilter(data ) {
  //console.log('actions: USER_FILTER_UPDATE: ', data)
  return {
    type: actionTypes.USER_FILTER_UPDATE,
    payload: data
  };
}


export function userSaveFilter(data ) {
  //console.log'actions: USER_FILTER_SAVE: ', data)
  return {
    type: actionTypes.USER_FILTER_SAVE,
    payload: data
  };
}

/*
export function userAddFilter(data ) {
  //console.log'actions: USER_FILTER_ADD: ', data)
  return {
    type: actionTypes.USER_FILTER_ADD,
    payload: data
  };
}*/


export function userSelectFilter(data ) {
  //console.log'actions: USER_FILTER_SELECT: ', data)
  return {
    type: actionTypes.USER_FILTER_SELECT,
    payload: data
  };
}


export function userDelFilter(data ) {
  //console.log'actions: USER_FILTER_DEL: ', data)
  return {
    type: actionTypes.USER_FILTER_DEL,
    payload: data
  };
}

export function userAddFilter(data ) {
  //console.log'actions: USER_FILTER_ADD: ', data)
  return {
    type: actionTypes.USER_FILTER_ADD,
    payload: data
  };
}

export function userAddFilterRequest(data ) {
  //console.log'actions: USER_FILTER_ADD_REQUEST: ', data)
  return {
    type: actionTypes.USER_FILTER_ADD_REQUEST,
    payload: data
  };
}


export function userAddFilterSuccess(data ) {
  //console.log'actions: USER_ADD_FILTER_SUCCESS: ', data)
  return {
    type: actionTypes.USER_FILTER_ADD_SUCCESS,
    payload: data
  };
}

export function userAddFilterFailure(data ) {
  //console.log'actions: USER_ADD_FILTER_FAILURE: ', data)
  return {
    type: actionTypes.USER_FILTER_ADD_FAILURE,
    payload: data
  };
}



