import React, { Component } from 'react';
import { Router, browserHistory } from 'react-router';

import { connect } from 'react-redux';
//import { resetPasswordEmail } from '../../../redux/actions/firebase_actions';
import { resetPasswordEmail } from '../../../redux/actions'

import { Redirect } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

// <SignUpLink />

const styles = theme => ({
  container: {
    margin: 'auto',
    marginTop: 100,
    maxWidth: 400,
    color: '#222222',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#EEEEEE',
  },

  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    minWidth: 300,
  },

  button: {
    margin: theme.spacing.unit,
    textTransform: 'none',
  },

  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      email: '',
      goHome: false,
      cancel: false,
      open: false,
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  handleClose = () => {
    this.props.OnDone();
    this.setState({ open: false, goHome: true });
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  onFormSubmit(event) {
    event.preventDefault();
    const email = this.state.email;
    //console.log('ResetPassword: email', email);
    this.props.resetPasswordEmail(email);

    this.setState({ open: true });
    //this.setState({ message: 'Please see your email!' })
    /*this.props.resetPasswordEmail(email).then((data) => {
        if (data.payload.errorCode) {
            this.setState({ message: data.payload.errorMessage });
        } else {
            this.setState({ message: 'Please see your email!' });
        }
    });*/
  }

  shouldComponentUpdate(nextProps, nextState) {
    //console.log('Signin: ShouldUpdate');
    return true;
  }

  handleClose = () => {
    this.setState({ goHome: true, cancel: true });
    this.props.OnDone();
  };

  render() {
    const { classes, ...other } = this.props;

    if (this.state.goHome || this.state.cancel) {
      return <Redirect to="/" />;
    }

    return (
      <div>
        <form
          onSubmit={this.onFormSubmit}
          className={classes.container}
          noValidate
          autoComplete="off"
        >
          <h4>Reset your password</h4>

          <TextField
            name="email"
            label="Email"
            id="txtEmail"
            color="default"
            value={this.state.email}
            className={classes.textField}
            onChange={e => this.handleChange(e)}
            margin="normal"
          />

          <div>
            <Button
              variant="contained"
              size="medium"
              color="default"
              fullWidth={true}
              type="submit"
              className={classes.button}
              style={{ width: '200px', marginTop: '20px' }}
            >
              Reset Password
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              size="medium"
              color="default"
              fullWidth={true}
              type="submit"
              onClick={() => {
                this.handleClose();
              }}
              className={classes.button}
              style={{ width: '200px', marginBottom: '40px' }}
            >
              Cancel
            </Button>
          </div>
        </form>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {/*<InfoIcon style={{fontSize: '32px', padding: '10px'}}/>*/}
                An email with password reset instructions has been sent to you
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" variant="contained" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  resetPasswordEmail: email => dispatch(resetPasswordEmail(email)),
});

const WrapForgotPassword = connect(null, mapDispatchToProps)(withStyles(styles)(ResetPassword));

export default WrapForgotPassword;
