/***********************************************************************
 *
 * DESCRIPTION :
 *   Firebase utility functions are defined here
 *
 * PUBLIC FUNCTIONS :
 *   ** Note user data structure: user.email, user.pass
 *   getProvider(provider)
 *   loginWithProvider(provider)
 *   registerUser(user)
 *   logoutUser()
 *   fetchUser()
 *   loginUser(user)
 *   updateUserProfile(user)
 *   resetPasswordEmail(email)
 *   changePassword(password)
 *   sendEmailVerification()
 *   updateUserEmail(email)
 *
 * AUTHOR(S) :
 *   D.L. Johnson    START DATE : 1 Jan 2019
 *
 * Copyright 3Wise 2019. All rights reserved.
 ***********************************************************************/

import firebase from 'firebase';
import { FIREBASE_CONFIG } from '../config';

export const firebaseApp = firebase.initializeApp(FIREBASE_CONFIG);
export const firebaseAuth = firebaseApp.auth();
export const firebaseDb = firebaseApp.database();

const FireBaseTools = {
  /**
   * Return an instance of a firebase auth provider based on the provider string.
   *
   * @param provider
   * @returns {firebase.auth.AuthProvider}
   */
  getProvider: provider => {
    switch (provider) {
      case 'email':
        return new firebase.auth.EmailAuthProvider();
      case 'facebook':
        return new firebase.auth.FacebookAuthProvider();
      case 'github':
        return new firebase.auth.GithubAuthProvider();
      case 'google':
        return new firebase.auth.GoogleAuthProvider();
      case 'twitter':
        return new firebase.auth.TwitterAuthProvider();
      default:
        throw new Error('Provider is not supported!!!');
    }
  },

  /**
   * Login with provider => p is provider "email", "facebook", "github", "google", or "twitter"
   * Uses Popup therefore provider must be an OAuth provider. EmailAuthProvider will throw an error
   *
   * @returns {any|!firebase.Thenable.<*>|firebase.Thenable<any>}
   */
  loginWithProvider: p => {
    const provider = FireBaseTools.getProvider(p);
    return firebaseAuth
      .signInWithPopup(provider)
      .then(firebaseAuth.currentUser)
      .catch(error => ({
        errorCode: error.code,
        errorMessage: error.message,
      }));
  },

  /**
   * Register a user with email and password
   *
   * @param user
   * @returns {any|!firebase.Thenable.<*>|firebase.Thenable<any>}
   */
  registerUser: user => {
    return firebaseAuth
      .createUserWithEmailAndPassword(user.email, user.pass)
      .then(userInfo => userInfo)
      .catch(error => ({
        errorCode: error.code,
        errorMessage: error.message,
      }));
  },

  /**
   * Sign the user out
   *
   * @returns {!firebase.Promise.<*>|firebase.Thenable<any>|firebase.Promise<any>|!firebase.Thenable.<*>}
   */
  logoutUser: () =>
    firebaseAuth.signOut().then(() => ({
      success: 1,
      message: 'logout',
    })),

  /**
   * Retrieve the current user (Promise)
   * @returns {Promise}
   */
  fetchUser: () =>
    
    new Promise((resolve, reject) => {
      //console.log'FireBase fetchUser: ')
      const unsub = firebaseAuth.onAuthStateChanged(
        user => {
          //console.log'fetchUser success: ',user)
          unsub();
          resolve(user);
        },
        error => {
          //console.log'fetchUser Error: ', error)
          reject(error);
        },
      );
    }),

  /**
   * Log the user in using email and password
   *
   * @param user
   * @returns {any|!firebase.Thenable.<*>|firebase.Thenable<any>}
   */
  loginUser: user =>
    firebaseAuth
      .signInWithEmailAndPassword(user.email, user.pass)
      .then(userInfo => userInfo)
      .catch(error => ({
        errorCode: error.code,
        errorMessage: error.message,
      })),

  /**
   * Update a user's profile data
   *
   * @param u
   * @returns {!firebase.Promise.<*>|firebase.Thenable<any>|firebase.Promise<any>|!firebase.Thenable.<*>}
   */
  updateUserProfile: u =>
    firebaseAuth.currentUser.updateProfile(u).then(
      () => firebaseAuth.currentUser,
      error => ({
        errorCode: error.code,
        errorMessage: error.message,
      }),
    ),

  /**
     * Update a user's email address
     *
     * @param email
     * @returns {!firebase.Promise.<*>|firebase.Thenable<any>|firebase.Promise<any>|!firebase.Thenable.<*>}
     */
  updateUserEmail: email =>
    firebaseAuth.currentUser.updateEmail(email).then(
      user => user,
      error => ({
        errorCode: error.code,
        errorMessage: error.message,
      }),
    ),



  /**
   * Reset the password given the specified email
   *
   * @param email {string}
   * @returns {!firebase.Promise.<*>|firebase.Thenable<any>|firebase.Promise<any>|!firebase.Thenable.<*>}
   */
  resetPasswordEmail: email =>
    firebaseAuth.sendPasswordResetEmail(email).then(
      () => ({
        message: 'Email sent',
      }),
      error => ({
        errorCode: error.code,
        errorMessage: error.message,
      }),
    ),

  /**
   * Update the user's password with the given password
   *
   * @param newPassword {string}
   * @returns {!firebase.Promise.<*>|firebase.Thenable<any>|firebase.Promise<any>|!firebase.Thenable.<*>}
   */
  changePassword: newPassword =>
    firebaseAuth.currentUser.updatePassword(newPassword).then(
      user => user,
      error => ({
        errorCode: error.code,
        errorMessage: error.message,
      }),
    ),

  /**
   * Send an account email verification message for the currently logged in user
   *
   * @returns {!firebase.Promise.<*>|firebase.Thenable<any>|firebase.Promise<any>|!firebase.Thenable.<*>}
   */
  sendEmailVerification: () =>
    firebaseAuth.currentUser.sendEmailVerification().then(
      () => ({
        message: 'Email sent',
      }),
      error => ({
        errorCode: error.code,
        errorMessage: error.message,
      }),
    ),

  /**
   * Get the firebase database reference.
   *
   * @param path {!string|string}
   * @returns {!firebase.database.Reference|firebase.database.Reference}
   */
  getDatabaseReference: path => firebaseDb.ref(path),
};

export default FireBaseTools;
