import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';

import Slider from '@material-ui/core/Slider';

import { unstable_Box as Box } from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { isMobile } from 'react-device-detect';

import Button from '@material-ui/core/Button';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import './UserProfile.css';
import * as common from '../../common.js';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import InfoIcon from '@material-ui/icons/Info';

import { Redirect } from 'react-router-dom';
import { FlexibleHeightXYPlot } from 'react-vis/dist/make-vis-flexible';
import ReactGA from 'react-ga';
import { green } from '@material-ui/core/colors';

const styles = theme => ({
  root: {
    minWidth: 20,
  },

  lineColor: {
    color: 'black',
  },

  formControl: {
    marginLeft: theme.spacing.unit * 3,
  },

  slider: {
    padding: '22px 0px',
  },

  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});

const devices = [
  {
    value: 'NA',
    label: '',
  },
  {
    value: 'MP',
    label: 'Mobile Phone',
  },
  {
    value: 'TB',
    label: 'Tablet',
  },
  {
    value: 'PC',
    label: 'Desktop PC',
  },
  {
    value: 'LP',
    label: 'Laptop',
  },
];

const StyledListItem = withStyles({
  root: {
    Color: 'grey',
    '&$selected': {
      backgroundColor: 'grey',
    },
  },
  selected: {},
})(ListItem);

const features = [
  'ALL: TV Series titles to be added',
  'ALL: Allow users to submit incorrectly labelled language markers and be rewarded',
  'ALL: Allow users to submit Sex/Nudity/Viloence markers for movies and be rewarded',
  'ALL: Provide Sex/Nudity/Violence scene markers for movies',
  "ALL: Users can see history of what they've selected as watched and build a favourites list",
  'ALL: Users can share favorite title lists with other users',
  'PLUS: Provide streaming Links to Netflix, Hulu, Amazon, AppleTV and Mubi',
  'PLUS: Allow users to customize offensive word list (can add own words and remove words)',
  'PLUS: Users will receive suggestions for next TV series and episode',
  'PRO: Live filtering of unwanted words and scenes on TV or streaming device from 3Wise',
];

const questions = [
  'Was 3Wise useful for choosing what to watch?',
  'Did you find the movie you were looking for?',
  'Is there anything about the site that is confusing/unclear?',
  'Did you experience any problems/bugs?',
  'Did you notice any incorrectly labelled words?',
];

class UserProfile extends React.Component {
  state = {
    alignment: 'center',
    multiline: 'Controlled',
    open: false,
    openError: false,
    errorMsg: '',
    extraVisible: true,
    goHome: false,
    checkValues: [],
    email: '',
    device: '',
    confusing: '',
    problems: '',
    features: '',
    movies: '',
    compliments: '',

    blocked_words: {},

    addwords_array: ['test1', 'test2'],
    delwords_array: ['test3', 'test4'],
  };

  componentDidMount() {
    ReactGA.initialize('UA-140786114-1');
    ReactGA.pageview('/userprofile');
  }

  componentWillMount() {
    var checkStates = [];
    for (var y in questions) {
      checkStates.push('U');
    }

    this.setState({ checkValues: checkStates });
  }

  handleClose = () => {
    this.props.OnDone();
    this.setState({ open: false, goHome: true });
  };

  handleCloseError = () => {
    this.setState({ openError: false });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
    //console.log('Feedback name change', event.target.value )
  };

  handleChangeCheck = index => (event, v) => {
    var newValues = this.state.checkValues;
    if (v != null && v != 'U') newValues[index] = v;

    //console.log ('Feedback hangleChangeCheck', v);

    this.setState({ checkValues: newValues });
    //console.log('Feedback checked:',this.state.checkValues)
  };

  errorDialog = msg => {
    return (
      <Dialog
        open={this.state.openError}
        onClose={this.handleCloseError}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {/*<InfoIcon style={{fontSize: '32px', padding: '10px'}}/>*/}
              {msg}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseError} color="primary" variant="contained" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  handleSubmit = event => {
    var i;
    var qLeft = false;
    /* Check if all questiones answered */
    for (i = 0; i < questions.length; i++) {
      if (this.state.checkValues[i] == 'U') qLeft = true;
    }

    if (qLeft) {
      //console.log('Feedback: Question Left')
      this.setState({ errorMsg: 'Please Select Yes or No to all feedback questions' });
      this.setState({ openError: true });
    } else if (this.state.confusing.length >= 3000 || this.state.movies.length >= 3000) {
      //console.log('Feedback: Too many characters');
      /*confusing: this.state.confusing,
      problems: this.state.problems,
      features: this.state.features,
      movies: this.state.movies,
      compliments: this.state.compliments */
      this.setState({ errorMsg: 'Please keep answers to under 3000 characters' });
      this.setState({ openError: true });
    } else {
      /* Calculate checks value */
      var checkval = 0;
      for (i = 0; i < questions.length; i++) {
        checkval = checkval + (this.state.checkValues[i] == 'Y') * Math.pow(2, i);
      }

      var datapack = {
        checks: checkval,
        email: this.state.email,
        device: this.state.device,
        confusing: this.state.confusing,
        problems: this.state.problems,
        features: this.state.features,
        movies: this.state.movies,
        compliments: this.state.compliments,
      };
      //console.log('Feedback: You did a submit')
      fetch(`${common.DJANGOURL}/api/feedback`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(datapack),
      })
        .then(function(response) {
          return response.json();
        })
        .then(function(data) {
          //console.log("Feedback: Data is ok", data);
        })
        .catch(function(ex) {
          //console.log("Feedback: parsing failed", ex);
        });
      //console.log('Feedback: Completed submit')
      this.setState({ open: true });
    }
  };

  addwords = (classes, addwords_array) => {
    //console.log('addwords:', addwords_array)
    return (
      <div>
        <fieldset className="addwords">
          <legend> Additional words to block </legend>
          <div className="scroll-container" id="scroll-container">
            {addwords_array.length > 0 ? (
              <List dense={true}>
                {addwords_array.map(bword => {
                  return (
                    <div>
                      <StyledListItem name={bword}>
                        <ListItemText primary={bword} />
                      </StyledListItem>
                      <Divider />
                    </div>
                  );
                })}
              </List>
            ) : null}
          </div>
        </fieldset>
        <fieldset className="delwords">
          <legend> words to delete </legend>
          <div className="scroll-container" id="scroll-container">
            {addwords_array.length > 0 ? (
              <List dense={true}>
                {addwords_array.map(bword => {
                  return (
                    <div>
                      <StyledListItem name={bword}>
                        <ListItemText primary={bword} />
                      </StyledListItem>
                      <Divider />
                    </div>
                  );
                })}
              </List>
            ) : null}
          </div>
        </fieldset>
      </div>
    );
  };

  delwords = (classes, delwords_array) => {
    //console.log('delwords:', delwords_array)
    return (
      <fieldset className="delwords">
        <legend> Words to not block </legend>
        <div className="scroll-container" id="scroll-container">
          {delwords_array.length > 0 ? (
            <List dense={true}>
              {delwords_array.map(bword => {
                return (
                  <div>
                    <StyledListItem name={bword}>
                      <ListItemText primary={bword} />
                    </StyledListItem>
                    <Divider />
                  </div>
                );
              })}
            </List>
          ) : null}
        </div>
      </fieldset>
    );
  };

  render() {
    const { classes } = this.props;
    const { sliderValue, alignment, addwords_array } = this.state;

    //console.log('Feedback: Started render')

    if (this.state.goHome) {
      return <Redirect to="/" />;
    }

    return (
      <div className="root">
        <div className={!isMobile ? 'root_inner' : 'root_inner_mobile'}>
          <form className="container" noValidate autoComplete="off">
            <p style={{ width: '100%', marginTop: '10px' }}> Customize your 3Wise experience</p>

            {this.addwords(classes, addwords_array)}

            <div style={{ width: '100%', display: 'flex' }}>
              <DialogActions>
                <Button
                  style={{ margin: '0px', marginTop: '10px' }}
                  onClick={() => this.handleSubmit()}
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
              </DialogActions>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

UserProfile.propTypes = {
  classes: PropTypes.object.isRequired,
};

const WrapUserProfile = withStyles(styles)(UserProfile);
export default WrapUserProfile;
