import React, { Component } from 'react';

//import ScrollMenu from '../../components/Widgets/ScrollMenu/scrollMenu.tsx'
import ScrollMenu from 'react-horizontal-scrolling-menu';
import WrapSingleMovieInfinite from '../Browser/movieslist/SingleMovieInfinite';
import WrapSingleMovie from '../Browser/movieslist/SingleMovie.js';
import WiseFooter from '../Browser/Footer';
import axios from 'axios';
import * as common from '../../common';
import WrapMovieInfoDialog from '../Info/MovieInfoDialog';
import WrapTVInfoDialog from '../Info/TVInfoDialog';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import SvgIcon from '@material-ui/core/SvgIcon';

import { isMobile } from 'react-device-detect';
import ReactGA from 'react-ga';
import { Swipeable } from 'react-swipeable';
import isEqual from "react-fast-compare";
import BounceLoader from 'react-spinners/SyncLoader';
import ContentLoader from "react-content-loader"
import InfiniteScroll from 'react-infinite-scroller';


import './Search.css';
import { isThisTypeNode } from 'typescript';
import { TrainOutlined } from '@material-ui/icons';

const jsonequal = x => y => JSON.stringify(x) === JSON.stringify(y);

const arrayCompare = f => ([x, ...xs]) => ([y, ...ys]) =>
  x === undefined && y === undefined ? true : Boolean(f(x)(y)) && arrayCompare(f)(xs)(ys);

const arrayObjectsEqual = arrayCompare(jsonequal);

const Arrow = ({ text, className }) => {
  return <div className={className}>{text}</div>;
};

const ArrowLeftSVG = ({ size, className }) => {
  return (
    <div className={className}>
      <svg height="16" width="16">
        <polygon points="16,0 0,8 16,16" fill="darkgrey" />
      </svg>
    </div>
  );
};

const ArrowRightSVG = ({ size, className }) => {
  return (
    <div className={className}>
      <svg height="16" width="16">
        <polygon points="0,0 16,8 0,16" fill="darkgrey" />
      </svg>
    </div>
  );
};

const persistSyntheticEvent = (func, persist) => {
  return (e, ...rest) => {
    if (persist && e.persist) e.persist();
    return func(e, ...rest);
  };
};

function ArrowLeftSVGF() {
  return (
    <div className={'arrow-prev'}>
      <svg height="16" width="16">
        <polygon points="16,0 0,8 16,16" fill="darkgrey" />
      </svg>
    </div>
  );
}

function ArrowRightSVGF() {
  return (
    <div className={'arrow-prev'}>
      <svg height="16" width="16">
        <polygon points="0,0 16,8 0,16" fill="darkgrey" />
      </svg>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    movieID: state.movies_redux.movieID,
    TVID: state.TVID,
    showMovieInfo: state.movies_redux.showMovieInfo,
    showTVInfo: state.movies_redux.showTVInfo,
    currentUser: state.currentUser,
  };
};

//const ArrowLeft = Arrow({ text: "<", className: "arrow-prev" });
//const ArrowRight = Arrow({ text: ">", className: "arrow-next" });
const ArrowLeftTitles = ArrowLeftSVG({ size: 100, className: 'arrow-prev' });
const ArrowRightTitles = ArrowRightSVG({ size: 100, className: 'arrow-next' });
const ArrowLeftStarring = ArrowLeftSVG({ size: 100, className: 'arrow-prev' });
const ArrowRightStarring = ArrowRightSVG({ size: 100, className: 'arrow-next' });

const MAXTITLES = common.maxtitles;
const TITLESBLOCK = common.searchtitlesblock;
const TITLESADD = common.searchtitlesadd;

class Search extends Component {
  constructor(props) {
    super(props);
    this.menu1 = null;
    this.menu2 = null;
    this.mounted = false;
    this.MenuScroll = React.createRef();
    this.showMovieInfoPrevious = false;
  }
  state = {
    movies: [],
    moviesMatch: [],
    moviesNoMatch: [],
    moviesOutsideYear: [],
    moviesMatchNetflix: 0,
    moviesNoMatchNetflix: 0,
    moviesOutsideYearNetflix: 0,
    outsideYearHasMoreItems: false,
    movie: {},
    menu: '<div className="menu-item"></div>',
    selected: 'item0',
    showInProcess: false,
    loading: false,
    outsideLoading: false,
    lastItemVisible: false,
    firstItemVisible: false,
    inertiascrolling: true,
    slowdownFactor: 0.75,
    titleCount: TITLESBLOCK,
    outsideTitleCount: TITLESBLOCK,
    pendingRightShift: false,
    reachedLastTitle: false,
    totalTitlesFetched: 0,
    outsideTotalTitlesFetched: 0,
    newSearch: false,
    persistEvent: true,
    swipeDirection: 'none',
    hasMoreItems: false
  };

  checkNetflixCountry = (v, region) => {
    if (v.netflix_set.length > 0)
      if (v.netflix_set.some(o => o.country == region))
        return true
      else
        return false
    else
      return false
  }

  SearchTitleOutside = (iname, tcount, lazyLoad, newSearch, startyear, endyear, blasphemy, profanity, sexual, racial, rating, bwords, ageratings, netflix, region) => {
    //console.log('SingleMovie, SearchTitle: newSearch: ', newSearch);
    //console.log('SingleMovie, SearchTitle: lazyload: ', lazyLoad);

    // type = name or starring

    var newTotalTitlesFetched;
    var oStartyear = parseInt(startyear) - 1
    var oEndyear = parseInt(endyear) + 1
    var moviesOutsideYearNetflix = 0

    //console.log('Search Outside: ', oStartyear, oEndyear)


    //console.log('SingleMovie: test')
    this.setState({ outsideLoading: true });
    if (newSearch)
      this.setState({ moviesOutsideYear: [] })

    /*const urlTitle = fetch(`${common.DJANGOURL}/api/titles/?${type}=${iname}&count=${tcount}
      &startyear=${filterChoices['startyear']}&endyear=${filterChoices['endyear']}
      &blasphemy=${filterChoices['blasphemy']}&profanity=${filterChoices['profanity']}
      &sexual=${filterChoices['sexual']}&racial=${filterChoices['racial']}&tmdbrating=${filterChoices['rating']}
      &blockedwords=${filterChoices['blockedwords']}&ageratings= ${filterChoices['ageratings']}`);*/

    var basestr = `${common.DJANGOURL}/api/titles/?outsideyears=1&full=1&name=${iname}&starring=${iname}&count=${tcount}&startyear=${oStartyear}&endyear=${oEndyear}`

    /*&blasphemy=${blasphemy}&profanity=${profanity}&sexual=${sexual}&racial=${racial}`*/

    if (blasphemy >= 0)
      basestr = basestr.concat(`&blasphemy=${blasphemy}`)
    if (profanity >= 0)
      basestr = basestr.concat(`&profanity=${profanity}`)
    if (sexual >= 0)
      basestr = basestr.concat(`&sexual=${sexual}`)
    if (racial >= 0)
      basestr = basestr.concat(`&racial=${racial}`)
    if (bwords !== '0')
      basestr = basestr.concat(`&blockedwords=${bwords}`)
    if (ageratings !== '0')
      basestr = basestr.concat(`&ageratings=${ageratings}`)
    if (netflix == '1')
      basestr = basestr.concat(`&netflix=1`)
    if (region)
      basestr = basestr.concat(`&country=${region}`)

    //console.log('Base str: ', basestr)
    const urlTitle = fetch(basestr);
    const urls = [urlTitle];

    Promise.all(urls)
      .then(([r1]) => Promise.all([r1.json()]))
      .then(([data1]) => {
        // //console.log('SingleMovie, Setting Redux for movie data = ', data1)
        //console.log('SingleMovie, Setting Redux for Creits = ', data2)
        //console.log('SingleMovie, Setting Redux for Video = ',data3)
        //console.log('SingleMovie, Setting Redux for blocked = ', data4)
        //movieJoin = data1;
        moviesOutsideYearNetflix = data1.filter(v => this.checkNetflixCountry(v,region.toUpperCase())).length;
        newTotalTitlesFetched = data1.length;

        if (newTotalTitlesFetched == tcount) {
          //console.log('SingleMovie , SearchTitle outsideYear: More movies to come')
          this.setState({
            outsideYearHasMoreItems: true,
            moviesOutsideYear: data1,
            moviesOutsideYearNetflix: moviesOutsideYearNetflix,
            outsideLoading: false,
            outsideTotalTitlesFetched: newTotalTitlesFetched,
            outsideTitleCount: newTotalTitlesFetched
          });

        } else {
          //console.log('SingleMovie, SearchTitle outsideYear:  Reached last movie')
          this.setState({
            outsideYearHasMoreItems: false,
            moviesOutsideYear: data1,
            moviesOutsideYearNetflix: moviesOutsideYearNetflix,
            outsideLoading: false,
            outsideTotalTitlesFetched: newTotalTitlesFetched,
            outsideTitleCount: newTotalTitlesFetched
          });
        }
        //console.log('search: moveJoin', movieJoin)
        //console.log('Search: Lazyload: ', lazyLoad)
      })
      .catch(err => {
        //console.log(err)
        this.setState({ loading: false });
      });
  };


  SearchTitle = (iname, tcount, lazyLoad, newSearch, startyear, endyear, blasphemy, profanity, sexual, racial, rating, bwords, ageratings, netflix, region) => {
    //console.log('SingleMovie, SearchTitle: newSearch: ', newSearch);
    //console.log('SingleMovie, SearchTitle: lazyload: ', lazyLoad);

    // type = name or starring

    var newTotalTitlesFetched;
    var moviesMatch = []
    var moviesNoMatch = []
    var moviesOutsideYear = []
    var moviesMatchNetflix = 0
    var moviesNoMatchNetflix = 0
    

    //console.log('SingleMovie: test')
    this.setState({ loading: true });
    if (newSearch)
      this.setState({ movies: [] })

    /*const urlTitle = fetch(`${common.DJANGOURL}/api/titles/?${type}=${iname}&count=${tcount}
      &startyear=${filterChoices['startyear']}&endyear=${filterChoices['endyear']}
      &blasphemy=${filterChoices['blasphemy']}&profanity=${filterChoices['profanity']}
      &sexual=${filterChoices['sexual']}&racial=${filterChoices['racial']}&tmdbrating=${filterChoices['rating']}
      &blockedwords=${filterChoices['blockedwords']}&ageratings= ${filterChoices['ageratings']}`);*/

    var basestr = `${common.DJANGOURL}/api/titles/?full=1&name=${iname}&starring=${iname}&count=${tcount}&startyear=${startyear}&endyear=${endyear}`

    /*&blasphemy=${blasphemy}&profanity=${profanity}&sexual=${sexual}&racial=${racial}`*/

    if (blasphemy >= 0)
      basestr = basestr.concat(`&blasphemy=${blasphemy}`)
    if (profanity >= 0)
      basestr = basestr.concat(`&profanity=${profanity}`)
    if (sexual >= 0)
      basestr = basestr.concat(`&sexual=${sexual}`)
    if (racial >= 0)
      basestr = basestr.concat(`&racial=${racial}`)
    if (bwords !== '0')
      basestr = basestr.concat(`&blockedwords=${bwords}`)
    if (ageratings !== '0')
      basestr = basestr.concat(`&ageratings=${ageratings}`)
    if (netflix == '1')
      basestr = basestr.concat(`&netflix=1`)
    if (region)
      basestr = basestr.concat(`&country=${region}`)

    //console.log('Base str: ', basestr)
    const urlTitle = fetch(basestr);

    const urls = [urlTitle];

    Promise.all(urls)
      .then(([r1]) => Promise.all([r1.json()]))
      .then(([data1]) => {
        // //console.log('SingleMovie, Setting Redux for movie data = ', data1)
        //console.log('SingleMovie, Setting Redux for Creits = ', data2)
        //console.log('SingleMovie, Setting Redux for Video = ',data3)
        //console.log('SingleMovie, Setting Redux for blocked = ', data4)
        //movieJoin = data1;

        newTotalTitlesFetched = data1.length;

        //console.log('SingleMovie, SearchTitle: newTotalTitlesFetched', newTotalTitlesFetched);
        //console.log('SingleMovie, SearchTitle: state.TitlesFetched', this.state.totalTitlesFetched);
        //console.log('SingleMovie, SearchTitle: newSearch: ', newSearch);

        // Split movies into matching and not matching
        moviesMatch = data1.filter(v => v.found == true)
        moviesNoMatch = data1.filter(v => v.found == false)
        moviesMatchNetflix = moviesMatch.filter(v => this.checkNetflixCountry(v,region.toUpperCase())).length;
        moviesNoMatchNetflix = moviesNoMatch.filter(v => this.checkNetflixCountry(v,region.toUpperCase())).length;

        if (newTotalTitlesFetched == tcount) {
          //console.log('SingleMovie, SearchTitle: More movies to come')
          this.setState({
            reachedLastTitle: false,
            hasMoreItems: true,
            movies: data1,
            moviesMatch: moviesMatch,
            moviesNoMatch: moviesNoMatch,
            moviesOutsideYear: moviesOutsideYear,
            moviesMatchNetflix: moviesMatchNetflix,
            moviesNoMatchNetflix: moviesNoMatchNetflix,
            loading: false,
            pendingRightShift: lazyLoad,
            totalTitlesFetched: newTotalTitlesFetched,
            titleCount: newTotalTitlesFetched
          });

        } else {
          //console.log('SingleMovie, SearchTitle: Reached last movie')

          // Start process to fetch outside of years
          this.SearchTitleOutside(this.props.search_name, TITLESBLOCK, false, true, this.props.startyear, this.props.endyear,
            this.props.blasphemy, this.props.profanity, this.props.sexual, this.props.racial, this.props.rating, this.props.bwords,
            this.props.ageratings, this.props.netflix, this.props.region);

          this.setState({
            reachedLastTitle: true,
            hasMoreItems: false,
            movies: data1,
            moviesMatch: moviesMatch,
            moviesNoMatch: moviesNoMatch,
            moviesOutsideYear: moviesOutsideYear,
            moviesMatchNetflix: moviesMatchNetflix,
            moviesNoMatchNetflix: moviesNoMatchNetflix,
            loading: false,
            pendingRightShift: false,
            totalTitlesFetched: newTotalTitlesFetched,
            titleCount: newTotalTitlesFetched
          });
        }
        //console.log('search: moveJoin', movieJoin)
        //console.log('Search: Lazyload: ', lazyLoad)
      })
      .catch(err => {
        //console.log(err)
        this.setState({ loading: false });
      });
  };




  componentDidMount() {
    //console.log('Search_Generic: DidMoujnt: search_type', this.props)
    this.mounted = true;

    this.SearchTitle(this.props.search_name, TITLESBLOCK, false, true, this.props.startyear, this.props.endyear,
      this.props.blasphemy, this.props.profanity, this.props.sexual, this.props.racial, this.props.rating, this.props.bwords,
      this.props.ageratings, this.props.netflix, this.props.region);
    this.showMovieInfoPrevious = this.props.showMovieInfo;
    //console.log('Search: DidMount')
    ReactGA.initialize('UA-140786114-1');
    ReactGA.pageview('/search');
  }


  componentWillUnmount() {
    this.mounted = false;
  }

  checkArrowVisibilityCondition = () => {
    //console.log('check Arrow Visibility')
    setTimeout(() => {
      if (this.menu1) {
        var newLastVisible = this.menu1.checkFirstLastItemVisibility({})['lastItemVisible'];
        this.setState({ lastItemVisible: newLastVisible });
      }
    }, 500);
  }

  LeftArrowProcess = () => {
    this.checkArrowVisibilityCondition()
  };


  TitlesClickLeftArrow = () => {
    //console.log('Search: Titlesclickleftarrow')
    //console.log('Search: Met condition Titlesclickleftarrow')
    this.menu1.handleArrowClick();
    this.LeftArrowProcess();
  };

  RightArrowProcess = () => {
    this.checkArrowVisibilityCondition()
  };



  TitlesClickRightArrow = () => {
    //console.log('Search: Titlesclickleftarrow')
    //console.log('Search: Met condition Titlesclickleftarrow')
    this.menu1.handleArrowClickRight();
    this.RightArrowProcess();
  };

  componentWillReceiveProps(nextProps, nextState) {
    //console.log('Search_Generic: componentWillReceiveProps ', nextProps, this.props)
  }

  /*
    startyear={this.props.match.params.filter_choices_startyear}
    endyear={this.props.match.params.filter_choices_endyear}
  
    blasphemy={this.props.match.params.filter_choices_blasphemy}
    profanity={this.props.match.params.filter_choices_profanity}
    sexual={this.props.match.params.filter_choices_sexual}
    racial={this.props.match.params.filter_choices_racial}
    rating={this.props.match.params.filter_choices_rating}
    bwords={this.props.match.params.filter_choices_bwords}
    ageratings={this.props.match.params.filter_choices_ageratings}*/



  /*shouldComponentUpdate(nextProps, nextState) {
    //console.log'List.js shouldComponentUpdate', this.mounted, nextState.themovies,this.state.themovies );
    if (this.mounted) return true;
   
  }*/

  shouldComponentUpdate(nextProps, nextState) {
    //console.log('Search Generic: shouldComponentUpdate: ', nextProps.currentUser.pending, nextState.movies, this.state.movies, nextState.loading, this.state.loading, nextState.reachedLastTitle, this.state.reachedLastTitle)
    //console.log('Search Generic: shouldComponentUpdate: nextProps, this.props:  ', nextProps, this.props)
    if (JSON.stringify([nextProps.search_name, nextProps.startyear, nextProps.endyear, nextProps.blasphemy, nextProps.profanity, nextProps.sexual, nextProps.racial, nextProps.rating, nextProps.bwords, nextProps.ageratings, nextProps.netflix, nextProps.region])
      != JSON.stringify([this.props.search_name, this.props.startyear, this.props.endyear, this.props.blasphemy, this.props.profanity, this.props.sexual, this.props.racial, this.props.rating, this.props.bwords, this.props.ageratings, this.props.netflix, this.props.region])) {
      /*if (!isEqual(nextProps,this.props)) {*/
      //console.log('Search Generic: shouldComponentUpdate: updating database: ')
      this.SearchTitle(nextProps.search_name, TITLESBLOCK, false, true, nextProps.startyear, nextProps.endyear,
        nextProps.blasphemy, nextProps.profanity, nextProps.sexual, nextProps.racial, nextProps.rating, nextProps.bwords,
        nextProps.ageratings, nextProps.netflix, nextProps.region);
    }

    if (
      !nextProps.currentUser.pending ||
      JSON.stringify([nextProps.search_name, nextProps.startyear, nextProps.endyear, nextProps.blasphemy, nextProps.profanity, nextProps.sexual, nextProps.racial, nextProps.rating, nextProps.bwords, nextProps.ageratings, nextProps.netflix, nextProps.region])
      != JSON.stringify([this.props.search_name, this.props.startyear, this.props.endyear, this.props.blasphemy, this.props.profanity, this.props.sexual, this.props.racial, this.props.rating, this.props.bwords, this.props.ageratings, this.props.netflix, this.props.region]) ||
      /*!isEqual(nextProps,this.props) ||*/
      nextState.movies != this.state.movies ||
      nextState.loading != this.state.loading ||
      nextState.reachedLastTitle != this.state.reachedLastTitle

    ) {
      //console.log('Search: ShouldUpdate')
      //console.log('Search Generic: shouldComponentUpdate: TRUE')
      return true;
    } else return false;

    /*
    if(nextProps.movies.length!=0)
      if (this.props.movies == 0)
        return true;
      else
        if (this.props.movies[0].poster != nextProps.movies[0].poster)
          return true;
        else
          return false;
    else
      return false; */
  }




  buildBlankMovie = index => {
    var rtime = Math.floor(Math.random() * 4) + 1;

    //console.log('search buildBlankMovie index: ',index, this.state.movies, this.state.movies.length)
    return (

      <div className="menu-item" key={index}>
        <div
          className="blank-movie-card"
        >
          <ContentLoader
            speed={rtime}
            width={150}
            height={225}
            viewBox="0 0 150 225"
            backgroundColor="#333333"
            foregroundColor="#000000"
          >
            <rect x="0" y="0" rx="2" ry="2" width="150" height="225" />
          </ContentLoader>
        </div>
      </div >
    );
  };



  loadItemsInYear = (typeMatch) => {
    console.log('Search_Generic_Infinite: typeMatch: ', typeMatch)
    var lazyload, newTitleCount, newSearch;
    var extraCondition = true
    if (typeMatch)
      var extraCondition = (this.state.moviesNoMatch.length == 0)

    if (this.state.hasMoreItems && !this.state.loading && extraCondition) {
      //console.log('Search Generic Load more items No Match')
      newTitleCount = this.state.titleCount + TITLESADD;
      //console.log('loadItmes No Match: newTitleCount: ', newTitleCount)
      this.setState({
        loading: true,
        titleCount: newTitleCount,
      });
      newSearch = false;

      this.SearchTitle(this.props.search_name, newTitleCount, false, newSearch, this.props.startyear, this.props.endyear,
        this.props.blasphemy, this.props.profanity, this.props.sexual, this.props.racial, this.props.rating, this.props.bwords,
        this.props.ageratings, this.props.netflix, this.props.region);
    }
  }

  loadItemsOutsideYear = () => {

    var lazyload, newTitleCount, newSearch;

    if (this.state.outsideYearHasMoreItems && !this.state.loading) {
      //console.log('Search Generic Load more items outside year')
      newTitleCount = this.state.outsideTitleCount + TITLESADD;
      //console.log('loadItmes outside year: newTitleCount: ', newTitleCount)
      this.setState({
        loading: true,
        outsideTitleCount: newTitleCount,
      });
      newSearch = false;

      this.SearchTitleOutside(this.props.search_name, newTitleCount, false, newSearch, this.props.startyear, this.props.endyear,
        this.props.blasphemy, this.props.profanity, this.props.sexual, this.props.racial, this.props.rating, this.props.bwords,
        this.props.ageratings, this.props.netflix, this.props.region);
    }

  }

  lazyLoad = () => {
    if (!this.state.loading && !this.state.newSearch) {
      //console.log('Search_Generic_infinite: states: ', this.state.hasMoreItems, this.state.outsideYearHasMoreItems)
      if (this.state.hasMoreItems)
        this.loadItemsInYear(false)
      else if (this.state.outsideYearHasMoreItems)
        this.loadItemsOutsideYear()
    }
  }



  render() {
    const {
      selected,
      movies,
      moviesMatch,
      moviesNoMatch,
      moviesOutsideYear,
      moviesMatchNetflix,
      moviesNoMatchNetflix,
      moviesOutsideYearNetflix,
      inertiascrolling,
      slowdownFactor,
      outsideLoading,
      loading,
      persistEvent,
    } = this.state;
    const movieEmpty = [1, 2, 3, 4, 5, 6, 7, 8];
    var alignCenterTitles = false;
    var alignCenterStarring = false;
    var mlen = 0;

    console.log('Search Infinite: ', moviesMatchNetflix, moviesNoMatchNetflix, this.props.netflix)
    //const loader = <div className="loader">Loading ...</div>;

    var moviesFullMatch = []
    var moviesFullNoMatch = []
    var movieData = []
    var moviesFullOutsideYear = []


    if (moviesMatch.length > 0) {
      moviesFullMatch = moviesMatch.map((movie, index) => {
        //console.log('Search_new index: ',index)
        if (movie) {
          return (
            <div className="menu-item" key={movie.imdbID}>
              {isMobile
                ?
                <WrapSingleMovie movie={movie} netflix_region={this.props.region} />
                :
                <WrapSingleMovieInfinite movie={movie} netflix_region={this.props.region} />
              }
            </div>
          );
        }

        else {
          return (
            <div className="menu-item"></div>
          )
        }
      });
    }
    if (moviesNoMatch.length > 0) {
      moviesFullNoMatch = moviesNoMatch.map((movie, index) => {
        //console.log('Search_new index: ',index)
        if (movie) {
          return (
            <div className="menu-item" key={movie.imdbID}>
              {isMobile
                ?
                <WrapSingleMovie movie={movie} netflix_region={this.props.region} />
                :
                <WrapSingleMovieInfinite movie={movie} netflix_region={this.props.region} />
              }
            </div>
          );
        }

        else {
          return (
            <div className="menu-item"></div>
          )
        }
      });
    }

    if (moviesOutsideYear.length > 0) {
      moviesFullOutsideYear = moviesOutsideYear.map((movie, index) => {
        //console.log('Search_new index: ',index)
        if (movie) {
          return (
            <div className="menu-item" key={movie.imdbID}>
              {isMobile
                ?
                <WrapSingleMovie movie={movie} netflix_region={this.props.region} />
                :
                <WrapSingleMovieInfinite movie={movie} netflix_region={this.props.region} />
              }
            </div>
          );
        }

        else {
          return (
            <div className="menu-item"></div>
          )
        }
      });
    }


    movieData = moviesFullMatch.concat(moviesFullNoMatch).concat(moviesFullOutsideYear)

    if (loading) {

      if (isMobile)
        movieData = movieData.concat(
          movieEmpty.map(index => {
            return this.buildBlankMovie(index);
          }));

      //movieData = movieLoading;
      if ((moviesMatch.length > 0) && (moviesNoMatch.length == 0) && (moviesOutsideYear.length == 0))
        moviesFullMatch = moviesFullMatch.concat(
          movieEmpty.map(index => {
            return this.buildBlankMovie(index);
          }));

      if (moviesNoMatch.length > 0 && (moviesOutsideYear.length == 0))
        moviesFullNoMatch = moviesFullNoMatch.concat(
          movieEmpty.map(index => {
            return this.buildBlankMovie(index);
          }));
    }
    if (outsideLoading) {
      if (moviesOutsideYear.length > 0)
        moviesFullOutsideYear = moviesFullOutsideYear.concat(
          movieEmpty.map(index => {
            return this.buildBlankMovie(index);
          }));
      else
        moviesFullOutsideYear =
          movieEmpty.map(index => {
            return this.buildBlankMovie(index);
          });

      //console.log('List.js: movieData loading ', movieData)
    }

    //console.log('movies filtered:', loading, moviesMatch, moviesNoMatch)


    return (

      <div>
        {(this.props.currentUser.pending || (loading && movies.length == 0)) ? (

          <div className="moviesLoading">
            <BounceLoader />
            <div className="loadingText">Searching ... </div>
          </div>
        )
          :
          (isMobile ? (
            <div className="outside-lists-search">
              {movieData.length > 0 ? (
                <div className="lists-search">
                  {(this.props.netflix==1 && (moviesMatchNetflix || moviesNoMatchNetflix || moviesOutsideYearNetflix))
                    ?<h4 style={{ marginLeft: '10px', color: 'black' }}>{this.props.search_title} - Netflix results shown first</h4>
                    :<h4 style={{ marginLeft: '10px', color: 'black' }}>{this.props.search_title}  </h4>}
                  <ScrollMenu
                    ref={el => {
                      this.menu1 = el;
                    }}
                    data={movieData}
                    wheel={false}
                    dragging={true}
                    alignCenter={false}
                    clickWhenDrag={false}
                    innerWrapperStyle={{ display: 'flex', alignItems: 'center' }}
                    inertiaScrolling={true}
                    inertiaScrollingSlowdown={0.7}
                    transition={1}
                    onLastItemVisible={this.lazyLoad}
                  />
                </div>
              ) : (
                <div className="lists-search-blank">
                  <b>No movies found - try changing your search terms or check your spelling</b>
                </div>
              )}
              {/* FIXME: Should only call if showMovie is true - but this fails to update timeline */}
              <WrapMovieInfoDialog open={this.props.showMovieInfo} movieID={this.props.movieID} region={this.props.region} />
              <WrapTVInfoDialog open={this.props.showTVInfo} region={this.props.region} />
            </div>
          ) : (
            <div className="outside-lists-search">
              {movieData.length > 0 ? (
                <div>
                  <div>
                    {moviesFullMatch.length > 0 ? (
                      <div className="lists-search">
                        {(this.props.netflix==1)?
                         ((moviesMatchNetflix) 
                        ?<h4 style={{ marginLeft: '30px', color: 'black' }}>{this.props.search_title} (matching filter) - Netflix results shown first </h4>
                        :<h4 style={{ marginLeft: '30px', color: 'black' }}>{this.props.search_title} (matching filter) - No Netflix results </h4>)
                        :<h4 style={{ marginLeft: '30px', color: 'black' }}>{this.props.search_title} (matching filter)</h4>}
                        <div className="lists-layout">
                          <InfiniteScroll
                            pageStart={0}
                            loadMore={this.loadItemsInYear.bind(this, true)}
                            hasMore={this.state.hasMoreItems}
                          >
                            <div className="tiledMovies">
                              {moviesFullMatch}
                            </div>

                          </InfiniteScroll>

                        </div>
                      </div>
                    ) : null}
                  </div>


                  <div>
                    {moviesFullNoMatch.length > 0 ? (
                      <div className="lists-search">
                         {(this.props.netflix==1)?
                         ((moviesNoMatchNetflix) 
                        ?<h4 style={{ marginLeft: '30px', color: 'black' }}>{this.props.search_title} (not matching filter) - Netflix results shown first </h4>
                        :<h4 style={{ marginLeft: '30px', color: 'black' }}>{this.props.search_title} (not matching filter) - No Netflix results </h4>)
                        :<h4 style={{ marginLeft: '30px', color: 'black' }}>{this.props.search_title} (not matching filter)</h4>}
                        <div className="lists-layout">

                          <InfiniteScroll
                            pageStart={0}
                            loadMore={this.loadItemsInYear.bind(this, false)}
                            hasMore={this.state.hasMoreItems}
                          >
                            <div className="tiledMovies">
                              {moviesFullNoMatch}
                            </div>

                          </InfiniteScroll>

                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div>
                    {moviesFullOutsideYear.length > 0 ? (
                      <div className="lists-search">
                        {(this.props.netflix==1)?
                         ((moviesOutsideYearNetflix)
                        ?<h4 style={{ marginLeft: '30px', color: 'black' }}>{this.props.search_title} (other years) - Netflix results shown first</h4>
                        :<h4 style={{ marginLeft: '30px', color: 'black' }}>{this.props.search_title} (other years) - No Netflix results</h4>)
                        :<h4 style={{ marginLeft: '30px', color: 'black' }}>{this.props.search_title} (other years)</h4>}
                        <div className="lists-layout">
                          <InfiniteScroll
                            pageStart={0}
                            loadMore={this.loadItemsOutsideYear.bind(this)}
                            hasMore={this.state.outsideYearHasMoreItems}
                          >
                            <div className="tiledMovies">
                              {moviesFullOutsideYear}
                            </div>

                          </InfiniteScroll>

                        </div>
                      </div>
                    ) : null}
                  </div>


                </div>
              ) :
                (
                  <div className="lists-search-blank">
                    <b>No movies found - try changing your search terms or check your spelling</b>
                  </div>
                )}


              {/* FIXME: Should only call if showMovie is true - but this fails to update timeline */}

              <WrapMovieInfoDialog open={this.props.showMovieInfo} movieID={this.props.movieID} region={this.props.region} />
              <WrapTVInfoDialog open={this.props.showTVInfo} region={this.props.region} />

            </div>
          )
          )}

      </div>
    );

  }
}

const WrapSearchInfinite = connect(mapStateToProps)(Search);

export default WrapSearchInfinite;
