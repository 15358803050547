import React, { Component } from 'react';

//import ScrollMenu from '../../components/Widgets/ScrollMenu/scrollMenu.tsx'
import ScrollMenu from 'react-horizontal-scrolling-menu';
import WrapSingleMovie from '../Browser/movieslist/SingleMovie';
import WiseFooter from '../Browser/Footer';
import axios from 'axios';
import * as common from '../../common';
import WrapMovieInfoDialog from '../Info/MovieInfoDialog';
import WrapTVInfoDialog from '../Info/TVInfoDialog';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import SvgIcon from '@material-ui/core/SvgIcon';

import { isMobile } from 'react-device-detect';
import ReactGA from 'react-ga';
import { Swipeable } from 'react-swipeable';
import isEqual from "react-fast-compare";
import BounceLoader from 'react-spinners/SyncLoader';
import ContentLoader from "react-content-loader"

import './Search.css';

const jsonequal = x => y => JSON.stringify(x) === JSON.stringify(y);

const arrayCompare = f => ([x, ...xs]) => ([y, ...ys]) =>
  x === undefined && y === undefined ? true : Boolean(f(x)(y)) && arrayCompare(f)(xs)(ys);

const arrayObjectsEqual = arrayCompare(jsonequal);

const Arrow = ({ text, className }) => {
  return <div className={className}>{text}</div>;
};

const ArrowLeftSVG = ({ size, className }) => {
  return (
    <div className={className}>
      <svg height="16" width="16">
        <polygon points="16,0 0,8 16,16" fill="darkgrey" />
      </svg>
    </div>
  );
};

const ArrowRightSVG = ({ size, className }) => {
  return (
    <div className={className}>
      <svg height="16" width="16">
        <polygon points="0,0 16,8 0,16" fill="darkgrey" />
      </svg>
    </div>
  );
};

const persistSyntheticEvent = (func, persist) => {
  return (e, ...rest) => {
    if (persist && e.persist) e.persist();
    return func(e, ...rest);
  };
};

function ArrowLeftSVGF() {
  return (
    <div className={'arrow-prev'}>
      <svg height="16" width="16">
        <polygon points="16,0 0,8 16,16" fill="darkgrey" />
      </svg>
    </div>
  );
}

function ArrowRightSVGF() {
  return (
    <div className={'arrow-prev'}>
      <svg height="16" width="16">
        <polygon points="0,0 16,8 0,16" fill="darkgrey" />
      </svg>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    movieID: state.movies_redux.movieID,
    TVID: state.TVID,
    showMovieInfo: state.movies_redux.showMovieInfo,
    showTVInfo: state.movies_redux.showTVInfo,
    currentUser: state.currentUser,
  };
};

//const ArrowLeft = Arrow({ text: "<", className: "arrow-prev" });
//const ArrowRight = Arrow({ text: ">", className: "arrow-next" });
const ArrowLeftTitles = ArrowLeftSVG({ size: 100, className: 'arrow-prev' });
const ArrowRightTitles = ArrowRightSVG({ size: 100, className: 'arrow-next' });
const ArrowLeftStarring = ArrowLeftSVG({ size: 100, className: 'arrow-prev' });
const ArrowRightStarring = ArrowRightSVG({ size: 100, className: 'arrow-next' });

const MAXTITLES = common.maxtitles;
const SHOWTITLES = 10;

class Search extends Component {
  constructor(props) {
    super(props);
    this.menu1 = null;
    this.menu2 = null;
    this.mounted = false;
    this.MenuScroll = React.createRef();
    this.showMovieInfoPrevious = false;
  }
  state = {
    movies: [],
    movie: {},
    menu: '<div className="menu-item"></div>',
    selected: 'item0',
    showInProcess: false,
    loading: false,
    lastItemVisible: false,
    firstItemVisible: false,
    inertiascrolling: true,
    slowdownFactor: 0.75,
    titleCount: 10,
    pendingRightShift: false,
    reachedLastTitle: false,
    totalTitlesFetched: 0,
    newSearch: false,
    persistEvent: true,
    swipeDirection: 'none',
  };

  SearchTitle = (type, iname, tcount, lazyLoad, newSearch, startyear, endyear, blasphemy, profanity, sexual, racial, rating, bwords, ageratings, netflix, region) => {
    //console.log('SingleMovie, SearchTitle: newSearch: ', newSearch);
    //console.log('SingleMovie, SearchTitle: lazyload: ', lazyLoad);

    // type = name or starring
    var movieJoin = {};
    var newTotalTitlesFetched;

    //console.log('SingleMovie: test')
    this.setState({ loading: true });
    if (newSearch)
      this.setState({movies:[]})

    /*const urlTitle = fetch(`${common.DJANGOURL}/api/titles/?${type}=${iname}&count=${tcount}
      &startyear=${filterChoices['startyear']}&endyear=${filterChoices['endyear']}
      &blasphemy=${filterChoices['blasphemy']}&profanity=${filterChoices['profanity']}
      &sexual=${filterChoices['sexual']}&racial=${filterChoices['racial']}&tmdbrating=${filterChoices['rating']}
      &blockedwords=${filterChoices['blockedwords']}&ageratings= ${filterChoices['ageratings']}`);*/

    var basestr = `${common.DJANGOURL}/api/titles/?full=1&${type}=${iname}&count=${tcount}&startyear=${startyear}&endyear=${endyear}`


    /*&blasphemy=${blasphemy}&profanity=${profanity}&sexual=${sexual}&racial=${racial}`*/

    if (blasphemy >= 0)
      basestr = basestr.concat(`&blasphemy=${blasphemy}`)
    if (profanity >= 0)
      basestr = basestr.concat(`&profanity=${profanity}`)
    if (sexual >= 0)
      basestr = basestr.concat(`&sexual=${sexual}`)
    if (racial >= 0)
      basestr = basestr.concat(`&racial=${racial}`)
    if (bwords !== '0')
      basestr = basestr.concat(`&blockedwords=${bwords}`)
    if (ageratings !== '0')
      basestr = basestr.concat(`&ageratings=${ageratings}`)
    if (netflix == '1')
      basestr = basestr.concat(`&netflix=1`)
    if (region)
      basestr = basestr.concat(`&country=${region}`)

    //console.log('Base str: ', basestr)
    const urlTitle = fetch(basestr);

    const urls = [urlTitle];

    Promise.all(urls)
      .then(([r1]) => Promise.all([r1.json()]))
      .then(([data1]) => {
        // //console.log('SingleMovie, Setting Redux for movie data = ', data1)
        //console.log('SingleMovie, Setting Redux for Creits = ', data2)
        //console.log('SingleMovie, Setting Redux for Video = ',data3)
        //console.log('SingleMovie, Setting Redux for blocked = ', data4)
        movieJoin = data1;

        newTotalTitlesFetched = data1.length;

        //console.log('SingleMovie, SearchTitle: newTotalTitlesFetched', newTotalTitlesFetched);
        //console.log('SingleMovie, SearchTitle: state.TitlesFetched', this.state.totalTitlesFetched);
        //console.log('SingleMovie, SearchTitle: newSearch: ', newSearch);
        if (newTotalTitlesFetched != this.state.totalTitlesFetched || newSearch) {
          if (newSearch) {
            this.setState({
              reachedLastTitle: false,
              movies: movieJoin,
              loading: false,
              pendingRightShift: lazyLoad,
              totalTitlesFetched: newTotalTitlesFetched,
              titleCount: 10,
              newSearch: true,
             
            });
          } else {
            this.setState({
              reachedLastTitle: false,
              movies: movieJoin,
              loading: false,
              pendingRightShift: lazyLoad,
              totalTitlesFetched: newTotalTitlesFetched,
              newSearch: false,
              
            });
          }
          //this.renderRedirect();
        } else {
          this.setState({ loading: false, pendingRightShift: false, reachedLastTitle: true });
        }
        //console.log('search: moveJoin', movieJoin)
        //console.log('Search: Lazyload: ', lazyLoad)
      })
      .catch(err => {
        //console.log(err)
        this.setState({  loading: false });
      });
  };

  componentDidMount() {
    //console.log('Search_Generic: DidMoujnt: search_type', this.props)
    this.mounted = true;
   
    this.SearchTitle(this.props.search_type, this.props.search_name, 10, false, true, this.props.startyear, this.props.endyear,
      this.props.blasphemy, this.props.profanity, this.props.sexual, this.props.racial, this.props.rating, this.props.bwords,
      this.props.ageratings, this.props.netflix, this.props.region);
    this.showMovieInfoPrevious = this.props.showMovieInfo;
    //console.log('Search: DidMount')
    ReactGA.initialize('UA-140786114-1');
    ReactGA.pageview('/search');
  }


  componentWillUnmount() {
    this.mounted = false;
  }

  checkArrowVisibilityCondition = () => {
    //console.log('check Arrow Visibility')
    setTimeout(() => {
      if (this.menu1) {
        var newLastVisible = this.menu1.checkFirstLastItemVisibility({})['lastItemVisible'];
        this.setState({ lastItemVisible: newLastVisible });
      }
    }, 500);
  }

  LeftArrowProcess = () => {
    this.checkArrowVisibilityCondition()
  };


  TitlesClickLeftArrow = () => {
    //console.log('Search: Titlesclickleftarrow')
    //console.log('Search: Met condition Titlesclickleftarrow')
    this.menu1.handleArrowClick();
    this.LeftArrowProcess();
  };

  RightArrowProcess = () => {
    this.checkArrowVisibilityCondition()
  };



  TitlesClickRightArrow = () => {
    //console.log('Search: Titlesclickleftarrow')
    //console.log('Search: Met condition Titlesclickleftarrow')
    this.menu1.handleArrowClickRight();
    this.RightArrowProcess();
  };

  componentDidUpdate() {
    //console.log('Search_Generic: DidUpdate: search_type', this.props)
    //console.log('Search: DidUpdate newSearch: ', this.state.newSearch, this.state.movies)
    var reachedLastItem;

    if (this.props.showMovieInfo != this.showMovieInfoPrevious) {
      this.showMovieInfoPrevious = this.props.showMovieInfo;
    } else {
      //console.log('Search: DidUpdate: ', this.state.newSearch)
      /*if (this.state.pendingRightShift) {
        if (!isMobile) {
          this.TitlesClickRightArrow();
        }
        this.setState({ pendingRightShift: false });
      }*/
      if (this.state.newSearch) {
        
        if ((this.state.movies.length > 0) && this.menu1) {
          //console.log('Search: DidUpdate: TitlesClickLeftArrow() ', this.menu1)
          this.TitlesClickLeftArrow();
          this.setState({ newSearch: false });
        }

        
      }
    }
  }


  handleScroll = e => {
    //const bottom = Number((e.target.scrollHeight - e.target.scrollTop).toFixed(0)) - e.target.clientHeight < 50;
    //let page = this.state.page;
    //if (bottom) {
    // write fetching logic here...
    //}
    //console.log('Search: handleScroll')
  };

  componentWillReceiveProps(nextProps, nextState) {
    //console.log('Search_Generic: componentWillReceiveProps ', nextProps, this.props)
  }

  /*
    startyear={this.props.match.params.filter_choices_startyear}
    endyear={this.props.match.params.filter_choices_endyear}
  
    blasphemy={this.props.match.params.filter_choices_blasphemy}
    profanity={this.props.match.params.filter_choices_profanity}
    sexual={this.props.match.params.filter_choices_sexual}
    racial={this.props.match.params.filter_choices_racial}
    rating={this.props.match.params.filter_choices_rating}
    bwords={this.props.match.params.filter_choices_bwords}
    ageratings={this.props.match.params.filter_choices_ageratings}*/

  shouldComponentUpdate(nextProps, nextState) {
    //console.log('Search Generic: shouldComponentUpdate: ', nextProps.currentUser.pending, nextState.movies, this.state.movies, nextState.loading, this.state.loading, nextState.reachedLastTitle, this.state.reachedLastTitle)
    //console.log('Search Generic: shouldComponentUpdate: nextProps, this.props:  ', nextProps, this.props)
    if (JSON.stringify([nextProps.search_name, nextProps.startyear, nextProps.endyear, nextProps.blasphemy, nextProps.profanity, nextProps.sexual, nextProps.racial, nextProps.rating, nextProps.bwords, nextProps.ageratings, nextProps.netflix, nextProps.region])
      != JSON.stringify([this.props.search_name, this.props.startyear, this.props.endyear, this.props.blasphemy, this.props.profanity, this.props.sexual, this.props.racial, this.props.rating, this.props.bwords, this.props.ageratings, this.props.netflix, this.props.region])) {
      /*if (!isEqual(nextProps,this.props)) {*/
      //console.log('Search Generic: shouldComponentUpdate: updating database')
      this.SearchTitle(nextProps.search_type, nextProps.search_name, 10, false, true, nextProps.startyear, nextProps.endyear, nextProps.blasphemy, nextProps.profanity, nextProps.sexual, nextProps.racial, nextProps.rating, nextProps.bwords, nextProps.ageratings, nextProps.netflix, nextProps.region);
    }


    if (
      !nextProps.currentUser.pending ||
      JSON.stringify([nextProps.search_name, nextProps.startyear, nextProps.endyear, nextProps.blasphemy, nextProps.profanity, nextProps.sexual, nextProps.racial, nextProps.rating, nextProps.bwords, nextProps.ageratings, nextProps.netflix, nextProps.region])
      != JSON.stringify([this.props.search_name, this.props.startyear, this.props.endyear, this.props.blasphemy, this.props.profanity, this.props.sexual, this.props.racial, this.props.rating, this.props.bwords, this.props.ageratings, this.props.netflix, this.props.region]) ||
      /*!isEqual(nextProps,this.props) ||*/
      nextState.movies != this.state.movies ||
      nextState.loading != this.state.loading ||
      nextState.reachedLastTitle != this.state.reachedLastTitle

    ) {
      //console.log('Search: ShouldUpdate')
      //console.log('Search Generic: shouldComponentUpdate: TRUE')
      return true;
    } else return false;

    /*
    if(nextProps.movies.length!=0)
      if (this.props.movies == 0)
        return true;
      else
        if (this.props.movies[0].poster != nextProps.movies[0].poster)
          return true;
        else
          return false;
    else
      return false; */
  }





  lazyLoad = () => {
    //console.log('Call search lazyLoad')
    var newTitleCount, newSearch;

    if (!this.state.loading && !this.state.newSearch) 
      if (this.state.titleCount < MAXTITLES && (!this.state.reachedLastTitle)) {
        newTitleCount = this.state.titleCount + 10;
        this.setState({
          loading: true,
          titleCount: newTitleCount,
          //lastItemVisible: true,
          //pendingRightShift: true,
        });
        newSearch = false;

        this.SearchTitle(
          this.props.search_type,
          this.props.search_name,
          newTitleCount,
          true,
          newSearch,
          this.props.startyear,
          this.props.endyear,
          this.props.blasphemy,
          this.props.profanity,
          this.props.sexual,
          this.props.racial,
          this.props.rating,
          this.props.bwords,
          this.props.ageratings,
          this.props.netflix,
          this.props.region);
      }
      else if (!this.state.lastItemVisible) {
        this.setState({ lastItemVisible: true })
      }
  }

  buildBlankMovie = index => {
    var rtime = Math.floor(Math.random() * 4) + 1;

    //console.log('search buildBlankMovie index: ',index, this.state.movies, this.state.movies.length)
    return (

      <div className={((index == 1) && (this.state.movies.length > 0)) ? "menu-item-first-loader" : "menu-item"} key={index}>
        <ContentLoader
          speed={rtime}
          width={150}
          height={225}
          viewBox="0 0 150 225"
          backgroundColor="#222222"
          foregroundColor="#000000"
        >
          <rect x="0" y="0" rx="2" ry="2" width="150" height="225" />
        </ContentLoader>
      </div>
    );
  };

  render() {
    const {
      selected,
      movies,
      inertiascrolling,
      slowdownFactor,
      loading,
      persistEvent,
    } = this.state;
    const movieEmpty = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    var alignCenterTitles = false;
    var alignCenterStarring = false;
    var mlen = 0;

    //console.log'Search render: ', this.props)


    if ('titles' in movies)
      if (movies.length > 5) alignCenterTitles = false;
      else alignCenterTitles = true;


    var movieData = [];
    var movieFull = []
    var moviesMatch = []
    var moviesNoMatch = []

    moviesMatch = movies.filter( v => v.found == true)
    moviesNoMatch = movies.filter( v => v.found == false)

    if (movies) {
      mlen = movies.length;
      //console.log('Search Generic movie length:', movies, mlen)
      //console.log('movies filtered:', moviesMatch, moviesNoMatch)
      movieFull = movies.map((movie, index) => {
        //console.log('Search_new index: ',index)
        if (movie) {
          if (index == mlen - 1) {
            //console.log('List.js reached last movie: ', movie,index)
            return (
              <div className="menu-item-last" key={movie.imdbID}>
                <WrapSingleMovie movie={movie} netflix_region={this.props.region} />
              </div>
            );
          } else {
            return (
              <div className="menu-item" key={movie.imdbID}>
                <WrapSingleMovie movie={movie} netflix_region={this.props.region} />
              </div>
            );
          }
        }
        else {
          return (
            <div className="menu-item"></div>
          )
        }
      });
    }


    if (!loading) {
      movieData = movieFull;
      //console.log('List.js: movieData not loading ', movieData)
    } else {
      //movieData = movieLoading;
      if ((movieFull.length > 0) )
        movieData = movieFull.concat(
          movieEmpty.map(index => {
            return this.buildBlankMovie(index);
          }));
      else {
        movieData = movieEmpty.map(index => {
          return this.buildBlankMovie(index);
        });
      }
      //console.log('List.js: movieData loading ', movieData)
    }





    

      return (

        <div>
          {isMobile ? (
            <div className="outside-lists-search">
              {movieData.length > 0 ? (
                <div className="lists-search">
                  <h4 style={{ marginLeft: '10px', color: 'black' }}>{this.props.search_title}</h4>
                  <ScrollMenu
                    ref={el => {
                      this.menu1 = el;
                    }}
                    data={movieData}
                    wheel={false}
                    dragging={true}
                    alignCenter={false}
                    clickWhenDrag={false}
                    innerWrapperStyle={{ display: 'flex', alignItems: 'center' }}
                    inertiaScrolling={true}
                    inertiaScrollingSlowdown={0.7}
                    transition={1}
                    onLastItemVisible={this.lazyLoad}
                  />
                </div>
              ) : (
                  <div className="lists-search-blank"></div>
                )}
              {/* FIXME: Should only call if showMovie is true - but this fails to update timeline */}
              <WrapMovieInfoDialog open={this.props.showMovieInfo} movieID={this.props.movieID} region={this.props.region} />
              <WrapTVInfoDialog open={this.props.showTVInfo} region={this.props.region} />
            </div>
          ) : (
              <div className="outside-lists-search">
                {movieData.length > 0 ? (
                  <div className="lists-search">
                    <h4 style={{ marginLeft: '50px', color: 'black' }}>{this.props.search_title} (matching filter)</h4>

                    <div className="lists-layout">
                      <div className="lists-button">
                        <IconButton
                          style={{ backgroundColor: '#dddddd' }}
                          aria-label="Expand"
                          onClick={this.TitlesClickLeftArrow}
                        >
                          <ArrowLeftSVGF />
                        </IconButton>
                      </div>

                      <div className="lists-scrollmenu">

                        <ScrollMenu
                          ref={el => {
                            this.menu1 = el;
                          }}
                          translate={0}
                          data={movieData}
                          scrollToSelected={true}
                          wheel={false}
                          dragging={false}
                          alignCenter={false}
                          innerWrapperStyle={{ display: 'flex', alignItems: 'center' }}
                          clickWhenDrag={false}
                          onLastItemVisible={this.lazyLoad}
                        />

                      </div>

                      <div className="button">
                        {!(this.state.reachedLastTitle && this.state.lastItemVisible) && (
                          <IconButton
                            style={{ backgroundColor: '#dddddd' }}
                            aria-label="Expand"
                            onClick={this.TitlesClickRightArrow}
                          >
                            <ArrowRightSVGF />
                          </IconButton>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                    <div className="lists-search-blank"></div>
                  )}
                {/* FIXME: Should only call if showMovie is true - but this fails to update timeline */}

                <WrapMovieInfoDialog open={this.props.showMovieInfo} movieID={this.props.movieID} region={this.props.region} />
                <WrapTVInfoDialog open={this.props.showTVInfo} region={this.props.region} />

              </div>
            )}
        </div>
      );

  }
}

const WrapSearch = connect(mapStateToProps)(Search);

export default WrapSearch;
