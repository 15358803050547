import * as actionType from '../constants/action-types';
import * as common from '../../common.js'

const MAX_SCREEN_WIDTH = common.maxScreenWidth

const initialState = {
  status: {
    pending: false,
    error: null,
    wide: true
  },
};

export const startup = (state = initialState.status, action) => {
  switch (action.type) {
    case actionType.STARTUP_REQUEST:
      //console.log('SAGA: STARTUP_REQUEST')
      return { ...state, pending: true, error: null };
    case actionType.STARTUP_SUCCESS:
      return initialState.status;
    case actionType.STARTUP_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case actionType.SCREEN_RESIZE:
        if (action.payload)
          if (action.payload.width < MAX_SCREEN_WIDTH)
            return {...state, wide: false}
          else
            return {...state, wide: true}
        else
          return state

    default:
      return state;
  }
};
