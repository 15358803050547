/***********************************************************************
 *
 * DESCRIPTION :
 *   Root javascript
 *
 * PUBLIC FUNCTIONS :
 *
 * NOTES :
 *   Redux is wrapped around App
 *
 * AUTHOR(S) :
 *   D.L. Johnson    START DATE : 1 Jan 2019
 *
 * Copyright 3Wise 2019. All rights reserved.
 ***********************************************************************/

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';

/* Redux store */
//import store from "./redux/store/index"

/* Load the main application */
const app = <App />;

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

//serviceWorker.register();
serviceWorker.unregister();
