import React, { Component } from 'react';
import { Router, browserHistory } from 'react-router';


import { connect } from 'react-redux';
import {
    fetchUserRequest,
    userLoginRequest,
    userLoginWithProviderRequest,
    userUpdatePassword,
    userUpdateRequest,
} from '../../../redux/actions';

import { Redirect } from 'react-router-dom';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import google_image from '../../../assets/Google_G_Logo.svg';
import threewise_image from '../../../assets/3wise_logo_nologo.png';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import View from 'react';
import Text from 'react';

import CheckIcon from '@material-ui/icons/Check';
import RemoveIcon from '@material-ui/icons/Remove';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


import Divider from '@material-ui/core/Divider';

import * as constants from '../constants.js'
import * as common from '../../../common.js'
import { PayPalButton } from "react-paypal-button-v2";

import './upgrade.css';

const StyledTableCellLight = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableCellLightHead = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableCellDark = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);


const StyledTableCellLightYellow = withStyles((theme) => ({
    root: {
        backgroundColor: 'rgb(254,207,0,0.4)',
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);


const StyledTableCellDarkYellow = withStyles((theme) => ({
    root: {
        backgroundColor: 'rgb(254,207,0,0.6)',
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableCellLightYellowHead = withStyles((theme) => ({
    head: {
        backgroundColor: 'rgb(254,207,0,0.4)',
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);


const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);



function createData(feature, basic, plus) {
    return { feature, basic, plus };
}

const rows = [
    createData(['Netflix link', 'Get filter results for Netflix in your country (62% of Netflix titles available adn growing)'], 0, 1),
    createData(["Word-level filtering", "Select specific swear words you don't want to see in your browser or search results e.g. All John Cusack movies that don't use the F**k word"], 0, 1),
    createData(['Custom profiles', 'Save your favourte custom filter profiles'], 0, 1),
    createData(['Comprehensive filter', 'Browse or search Movies/TV Series using year range, maximum swear words (Blasphemy, Profanity, Sexual and Racial words) and Age Ratings'], 1, 1),
    createData(['Timeline of swear words', 'See exactly when a swear word occurs and the exact phrase used with swear word starred out'], 1, 1),
    createData(['Search 65000 titles', 'Comprehensive up to date movies and TV series titles updated every 2 days dating back to 1901'], 1, 1),
];

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});


// <SignUpLink />
const plusnetflix = common.BASEURL + '/img/icons/' + 'plus_netflix.png';
const plusfilter = common.BASEURL + '/img/icons/' + 'plus_filter.png';

const styles = theme => ({
    container: {
        margin: 'auto',
        marginTop: 100,
        maxWidth: 400,
        color: '#222222',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#EEEEEE',
    },

    memberOuter: {
        margin: '10px'
    },

    memberBox: {
        width: 340,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid #CCCCCC',
        padding: '5px',
        boxShadow: '0 0 5px #BBBBBB'
    },


    loginError: {
        width: '340px',
        paddingLeft: '5px',
        paddingRight: '5px',
        paddingBottom: '10px',
        paddingTop: '10px',
        fontSize: '14px',
        color: '#222222',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#ffefef',
        border: '1px solid #cc0000',
    },

    updated: {
        width: '340px',
        paddingLeft: '5px',
        paddingRight: '5px',
        paddingBottom: '10px',
        paddingTop: '10px',
        fontSize: '14px',
        color: '#222222',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#feffc8',
        border: '1px solid #BBBBBB',
    },


    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        minWidth: 300,
    },

    button: {
        margin: theme.spacing.unit,
        textTransform: 'none',
    },

    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
});

class UpgradeProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: this.props.currentUser.email,
            pass: '',
            goHome: false,
            goChangePassword: false,
            cancel: false,
            error: '',
            updated: '',
            joinDate: this.props.currentUser.join,
            memberPlan: this.props.currentUser.type,
            memberNews: this.props.currentUser.newsletter,
            revealPassword: false,
            currentPass: '',
            pass1: '',
            pass2: '',
            chooseMonthly: true,
            planText: 'Monthly plan',
            planCost: '$2.00' 

        };
        this.onFormSubmit = this.onFormSubmit.bind(this);

    }

    componentWillReceiveProps(nextProps) {
        //console.log('Profile, will receiveprops: all: ', nextProps.currentUser)
        if (nextProps.currentUser.isLoggedIn && !nextProps.currentUser.pending) {
            //console.log('Signin Page: componentWillReceiveProps ')
            //this.props.history.push('/');
            //browserHistory.push('/')
            //console.log('Profile, will receiveprops: ', nextProps.currentUser, this.props.currentUser);

            this.setState({
                joinDate: nextProps.currentUser.join,
                memberPlan: nextProps.currentUser.type,
                memberNews: nextProps.currentUser.newsletter,
                email: nextProps.currentUser.email
            })
            if (!this.props.currentUser.pending) {
                if ((nextProps.currentUser.joinDate != this.props.currentUser.joinDate) ||
                    (nextProps.currentUser.type != this.props.currentUser.type) ||
                    (nextProps.currentUser.newsletter != this.props.currentUser.newsletter) ||
                    (nextProps.currentUser.email != this.props.currentUser.email)) {

                    this.setState({ updated: 'Your changes have been saved' })


                }
            }


        }
        if (!nextProps.currentUser.isLoggedIn)
            this.setState({ goHome: true })




        //console.log('SignUp: ', nextProps.currentUser);
        if (nextProps.currentUser.error != null) {

            if (nextProps.currentUser.error.errorCode == constants.ERROR_CODE_ACCOUNT_RELOGIN) {
                //console.log(constants.ERROR_MSG_ACCOUNT_RELOGIN);
                this.setState({ error: constants.ERROR_MSG_ACCOUNT_RELOGIN, errorType: constants.ERROR_CODE_ACCOUNT_RELOGIN });
            }
            if (nextProps.currentUser.error.errorCode == constants.ERROR_CODE_INVALID_EMAIL) {
                //console.log(constants.ERROR_MSG_INVALID_EMAIL);
                this.setState({ error: constants.ERROR_MSG_INVALID_EMAIL, errorType: constants.ERROR_CODE_INVALID_EMAIL });
            }
            if (nextProps.currentUser.error.errorCode == constants.ERROR_CODE_ACCOUNT_EXISTS) {
                //console.log(constants.ERROR_MSG_ACCOUNT_EXISTS);
                this.setState({ error: constants.ERROR_MSG_ACCOUNT_EXISTS, errorType: constants.ERROR_CODE_ACCOUNT_EXISTS });
            }

        }



    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    changePassword = () => {
        this.setState({ goChangePassword: true })
    }

    createAccount = () => {
        //console.log('SignIn: Create Account');
        this.setState({ goSignUp: true });
    };

    onFormSubmit(event) {

        var datapack = {
            uid: this.props.currentUser.uid,
            old: {
                email: this.props.currentUser.email,
                accType: this.props.currentUser.type,
                newsletter: this.props.currentUser.newsletter
            },
            new: {
                email: this.state.email,
                accType: this.state.memberPlan,
                newsletter: this.state.memberNews
            }
        };


        //console.log('Profile datapack: ', datapack);
        this.props.updateUser(datapack);

        /*fetch(`${common.DJANGOURL}/api/users/${this.props.currentUser.uid}/`, {
          method: 'patch',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Accept: 'application/json',
          },
          body: JSON.stringify(datapack),
        })
          .then(function (response) {
            return response.json();
          })
          .catch(function (ex) {
            //console.log("Profile update failed: ", ex);
          });*/


    }



    handlenewsToggle = () => {
        //console.log('togglenews')
        this.setState({ memberNews: !this.state.memberNews })
    }

    shouldComponentUpdate(nextProps, nextState) {

        //console.log('Signin: ShouldUpdate')
        return true;

    }

    handleClose = () => {
        this.setState({ goHome: true, cancel: true });
        this.props.OnDone();
    };

    selectMonthly = () => {
        //console.log'Select Monthly')
        this.setState({chooseMonthly:true, planText:'Monthly plan', planCost: '$2.00'})
    }

    selectYearly = () => {
        //console.log'Select Monthly')
        this.setState({chooseMonthly:false, planText:'Yearly plan', planCost: '$15.00'})
    }

    render() {
        const { classes, ...other } = this.props;
        const { error, updated } = this.state;
        //console.log('Profile: ', this.props.currentUser, this.state)


        return (
            <div className="plusroot">
                <div className="plusheader" >
                    <p className="plusHeading">3Wise plus </p>
                    <p className="plusParagraph">For the price of a cup of coffee each month, finally find those Netflix movies that are safe for your family to watch, select specific swear words you don't want to see in your results and save your favourite filter profiles</p>
                </div>


                <div className="plusfeatures">
                    <div className="singlefeature">
                        <div className="tickfeature">
                            <CheckIcon style={{ fontSize: 20, fontWeight: 'bold' }} />
                        </div>
                        <p className="ticktext"> Netflix links</p>
                    </div>
                    <div className="singlefeature">
                        <div className="tickfeature">
                            <CheckIcon style={{ fontSize: 20, fontWeight: 'bold' }} />
                        </div>
                        <p className="ticktext"> Word level filtering</p>
                    </div>
                    <div className="singlefeature">
                        <div className="tickfeature">
                            <CheckIcon style={{ fontSize: 20, fontWeight: 'bold' }} />
                        </div>
                        <p className="ticktext"> Save custom filters</p>
                    </div>

                </div>

                <div className="pluslearnmore">
                    <Link style={{ textDecoration: 'none' }} to={'/infoplus'}>
                        Compare 3Wise Plus and Basic
                </Link>
                </div>

                <Divider style={{ width: '700px' }} />

                <div className="plusheader" >
                    <p className="plusHeading">Complete payment process </p>
                    <p className="plusParagraph"> Your support pays for the running of our servers and the development of exciting new features we can't wait to show you</p>
                </div>

                <div className="pluspayoptions">

                    <div className={(this.state.chooseMonthly)?"payoptionHighlight":"payoption"} role="button" onClick={this.selectMonthly}>
                        <p className="plusCostHeading" >
                            MONTHLY
                        </p>
                        <div className="plusCostLine">
                            <div className="plusCost">
                                $2
                            </div>
                            <div className="plusPer">
                                per month
                            </div>
                        </div>
                        <div className="plusCostMonth">
                            <p className="plusSubtextHighlight">
                                First month is Free!
                        </p>
                            <p className="plusSubtext">
                                Cancel anytime
                        </p>
                        </div>
                    </div>
                    <div className={(!this.state.chooseMonthly)?"payoptionHighlight":"payoption"}role="button" onClick={this.selectYearly} >
                        <p className="plusCostHeading">
                            YEARLY
                        </p>
                        <div className="plusCostLine">
                            <div className="plusCost">
                                $15
                            </div>
                            <div className="plusPer">
                                per year
                            </div>
                        </div>
                        <p className="plusSubtextHighlight">
                            Save 37.5% per year
                        </p>
                    </div>

                </div>

                <div className="orderSummary">
                    <b>Summary of your oder</b>

                    <Divider style={{ width: '100%', marginTop: "10px", marginBottom: "10px" }} />

                    <b>{this.state.planText} </b>
                    <p><i>Quickly find netflix movies that match your filters, fine grain filtering with word search and ability to save filter profiles</i></p>
                    <div className="orderCostLine">
                        <p>Plus Membership</p>
                        <b>{this.state.planCost}</b>
                    </div>
                   
                    <div className="orderPaypalOuter">
                    <div className="orderPaypalInner">
                        <PayPalButton
                            options={{
                                clientId: "AXUHZHtMwiUlpjZVezrDKYgOdwYXJjp0P7s7luzrnJBaanwU-gKVKY-KpxsDypE3a9-jQ5rGXtW0sFDP",
                                vault: true
                            }}
                            createSubscription={(data, actions) => {
                                return actions.subscription.create({
                                    plan_id: 'P-20D19789PY994702WL2W4PCI'
                                });
                            }}
                            onApprove={(data, actions) => {
                                // Capture the funds from the transaction
                                return actions.subscription.get().then(function (details) {
                                    // Show a success message to your buyer
                                    alert("Subscription completed");


                                });
                            }}
                        />
                    </div>
                    </div>
                    

                    <Divider style={{ width: '100%', marginTop: "10px", marginBottom: "10px" }} />

                    <b>Important information </b>
                    {this.state.chooseMonthly?(
                    <ul>
                        <li>The first month of your subscription will be free</li>
                        <li>You will be billed monthly using the information you provide to PayPal</li>
                        <li>You will only be billed after the 30 day free trial ends</li>
                        <li>You may Cancel at anytime and will continue to enjoy 3wise plus up until the end of the billing cycle</li>
                    </ul>)
                    :
                    (<ul>
                        <li>You will be billed yearly using the information you provide to PayPal</li>
                        <li>You may Cancel at anytime and you will still enjoy the 3Wise plus service up until the end of the billing cycle</li>
                    </ul>)
                    }


                </div>


            </div>


        );
    }
}

function mapStateToProps(state) {
    return {
        currentUser: state.currentUser,
    };
}

const mapDispatchToProps = dispatch => ({
    fetchUser: () => dispatch(fetchUserRequest()),
    updateUser: (userdata) => dispatch(userUpdateRequest(userdata)),
    loginUser: user => dispatch(userLoginRequest(user)),
    loginWithProvider: provider => dispatch(userLoginWithProviderRequest(provider)),
});

const WrapUpgradeProfile = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UpgradeProfile));

export default WrapUpgradeProfile;