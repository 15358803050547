import React, { Component } from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import ContentLoader from "react-content-loader"
//import ScrollMenu from '../../../components/Widgets/ScrollMenu/scrollMenu.tsx'
import WrapSingleMovie from './SingleMovie.js';
import axios from 'axios';
import * as common from '../../../common.js';
import { isMobile } from 'react-device-detect';
import LoadingOverlay from 'react-loading-overlay';

import IconButton from '@material-ui/core/IconButton';
//import { Swipeable, useSwipeable } from 'react-swipeable';
import SwipeableHook from './SwipeableHook.js';
import './Lists.css'


const RIGHT = '-1';
const LEFT = '+1';
const SEARCHYEAR = 1970;
const SEARCHRATING = 3;
const MAXTITLES = common.maxtitles;

Math.seededRandom = function (max, min, seed) {
  max = max || 1;
  min = min || 0;

  seed = (seed * 9301 + 49297) % 233280;
  var rnd = seed / 233280.0;

  return min + rnd * (max - min);
}

function shuffleArray(array, seed) {
  for (let i = array.length - 1; i > 0; i--) {
    //const j = Math.floor(Math.random() * (i + 1));
    const j = Math.floor(Math.seededRandom(1, 0, seed) * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array
}


const Arrow = ({ text, className }) => {
  return <div className={className}>{text}</div>;
};

const ArrowRightSVG = ({ size, className }) => {
  return (
    <div className={className}>
      <svg height="16" width="16">
        <polygon points="0,0 16,8 0,16" fill="darkgrey" />
      </svg>
    </div>
  );
};

const ArrowLeftSVG = ({ size, className }) => {
  return (
    <div className={className}>
      <svg height="16" width="16">
        <polygon points="16,0 0,8 16,16" fill="darkgrey" />
      </svg>
    </div>
  );
};

function ArrowLeftSVGF() {
  return (
    <div className={'arrow-prev'}>
      <svg height="16" width="16">
        <polygon points="16,0 0,8 16,16" fill="darkgrey" />
      </svg>
    </div>
  );
}

function ArrowRightSVGF() {
  return (
    <div className={'arrow-prev'}>
      <svg height="16" width="16">
        <polygon points="0,0 16,8 0,16" fill="darkgrey" />
      </svg>
    </div>
  );
}

function GetPastYYMMDD(months) {
  var d = new Date();
  d.setMonth(d.getMonth() - months)
  var dPast = d.toISOString().slice(0, 10)
  return dPast

}

const styles = theme => ({
  horizontalScroll: {
    overflowY: 'scroll',
    overflowX: 'scroll',
    overflow: 'scroll',
    zIndex: 0,
    scrollBehavior: 'smooth',
  },
});

const persistSyntheticEvent = (func, persist) => {
  return (e, ...rest) => {
    if (persist && e.persist) e.persist();
    return func(e, ...rest);
  };
};

const RandomizeResult = (res, seed) => {

  //randomArray is the seed
  var finalres;

  // Shuffle first 20, then in groups of 10

  if (res.length <= 20)
    finalres = shuffleArray(res, seed)
  else {
    var first_twenty = res.slice(0, 20);
    //console.log('randomize: first_twenty', res)
    finalres = shuffleArray(first_twenty, seed)
    //console.log('randomize: first_twenty_rand', finalres)
    var blocks_ten = Math.floor((res.length - 20) / 10)
    //console.log('randomize: blocks_ten', blocks_ten)

    for (var i = 0; i < blocks_ten; i++) {
      var idx = 20 + i * 10
      var next_ten = res.slice(idx, idx + 10)
      //console.log('randomize: next_ten', idx, next_ten)
      var next_ten_rand = shuffleArray(next_ten, seed)
      //console.log('randomize: next_ten_rand', next_ten_rand)
      finalres.push(...next_ten_rand)
      //console.log('randomize: finalres', finalres)
    }

    var blocks_left = res.length % 10;
    if (blocks_left > 0) {
      idx = 20 + i * 10;
      var remaining = res.slice(idx, idx + blocks_left);
      var remaining_rand = shuffleArray(remaining);
      finalres.push(...remaining_rand)
    }

    //finalres = res
  }

  return finalres

}

//const ArrowLeft = Arrow({ text: "<", className: "arrow-prev" });
//const ArrowRight = Arrow({ text: ">", className: "arrow-next" });
const ArrowLeft = ArrowLeftSVG({ size: 100, className: 'arrow-prev' });
const ArrowRight = ArrowRightSVG({ size: 100, className: 'arrow-next' });

const DIRECTIONS = ['Left', 'Right', 'Up', 'Down'];




class List extends Component {
  constructor(props) {
    super(props);
    this.menu1 = null;
    this.mounted = false;
    this.showMovieInfoPrevious = false;
  }

  state = {
    themovies: [],
    movie: {},
    showInProcess: false,
    loading: false,
    lastItemVisible: false,
    firstItemVisible: false,
    inertiascrolling: true,
    slowdownFactor: 0.75,
    titleCount: 20,
    searchYear: SEARCHYEAR,
    searchRating: SEARCHRATING,
    pendingRightShift: false,
    reachedLastTitle: false,
    totalTitlesFetched: 0,
    newSearch: false,
    swipeDirection: 'none',
    persistEvent: true,
    swiping: false,
    blockVerticalScroll: false
  };

  //this.SearchTitle(this.state.searchYear,this.state.searchRating,20,title,false,true)
  SearchTitle = (searchYear, searchRating, title, filterChoices, titleCount, lazyLoad, newSearch, seed, region) => {
    //console.log('Browse, List.js: newSearch, title: ', newSearch,title);
    //console.log('Browse, List.js: lazyload: ', lazyLoad);
    //console.log('List.js: SearchTitle: ', filterChoices);

    var newTotalTitlesFetched;
    var movieData = [];
    this.setState({ loading: true });

    var genreKeyWords = common.genreMapping[title]
    var datePast = GetPastYYMMDD(common.netflixWindow)

    var apistr = '';
    //&rating=${searchRating}

    if (this.props.listType == 'netflix') {
      if (this.props.type == 'movie') {
        /*apistr = `${common.DJANGOURL}/api/moviegenres/?startyear=${filterChoices['startyear']}&endyear=${filterChoices['endyear']}
        &count=${titleCount}&country=${region}&ageratings=${filterChoices['ageratings']}&netflixstart=2022-03-27`;*/

        apistr = `${common.DJANGOURL}/api/moviegenres/?count=${titleCount}&country=${region}
        &ageratings=${filterChoices['ageratings']}&blockedwords=${filterChoices['blockedwords']}&netflixstart=${datePast}&sort=date`;

        if (filterChoices['locklanguage'])
          apistr = apistr.concat(`&olanguage=${filterChoices['original_language']}`)

        if (!filterChoices['excludeBlasphemy'])
          apistr = apistr.concat(`&blasphemy=${filterChoices['blasphemy']}`)
        if (!filterChoices['excludeProfanity'])
          apistr = apistr.concat(`&profanity=${filterChoices['profanity']}`)
        if (!filterChoices['excludeSexual'])
          apistr = apistr.concat(`&sexual=${filterChoices['sexual']}`)
        if (!filterChoices['excludeRacial'])
          apistr = apistr.concat(`&racial=${filterChoices['racial']}`)

        apistr = apistr.concat(`&netflix=1`)

        //console.log('List.js: apistr = ', apistr, filterChoices)
        //apistr = common.DJANGOURL+ '/api/moviegenres/?year=1960&rating=4&genre=' + this.props.title;
      } else {
        /*apistr = `${common.DJANGOURL}/api/tvgenres/?startyear=${filterChoices['startyear']}&endyear=${filterChoices['endyear']}
        &count=${titleCount}&country=${region}&ageratings=${filterChoices['ageratings']}&netflixstart=2022-03-27`;*/
        apistr = `${common.DJANGOURL}/api/tvgenres/?count=${titleCount}&country=${region}
        &ageratings=${filterChoices['ageratings']}&netflixstart=${datePast}&sort=date`;


        if (filterChoices['locklanguage'])
          apistr = apistr.concat(`&olanguage=${filterChoices['original_language']}`)
        if (!filterChoices['excludeBlasphemy'])
          apistr = apistr.concat(`&blasphemy=${filterChoices['blasphemy']}`)
        if (!filterChoices['excludeProfanity'])
          apistr = apistr.concat(`&profanity=${filterChoices['profanity']}`)
        if (!filterChoices['excludeSexual'])
          apistr = apistr.concat(`&sexual=${filterChoices['sexual']}`)
        if (!filterChoices['excludeRacial'])
          apistr = apistr.concat(`&racial=${filterChoices['racial']}`)

        apistr = apistr.concat(`&netflix=1`)


        //apistr = `${common.DJANGOURL}/api/tvgenres/?year=${searchYear}&count=${titleCount}&genre=${title}`;
        //apistr = common.DJANGOURL+ '/api/tvgenres/?year=1960&rating=4&genre=' + this.props.title;
      }

    }

    if (this.props.listType == 'browserlist') {
      if (this.props.type == 'movie') {
        apistr = `${common.DJANGOURL}/api/moviegenres/?startyear=${filterChoices['startyear']}&endyear=${filterChoices['endyear']}
        &count=${titleCount}&genre=${genreKeyWords}&tmdbrating=${filterChoices['rating']}
        &blockedwords=${filterChoices['blockedwords']}&ageratings=${filterChoices['ageratings']}&country=${region}`;


        if (filterChoices['locklanguage'])
          apistr = apistr.concat(`&olanguage=${filterChoices['original_language']}`)
        if (!filterChoices['excludeBlasphemy'])
          apistr = apistr.concat(`&blasphemy=${filterChoices['blasphemy']}`)
        if (!filterChoices['excludeProfanity'])
          apistr = apistr.concat(`&profanity=${filterChoices['profanity']}`)
        if (!filterChoices['excludeSexual'])
          apistr = apistr.concat(`&sexual=${filterChoices['sexual']}`)
        if (!filterChoices['excludeRacial'])
          apistr = apistr.concat(`&racial=${filterChoices['racial']}`)
        if (filterChoices['netflix'])
          apistr = apistr.concat(`&netflix=1`)
        if (filterChoices['christian'])
          apistr = apistr.concat(`&christian=1`)

        //console.log('List.js: apistr = ', apistr, filterChoices)
        //apistr = common.DJANGOURL+ '/api/moviegenres/?year=1960&rating=4&genre=' + this.props.title;
      } else {
        apistr = `${common.DJANGOURL}/api/tvgenres/?startyear=${filterChoices['startyear']}&endyear=${filterChoices['endyear']}
        &count=${titleCount}&genre=${genreKeyWords}&tmdbrating=${filterChoices['rating']}
        &blockedwords=${filterChoices['blockedwords']}&ageratings=${filterChoices['ageratings']}&country=${region}`;

        if (filterChoices['locklanguage'])
          apistr = apistr.concat(`&olanguage=${filterChoices['original_language']}`)
        if (!filterChoices['excludeBlasphemy'])
          apistr = apistr.concat(`&blasphemy=${filterChoices['blasphemy']}`)
        if (!filterChoices['excludeProfanity'])
          apistr = apistr.concat(`&profanity=${filterChoices['profanity']}`)
        if (!filterChoices['excludeSexual'])
          apistr = apistr.concat(`&sexual=${filterChoices['sexual']}`)
        if (!filterChoices['excludeRacial'])
          apistr = apistr.concat(`&racial=${filterChoices['racial']}`)
        if (filterChoices['netflix'])
          apistr = apistr.concat(`&netflix=1`)


        //apistr = `${common.DJANGOURL}/api/tvgenres/?year=${searchYear}&count=${titleCount}&genre=${title}`;
        //apistr = common.DJANGOURL+ '/api/tvgenres/?year=1960&rating=4&genre=' + this.props.title;
      }
    }

    // Remove white space from string
    var apistrNS = apistr.replace(/ /g, '')
    axios
      .get(apistrNS)
      .then(res => {
        if (this.mounted) {
          movieData = RandomizeResult(res.data, seed);
          //movieData = res.data;

          newTotalTitlesFetched = movieData.length;

          //console.log('Lists.js  newTotalTitlesFetched', newTotalTitlesFetched);
          //console.log('Lists.js  state.TitlesFetched', this.state.totalTitlesFetched);
          //console.log('Lists.js , SearchTitle: newSearch: ', newSearch);
          if (newTotalTitlesFetched != this.state.totalTitlesFetched || newSearch) {
            // Check if Total Titles Fetched from database < titleCount to fit in scroller
            var reachedLastTitle = false
            if (newTotalTitlesFetched < this.state.titleCount)
              var reachedLastTitle = true

            this.setState({
              reachedLastTitle: reachedLastTitle,
              themovies: movieData,
              //loading: false,
              //pendingRightShift: false,
              totalTitlesFetched: newTotalTitlesFetched,
              //titleCount: 20,
              //newSearch: true,
            });

            setTimeout(() => {
              this.setState({
                loading: false,
              })
            }, 2000);

            /*if (newSearch) {
              this.setState({
                reachedLastTitle: reachedLastTitle,
                themovies: movieData,
                loading: false,
                //pendingRightShift: false,
                totalTitlesFetched: newTotalTitlesFetched,
                //titleCount: 20,
                //newSearch: true,
              });
            } else {
              this.setState({
                reachedLastTitle: reachedLastTitle,
                themovies: movieData,
                loading: false,
                //pendingRightShift: false,
                totalTitlesFetched: newTotalTitlesFetched,
              });
            }*/
            //this.renderRedirect();
          } else {
            this.setState({ loading: false, reachedLastTitle: true });
          }
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        //console.log('Browse: Get DB Error');
      });
  };



  componentDidMount() {
    //console.log('List.js component mount')
    this.mounted = true;
    this.showMovieInfoPrevious = this.props.showMovieInfo;
    //When mounted newSearch is true
    this.SearchTitle(
      this.state.searchYear,
      this.state.searchRating,
      this.props.title,
      this.props.filterChoices,
      20,
      false,
      true,
      this.props.movieShuffleSeed,
      this.props.region
    );
  }

  componentWillUnmount() {
    //console.log('List.js component unmount')
    this.mounted = false;
  }


  checkArrowVisibilityCondition = () => {
    //console.log('check Arrow Visibility')
    setTimeout(() => {
      var newLastVisible = this.menu1.checkFirstLastItemVisibility({})['lastItemVisible'];
      //console.log('checkArrowVisibile: ', this.props.listType, newLastVisible)
      this.setState({ lastItemVisible: newLastVisible });
    }, 500);
  }

  LeftArrowProcess = () => {
    this.checkArrowVisibilityCondition()
  };

  TitlesClickLeftArrow = () => {
    //console.log('Search: Titlesclickleftarrow')
    //console.log('Search: Met condition Titlesclickleftarrow')
    this.menu1.handleArrowClick();
    this.LeftArrowProcess();
  };

  RightArrowProcess = () => {
    this.checkArrowVisibilityCondition()
  };

  TitlesClickRightArrow = () => {
    //console.log'List.js: Titlesclickrightarrow: ', this.menu1)
    //console.log('Search: Met condition Titlesclickleftarrow')
    this.menu1.handleArrowClickRight();
    this.RightArrowProcess();
  };

  /*componentDidUpdate() {
    if (this.state.pendingRightShift) {
      this.TitlesClickRightArrow();
      this.setState({ pendingRightShift: false });
    }
  }*/

  shouldComponentUpdate(nextProps, nextState) {
    //console.log'List.js shouldComponentUpdate', this.mounted, nextState.themovies,this.state.themovies );
    if (this.mounted) return true;
    /*if (this.state.themovies === nextState.themovies) {
      return false;
    } else {
      return true;
    }*/
  }

  buildBlankMovie = index => {
    var rtime = Math.floor(Math.random() * 4) + 1;

    //console.log('buildBlankMovie index: ',index)
    return (

      <div className={((index == 1) && (this.state.themovies.length > 0)) ? "menu-item-first-loader" : "menu-item"} key={index}>
        <div
          className="blank-movie-card"
        >
          <ContentLoader
            speed={rtime}
            width={150}
            height={225}
            viewBox="0 0 150 225"
            backgroundColor="#333333"
            foregroundColor="#000000"
          >

            <rect x="0" y="0" rx="2" ry="2" width="150" height="225" />
          </ContentLoader>
        </div>
      </div>
    );
  };


  lazyLoad = () => {
    //console.log('Call lazyLoad function')
    var newTitleCount, newSearch;
    if (!this.state.loading)

      if (this.state.titleCount < MAXTITLES && (!this.state.reachedLastTitle)) {
        //console.log('LazyLoad passed conditions: ', this.props.heading, this.state.loading, this.state.reachedLastTitle)
        newTitleCount = this.state.titleCount + 10;
        this.setState({
          loading: true,
          titleCount: newTitleCount,
          //lastItemVisible: true,
          //pendingRightShift: true,
        });
        newSearch = false;
        this.SearchTitle(
          this.state.searchYear,
          this.state.searchRating,
          this.props.title,
          this.props.filterChoices,
          newTitleCount,
          true,
          newSearch,
          this.props.movieShuffleSeed,
          this.props.region
        );
      }
      else if (!this.state.lastItemVisible) {
        //console.log('lazyload set lastItemvisible: ', this.props.listType)
        this.setState({ lastItemVisible: true })
      }
  }

  render() {
    const { persistEvent, loading } = this.state;

    //console.log('List.js shuffle: ', this.props.movieShuffleSeed)
    //console.log('List.js movies: ', this.props.filterChoices)
    //console.log('List: netflix region: ', this.props.region)
    const mlen = this.state.themovies.length;
    const movieEmpty = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    var movieData = [];

    const movieFull = this.state.themovies.map((movie, index) => {
      if (movie) {
        if (index == mlen - 1) {
          //console.log('List.js reached last movie: ', movie,index)
          return (
            <div className="menu-item-last" key={movie.imdbID}>
              <WrapSingleMovie movie={movie} netflix_region={this.props.region} blockPreview={this.state.swiping} />
            </div>
          );
        } else {
          return (
            <div className="menu-item" key={movie.imdbID}>
              <WrapSingleMovie movie={movie} netflix_region={this.props.region} blockPreview={this.state.swiping} />
            </div>
          );
        }
      }
      else
        return (
          <div className="menu-item"></div>
        )
    });



    //if (this.state.themovies.length > 0)
    //console.log('List.js: movieFull: ', movieFull)
    if (!loading) {
      movieData = movieFull;
      //console.log('List.js: movieData not loading ', movieData)
    } else {
      //movieData = movieLoading;
      if (movieFull.length > 0)
        movieData = movieFull.concat(
          movieEmpty.map(index => {
            return this.buildBlankMovie(index);
          }));
      else {
        movieData = movieEmpty.map(index => {
          return this.buildBlankMovie(index);
        });
      }
      //console.log('List.js: movieData loading ', movieData)
    }




    return (
      <div >
        {isMobile ? (
          <div style={{ marginTop: '40px' }}  >
            <h4 style={{ marginLeft: '10px', color: 'black' }}>{this.props.heading}: {this.state.blockVerticalScroll}</h4>

            <ScrollMenu
              ref={el => {
                this.menu1 = el;
              }}
              data={movieData}
              wheel={false}
              dragging={true}
              alignCenter={false}
              clickWhenDrag={false}
              innerWrapperStyle={{ display: 'flex', alignItems: 'center' }}
              inertiaScrolling={true}
              inertiaScrollingSlowdown={0.7}
              transition={1}
              onLastItemVisible={this.lazyLoad}
            />

          </div>
        ) : (
          <div className="lists-block">
            <h4>{this.props.heading}</h4>

            <div className="lists-layout">
              <div className="lists-button">
                <IconButton
                  style={{ backgroundColor: '#dddddd' }}
                  aria-label="Expand"
                  onClick={this.TitlesClickLeftArrow}
                >
                  <ArrowLeftSVGF />
                </IconButton>
              </div>

              <div className="lists-scrollmenu">

                <ScrollMenu
                  ref={el => {
                    this.menu1 = el;
                  }}
                  translate={0}
                  data={movieData}
                  wheel={false}
                  dragging={false}
                  alignCenter={false}
                  innerWrapperStyle={{ display: 'flex', alignItems: 'center' }}
                  clickWhenDrag={false}
                  onLastItemVisible={this.lazyLoad}
                />

              </div>

              <div className="button">
                {!(this.state.reachedLastTitle && this.state.lastItemVisible) && (
                  <IconButton
                    style={{ backgroundColor: '#dddddd' }}
                    aria-label="Expand"
                    onClick={this.TitlesClickRightArrow}
                  >
                    <ArrowRightSVGF />
                  </IconButton>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default List;
