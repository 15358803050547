/***********************************************************************
 *
 * DESCRIPTION :
 *   Root Navigation section of code to launch :
 *     toolbar
 *     browser / search
 *     bottomnavigation
 *
 * PUBLIC FUNCTIONS :
 *      <RootNavigation/>
 * 
 * NOTES :
 *
 *
 * AUTHOR(S) :
 *   D.L. Johnson    START DATE : 1 Jan 2019
 *
 * Copyright 3Wise 2019. All rights reserved.
 ***********************************************************************/

import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuList from '@material-ui/core/MenuList';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';

import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import InfoIcon from '@material-ui/icons/Info';
import EventNote from '@material-ui/icons/EventNote'
import LockOpenIcon from '@material-ui/icons/LockOpen';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import NoteIcon from '@material-ui/icons/Note';
import HomeIcon from '@material-ui/icons/Home';
import Grid from '@material-ui/core/Grid';

import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import './PrimaryAppBar.css';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';

import { Switch, Route, Link, Redirect, withRouter, BrowserRouter } from 'react-router-dom';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import axios from 'axios';
import * as common from '../../common.js';

import WrapBrowser from '../Browser/NewBrowser';
import WrapSearch from '../Search/Search_Generic';
import MovieInfo from '../Info/MovieInfo';
//import ListofSearches from '../Search/ListOfSearches';
import ListofSearchesInfinite from '../Search/ListOfSearches_infinite';

import { RingLoader } from 'react-spinners';
import BounceLoader from 'react-spinners/BounceLoader';
import PropagateLoader from 'react-spinners/PropagateLoader';
import BarLoader from 'react-spinners/BarLoader';

import { css } from '@emotion/core';

import LoadingOverlay from 'react-loading-overlay';
import Firebase from '../../firebase/components/Firebase';
import WrapSignInPage from '../Authentication/SignIn';
import WrapProfile from '../Authentication/Profile';
import WrapInfoPlus from '../Authentication/InfoPlus';
import WrapUpgradeProfile from '../Authentication/Upgrade';


import WrapSignUpPage from '../Authentication/SignUp';
import WrapForgotPassword from '../Authentication/ForgotPassword';
import WrapChangePassword from '../Authentication/ChangePassword';

import WrapFeedback from '../Feedback';
import WrapAbout from '../About';
import WrapUserProfile from '../UserProfile';

import { connect } from 'react-redux';
import {
  fetchUserRequest,
  userLogoutRequest,
  setMovieInfo,
  closeMovieInfo,
  setRegion
} from '../../redux/actions';
import { startupRequest, screenResize } from '../../redux/actions/startup';

import Button from '@material-ui/core/Button';

import classNames from 'classnames';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';

import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';

import { isMobile } from 'react-device-detect';
import ReactGA from 'react-ga';
import WiseFooter from '../Browser/Footer';
import WiseBanner from '../Browser/Banner';

import './RootNavigation.css';

import ScrollToTop from '../../components/scrolltop';
import animate_owl from '../../assets/3wise_logo.png';
import owl_letters from '../../assets/3wise_logo_nologo.png';
import test_image from '../../assets/test.jpg';
import coin_image from '../../assets/coin.svg';

import Avatar from '@material-ui/core/Avatar';

import Paper from '@material-ui/core/Paper';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';


import WrapBlockedWordSliders from '../../components/Widgets/BlockedWordsSliders'
import isEqual from "react-fast-compare";
import animate_owl3 from '../../assets/3wise_owl_take6.gif';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import ListsOfSearchesInfinite from '../Search/ListOfSearches_infinite';


// Facebook Pixel
import ReactPixel from 'react-facebook-pixel';
const advancedMatching = { em: 'info@3wise.io' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: true, // enable logs
};




const drawerWidth = 200;
const menuWidth = 200;

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#7986CB',
      main: '#000000',
      dark: '#1A237E',
      contrastText: '#222222',
    },
    secondary: {
      light: '#999999',
      main: '#10234f',
      dark: '#1f1311',
      contrastText: '#222222',
    },
    appBar: {
      paddingTop: '5px',
      paddingBottom: '5px',
      color: 'primary',
      background: '#868686',
    },
  },
});

const override = css`
  display: block;

  position: fixed;
  top: -400px;
  border-color: red;
`;



const styles = theme => ({

  iconContainer: {
    '&:hover': {
      backgroundColor: '#F9CC33',

    }

  },


  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: 'black',
    color: 'white',
    width: '100%',
  },

  paper: {
    width: '80%',
    maxHeight: 435,
  },

  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    paddingTop: '50px',
    paddingLeft: '10px',
    cellHeight: 200,
  },

  gridListTile: {
    imgFullWidth: '100px',
    width: 150,
  },

  gridListTileBar: {
    //flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },

  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    alignItems: 'space-between',
    background: '#E0E0E0',
    boxShadow: 'none',
  },

  appHeader: {
    width: '100%',
    background: '#eeeeee',
    color: '#555555',
  },

  appStatus: {
    background: '#lightyellow',
    color: '#888888',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  appButton: {
    textTransform: 'none',
    fontWeight: 'bold',
    marginLeft: '20px',
    marginRight: '20px',

    '&:hover': {
      backgroundColor: 'rgb(254,201,0)',

    }
  },

  appButton2: {
    textTransform: 'none',
    fontWeight: 'bold',
    marginLeft: '5px',
    marginRight: '5px',
    '&:hover': {
      backgroundColor: 'rgb(254,201,0)',

    }
  },
  linkButton: {
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: 'rgb(254,201,0)',
    }
  },

  icon: {
    minWidth: '30px',
  },

  appButton3: {
    textTransform: 'none',
    fontWeight: 'bold',
    marginLeft: '5px',
    marginRight: '5px',
  },

  popup: {
    width: '400px'
  },


  grow: {
    flexGrow: 1,
  },

  menuButton: {
    marginLeft: 0,
    marginRight: 0,
  },

  hide: {
    display: 'none',
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    background: '#dddddd',
    '& *': { color: '#222222' },
  },

  menuPaper: {
    marginTop: '100px',
    minWidth: menuWidth,
    background: '#dddddd',
    '& *': { color: '#222222' },
  },

  //'& *': { color: '#eecf8f' },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },

  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },

  search: {
    position: 'relative',
    border: '2px solid #444444',
    borderRadius: '25px',
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },

    marginTop: '10px',
    //marginBottom: '20px',
    marginRight: theme.spacing.unit * 1,
    marginLeft: theme.spacing.unit * 1,
    width: '80%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      width: 400,
    },
  },

  searchIcon: {
    width: theme.spacing.unit * 4,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  inputRoot: {
    color: 'inherit',
    width: '100%',
  },

  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 4,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  avatar: {
    margin: 0,
    marginRight: 0
  },

  ShowPlusButton: {
    marginRight: '5px',
    fontWeight: 'bold',
    textTransform: 'none',
    /*'&:hover': {
      backgroundColor: 'white',
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'rgb(200,200,200)',
      },
    },*/
  },


});

class RootNavigation extends Component {
  constructor(props) {
    super(props);
    this.timeout = 0;
  }

  state = {
    anchorEl: null,
    anchorEl2: null,
    mobileMoreAnchorEl: null,
    open: false,
    showSideDrawer: false,
    loading: false,
    fetchingDB: true,
    blockedwords: [],
    Err: 0,
    searchString: '',
    searchStringOld: '',
    redirect: false,
    showSearch: true,
    region: common.region,
    movieShuffleSeed: Math.floor(Math.random() * 100),
    loaderShow: true,
    componentLoaded: false,


  };



  FetchBlockedWords = () => {

    //console.log('RootNavigation FetchBlockedwords ....')

    var data;
    this.setState({ fetchingDB: true });

    var Blasphemy = []
    var Profanity = []
    var Sexual = []
    var Racial = []

    var Blasphemy_roots = []
    var Profanity_roots = []
    var Sexual_roots = []
    var Racial_roots = []

    var result = {}

    const url = `${common.DJANGOURL}/api/blocked`
    axios.get(url)
      .then(res => {
        data = res.data
        //console.log('BlockedWordsSliders: Fetch result: ', data, data.length)

        for (var i = 0; i < data.length; i++) {
          if (data[i]['category'] == 'Profanity')
            if (!Profanity_roots.includes(data[i]['rootword'])) {
              Profanity.push([data[i]['wordstar'], data[i]['word'], data[i]['id'], data[i]['rootword']])
              Profanity_roots.push(data[i]['rootword'])
            }
          if (data[i]['category'] == 'Potential Blasphemy')
            if (!Blasphemy_roots.includes(data[i]['rootword'])) {
              Blasphemy.push([data[i]['wordstar'], data[i]['word'], data[i]['id'], data[i]['rootword']])
              Blasphemy_roots.push(data[i]['rootword'])
            }
          if (data[i]['category'] == 'Racial')
            if (!Racial_roots.includes(data[i]['rootword'])) {
              Racial.push([data[i]['wordstar'], data[i]['word'], data[i]['id'], data[i]['rootword']])
              Racial_roots.push(data[i]['rootword'])
            }
          if (data[i]['category'] == 'Sexual')
            if (!Sexual_roots.includes(data[i]['rootword'])) {
              Sexual.push([data[i]['wordstar'], data[i]['word'], data[i]['id'], data[i]['rootword']])
              Sexual_roots.push(data[i]['rootword'])
            }
        }

        result = { 'profanity': Profanity, 'blasphemy': Blasphemy, 'racial': Racial, 'sexual': Sexual }
        //console.log('Fetch Blocked Words: ', result)
        this.setState({ blockedwords: result, fetchingDB: false })

      })
      .catch(err => {
        //console.log('BlockedWordsSliders: error: ', err);
        this.setState({ blockedwords: [], fetchingDB: false });
      });
  }

  hideLoadingScreen = () => {
    this.setState({ loaderShow: false })
  }

  // Set loading time 
  LoadingTimerStart = () => {
    setTimeout(() => {
      this.hideLoadingScreen();
    }, 4000);
  }

  resize = () => {
    const width = window.innerWidth
    const height = window.innerHeight
    this.props.screenResize({ width: width, height: height })
    //console.log'Resize event: ', width, height)

  }

  componentDidMount() {

    const width = window.innerWidth
    const height = window.innerHeight
    this.props.screenResize({ width: width, height: height })

    this.LoadingTimerStart();

    //this.props.startup();
    this.FetchBlockedWords();
    this.props.fetchUser();

    // Listen for screen resizing
    window.addEventListener('resize', this.resize)

    var newSearch;
    //console.log('RootNavigation: componentdidmount', this.props.currentUser)
    //console.log('RootNavigation: Params: ', window.location.pathname)

    //Check if we started with a Search URL
    if (window.location.pathname.includes('search')) {
      newSearch = window.location.pathname.split('/')[2].trim()
      //console.log('RootNavigation: newSearch: ', newSearch)
      this.setState({ searchString: newSearch })
      this.searchRef.value = decodeURI(newSearch)
    }
    this.setState({ componentLoaded: true })
    ReactGA.initialize('UA-140786114-1');
    ReactGA.pageview('/');
    ReactPixel.init('3104067973239593', advancedMatching, options);
    ReactPixel.pageView(); // For tracking page view

  }


  handleChevronClose = () => {
    this.setState({ showSideDrawer: false, searchString: '', searchStringOld: '' });
  };

  handleDrawerClose = () => {
    this.setState({ showSearch: false });
    this.setState({ showSideDrawer: false, searchString: '', searchStringOld: '' });
    //this.refs.SearchInput = '';
    //this.searchRef.value = '';
    this.props.setMovieInfo('');
    this.props.closeMovieInfo();
  };

  handleDrawerHome = () => {
    this.setState({ showSearch: true });
    this.setState({ showSideDrawer: false, searchString: '', searchStringOld: '' });

    //this.searchRef.value = '';
    this.props.setMovieInfo('');
    this.props.closeMovieInfo();

  };

  handleDrawerOpen = () => {
    this.setState(prevState => {
      return { showSideDrawer: !prevState.showSideDrawer };
    });
  };

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };



  handleCoinMenuOpen = event => {
    this.setState({ anchorEl2: event.currentTarget });
  };

  handleSearchShow = () => {
    //console.log('RootNavigation: handleSearchShow');
    this.setState({ showSideDrawer: false, searchString: '', searchStringOld: '' });
    var searchInput = document.getElementById('SearchInput');

    if (searchInput != null)
      searchInput.value = '';

    this.setState({ showSearch: true });
  };

  closeSearch = () => {

    var searchInput = document.getElementById('SearchInput');
    if (searchInput != null)
      searchInput.value = '';
    this.setState({ searchString: '' })
  }


  handleSearchHide = () => {
    this.setState({ showSearch: false });
  };

  handleSignIn = () => {
    this.setState({ showSearch: false });
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleSignUp = () => {
    this.setState({ showSearch: false });
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleProfile = () => {
    this.setState({ showSearch: false });
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleProfileMenuClose = () => {
    this.setState({ anchorEl2: null });
    this.handleMobileMenuClose();
  };

  handleSignOut = () => {
    this.props.logoutUser();
    this.setState({ showSearch: true });
    this.handleMenuClose();
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  handleClickListItem = () => {
    this.setState({ open: true });
  };

  handleClose = value => {
    this.setState({ value, open: false });
  };

  handleFeedback = value => {
    //console.log('Got Feedback button')
  };




  renderRedirect = () => {
    //console.log('RootNavigation searchString  renderRedirect: filterChoices', this.props.currentUser.currentfilter, this.state.searchString, this.state.componentLoaded);
    if (this.state.componentLoaded) {
      if ((this.state.searchString.length > 0)) {
        var bwords_str = ''
        var bwords = this.props.currentUser.currentfilter['blockedwords']
        if (bwords.length > 0)
          bwords_str = bwords[0];
        else
          bwords_str = '0';
        for (var i = 1; i < bwords.length; i++) {
          bwords_str = bwords_str.concat(',');
          bwords_str = bwords_str.concat(bwords[i]);
        }

        var ageratings_str = ''
        var ageratings = this.props.currentUser.currentfilter['ageratings']
        if (ageratings.length > 0)
          ageratings_str = ageratings[0]
        else
          ageratings_str = '0';
        for (var i = 1; i < ageratings.length; i++) {
          ageratings_str = ageratings_str.concat(',');
          ageratings_str = ageratings_str.concat(ageratings[i]);
        }


        var filterSelect = {};



        //console.log('searchString new, old: ', this.state.searchString, this.state.searchStringOld)

        /*if (this.state.searchStringOld == '') {
          filterSelect = this.state.filterChoicesFirstSearch;
          bwords_str = '0';
          ageratings_str = '0';
          
        }
        else {
          filterSelect = this.state.filterChoices;
        }*/
        filterSelect = JSON.parse(JSON.stringify(this.props.currentUser.currentfilter))

        /* fix the search for exclusions */
        if (filterSelect['excludeBlasphemy'])
          filterSelect['blasphemy'] = '-1'
        if (filterSelect['excludeProfanity'])
          filterSelect['profanity'] = '-1'
        if (filterSelect['excludeSexual'])
          filterSelect['sexual'] = '-1'
        if (filterSelect['excludeRacial'])
          filterSelect['racial'] = '-1'
        if (filterSelect['netflix'])
          filterSelect['netflix'] = '1'
        else
          filterSelect['netflix'] = '0'
        if (filterSelect['locklanguage'])
          filterSelect['locklanguage'] = '1'
        else
          filterSelect['locklanguage'] = '0'

        var sstr = '/search/' + this.state.searchString + '/' + filterSelect['startyear'] + '/' + filterSelect['endyear']
          + '/' + filterSelect['blasphemy'] + '/' + filterSelect['profanity']
          + '/' + filterSelect['sexual'] + '/' + filterSelect['racial']
          + '/' + filterSelect['rating']
          + '/' + bwords_str
          + '/' + ageratings_str
          + '/' + filterSelect['netflix']
          + '/' + this.props.currentUser.region + '/'



        //#DJ deal with search diference between ageratings moves and TV


        //console.log('RootNavigation: sstr', sstr)
        return <Redirect to={sstr} />;
        //return <Redirect to={{pathname:'/search', query: {q: this.state.searchString}}}/>

        //var sstr='/search?q=' + this.state.searchString;
        //console.log('RootNavigation: Redirecting to Search');
        //return <Redirect to={sstr}/>
        //return <Redirect to='/search'/>
      }

      //else if (this.state.searchStringOld.length > 0   )  {
      else {
        return <Redirect to="/" />;
      }
    }

  };




  /*SearchTitle = iname => {
    this.setState({ loading: true, searchString: iname });
    var movieJoin = {};
    const urlTitle = fetch(`${common.DJANGOURL}/api/titles/?name=${iname}`);
    const urlStarring = fetch(`${common.DJANGOURL}/api/titles/?starring=${iname}`);
    //console.log('RootNavigation: urls', urlTitle, urlStarring)

    const urls = [urlTitle, urlStarring];

    Promise.all(urls)
      .then(([r1, r2]) => Promise.all([r1.json(), r2.json()]))
      .then(([data1, data2]) => {
        //console.log('SingleMovie, Setting Redux for movie data = ', data1)
        //console.log('SingleMovie, Setting Redux for Creits = ', data2)
        //console.log('SingleMovie, Setting Redux for Video = ',data3)
        //console.log('SingleMovie, Setting Redux for blocked = ', data4)
        movieJoin['titles'] = data1;
        movieJoin['starring'] = data2;
        //console.log('RootNavigation: moveJoin', movieJoin)

        this.setState({ dbResult: movieJoin, loading: false });
        this.renderRedirect();
      })
      .catch(err => {
        //console.log(err)
        this.setState({ loading: false });
      });
  }; */

  /*SearchTitle = iname => {
    
    this.setState({searchString: iname});
    var apistr = common.BASEURL + ':8000/api/titles/?name=' + iname;
    this.setState({loading:true});
    axios.get(apistr)
        .then (res => {
          this.setState({loading:false});
          this.updateMovies(res.data);
        })
        .catch(err => {
          this.setState({loading:false});
          //console.log('Search: Get Movies Error')
        });
  }; */



  doSearch = evt => {
    var searchText = evt.target.value; // this is the search text
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      //this.SearchTitle(searchText)
      this.setState({ searchStringOld: this.state.searchString });
      this.setState({ searchString: searchText });
    }, 1000);
  };

  /*updateFilters = (newFilters) => {
    //console.log('RootNavigation: updateFilter: ', newFilters)
    this.setState({ searchStringOld: this.state.searchString });
    this.setState({ filterChoices: newFilters })
  
  }*/


  renderMenu(classes, currentUser) {
    return (
      <Menu
        anchorEl={this.state.anchorEl}
        keepMounted
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(this.state.anchorEl)}
        onClose={this.handleMenuClose}
        id="signin-menu"
        classes={{
          paper: classes.menuPaper,
        }}
      >
        {currentUser.isLoggedIn ? (
          <div>
            <MenuItem onClick={this.handleMenuClose}> {currentUser.firebase.email} </MenuItem>
            <hr />
            <Link style={{ textDecoration: 'none' }} to={'/'}>
              <MenuItem className={classes.linkButton} onClick={this.handleSignOut}> Sign Out </MenuItem>
            </Link>
            <Link style={{ textDecoration: 'none' }} to={'/profile'}>
              <MenuItem className={classes.linkButton} onClick={this.handleProfile}> My profile </MenuItem>
            </Link>
          </div>
        ) : (
          <div>
            <Link style={{ textDecoration: 'none' }} to={'/signin'}>
              {' '}
              <MenuItem className={classes.linkButton} onClick={this.handleSignIn}> Sign In </MenuItem>{' '}
            </Link>
            <Link style={{ textDecoration: 'none' }} to={'/signup'}>
              {' '}
              <MenuItem className={classes.linkButton} onClick={this.handleSignUp}> Sign Up </MenuItem>{' '}
            </Link>
          </div>
        )}
      </Menu>
    );
  }

  buildFlagImage(country) {

    var flagurl = common.BASEURL + '/img/icons/Flags/' + country + '.png'

    return (
      <img
        className="flagimage"
        src={flagurl}
        alt={flagurl}
      />
    )

  }

  selectCountry = (country) => {
    //console.log('Select country: ', country);
    this.props.setRegion(country)
    //this.props.selectCountry(country)
    this.setState({ region: country })

    /*var newfilterChoices = JSON.parse(JSON.stringify(this.state.filterChoices))
    newfilterChoices['region'] = country;
    this.updateFilters(newfilterChoices);*/

  }


  renderRegion(classes, region) {

    const regionimg = [];

    var countryList = common.NetflixRegions;

    /* popupState.close */
    regionimg.push(this.buildFlagImage(region));

    return (
      <div className='region'>
        <div>
          {(!this.props.currentUser.isLoggedIn)
            ? <p ><b> Sign Up to save filter preferences and bookmark titles</b> </p> : null}
        </div>
        <div className="regionPopup">
          <PopupState variant="popover" popupId="demo-popup-menu" >
            {(popupState) => (
              <React.Fragment>
                <Button className={classes.appButton3} color="primary" {...bindTrigger(popupState)}>
                  {regionimg}
                  <span className='regionText'><b>Change Region</b></span>
                </Button>
                <Menu {...bindMenu(popupState)}>

                  {countryList.map((c, i) => {
                    return (
                      <MenuItem onClick={() => {
                        this.selectCountry(c.code);
                        popupState.close()
                      }}>

                        <div className="flagandtext" >
                          {this.buildFlagImage(c.code)}
                          <span className='regionText'><b>{c.country}</b></span>
                        </div>
                      </MenuItem>
                    )
                  })}

                </Menu>
              </React.Fragment>
            )}
          </PopupState>
        </div>
      </div>



    )

  }

  renderMenuCoin(classes, currentUser) {
    return (
      <Menu
        anchorEl={this.state.anchorEl2}
        keepMounted
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(this.state.anchorEl2)}
        onClose={this.handleCoinMenuClose}
        id="signin-menu"
        classes={{
          paper: classes.menuPaper,
        }}
      >
        <div>
          <MenuItem onClick={this.handleEarnCoins}> Earn Coins </MenuItem>
          <MenuItem onClick={this.handleCheckCoins}> Check Coin earnings </MenuItem>
        </div>
      </Menu>
    );
  }

  renderDrawer(classes, open) {
    return (
      <SwipeableDrawer
        color="black"
        className={classes.drawer}
        variant="temporary"
        anchor="right"
        open={open}
        disableSwipeToOpen
        onClose={this.handleChevronClose}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={this.handleChevronClose}>
            {theme.direction === 'ltr' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          <Link to={'/'} style={{ textDecoration: 'none' }} onClick={this.handleDrawerHome}>
            <ListItem className={classes.linkButton} button key="Home">
              <ListItemIcon style={{ minWidth: '40px' }}>
                <HomeIcon />
                <ListItemText primary={<div style={{ marginLeft: 20, marginTop: -3 }}>Home</div>} />
              </ListItemIcon>
            </ListItem>
          </Link>
          <Link to={'/about'} style={{ textDecoration: 'none' }} onClick={this.handleDrawerClose}>
            <ListItem className={classes.linkButton} button key="About">
              <ListItemIcon >
                <InfoIcon />
                <ListItemText primary={<div style={{ marginLeft: 20, marginTop: -3 }}>About</div>} />
              </ListItemIcon>
            </ListItem>
          </Link>
          <Link to={'/feedback'} style={{ textDecoration: 'none' }} onClick={this.handleDrawerClose}>
            <ListItem className={classes.linkButton} button key="Feedback">
              <ListItemIcon >
                <EventNote />
                <ListItemText primary={<div style={{ marginLeft: 20, marginTop: -3 }}>Feedback</div>} />
              </ListItemIcon>
            </ListItem>
          </Link>


          {(!this.props.currentUser.pending &&
            !this.props.currentUser.isLoggedIn) ?
            <Link to={'/signin'} style={{ textDecoration: 'none' }} onClick={this.handleDrawerClose}>
              <ListItem className={classes.linkButton} button key="SignIn">
                <ListItemIcon >
                  <LockOpenIcon />
                  <ListItemText primary={<div style={{ marginLeft: 20, marginTop: -3 }}>Sign In</div>} />
                </ListItemIcon>
              </ListItem>
            </Link> : null}

          {(!this.props.currentUser.pending &&
            !this.props.currentUser.isLoggedIn) ?
            <Link to={'/signup'} style={{ textDecoration: 'none' }} onClick={this.handleDrawerClose}>
              <ListItem className={classes.linkButton} button key="SignUp">
                <ListItemIcon >
                  <AssignmentIndIcon />
                  <ListItemText primary={<div style={{ marginLeft: 20, marginTop: -3 }}>Sign Up</div>} />
                </ListItemIcon>
              </ListItem>
            </Link> : null}

          {/*(!this.props.currentUser.pending &&
            this.props.currentUser.isLoggedIn &&
            this.props.currentUser.type == 1) ?
            <Link to={'/infoplus'} style={{ textDecoration: 'none' }} onClick={this.handleDrawerClose}>
              <ListItem className={classes.linkButton} button key="infoplus">
                <ListItemIcon >
                  <NoteIcon />
                  <PlaylistAddIcon primary={<div style={{ marginLeft: 20 , marginTop: -3 }}>Join Plus</div>} />
                </ListItemIcon>
              </ListItem>
          </Link> : null*/}

          <ListItem className={classes.linkButton}
            button
            key="Contact us"
            component="a"
            href="mailto:info@3wise.io?subject=Feedback on your 3Wise Web Application"
          >
            <ListItemIcon >
              <ContactMailIcon />
              <ListItemText
                href="mailto:info@3wise.io?subject=Feedback on your 3Wise Web Application"
                primary={<div style={{ marginLeft: 20, marginTop: -3 }}>Contact us</div>}
              />
            </ListItemIcon>
          </ListItem>
        </List>
      </SwipeableDrawer>
    );
  }

  componentWillReceiveProps(nextProps) {
    //console.log('RootNavigation: Props: ', nextProps)
    if (nextProps.currentUser.region != this.props.currentUser.region)
      this.setState({ region: nextProps.currentUser.region })
  }


  render() {
    //console.log('RootNavigation: render: ', this.state.movieShuffleSeed);

    const {
      anchorEl,
      mobileMoreAnchorEl,
      open,
      showSideDrawer,
      searchString,
      showSearch,
    } = this.state;
    const { classes } = this.props;
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    var activeSearch = false;
    //console.log('RootNavigtion, pathname: ', this.props.currentUser)
    /*//console.log(
      'RootNavigtion, user state (pending, isLoggedIn,this.props.currentUser.type ): ',
      this.props.currentUser,
      this.props.currentUser.pending,
      this.props.currentUser.isLoggedIn,
      this.props.currentUser.type,
    );*/
    //console.log'RootNavigation: photoURL: ', this.props.currentUser.firebase.photoURL, this.props.currentUser.isLoggedIn)
    //console.log('RoofNavigation screen controls: ', isMobile ,this.props.wideScreen, (isMobile || this.props.wideScreen) )

    const pathname = window.location.pathname
    //console.log('RootNavigation pathname ', pathname)

    if (this.state.searchString.length > 0) {
      //console.log('RootNavigation got search')
      activeSearch = true
    }




    return (

      <BrowserRouter >

        <Helmet>
          <title>3wise: Find and block swear words in movies and TV series</title>
          <meta name="description" content="Scan movies and TV series for swear words and bad language and use a plugin to block swearing in Netflix and Youtube" />
          <meta name="keywords" content="find swear word,filter swear word,count swear words,find blasphemy,movies,youtube" />
          <meta name="author" content="David Lloyd johnson" />
          <link rel="canonical" href="https://www.3wise.io" />
        </Helmet>
        <ScrollToTop>
          <div className={((pathname == '/' || pathname.includes('search')) && this.state.loaderShow) ? "TopLoaderShow" : "TopLoaderHide"}>
            <img
              /*style={{ position: 'fixed', top: '50%', left: '50%', marginLeft: '-50px', marginTop: '-150px', width: '300px' }}*/
              className="owlAnimate"
              src={animate_owl3}
              alt="loading..."
              loop={false}
            />
            {this.state.loaderShow ?
              <div className="TopLoadSpinner">
                <BarLoader />
              </div> : null}

          </div>

          <div className={((pathname == '/' || pathname.includes('search')) && this.state.loaderShow) ? "TheAppHide" : "TheAppShow"}>

            {this.renderRedirect()}

            <div className="topMenu">
              <MuiThemeProvider theme={theme}>
                {/* Show AppBar  <AppBar style={{ background: '#EEEEEE' }} className={classes.appBar} position="static" >*/}

                {/*<appBar className={classes.appStatus}>
                  <div className="infobar">
                    <p> This is an Alpha release of 3Wise, You may experience delays or bugs. If the site crashes or freezes - please reload the site and report your experience using the Feedback option or 'Contact Us' option  </p>
                  </div>
                </appBar>*/}

                <appBar className={classes.appHeader}>
                  {this.renderRegion(classes, this.state.region)}
                </appBar>

                <AppBar className={classes.appBar} position="static">
                  <Toolbar className={(isMobile && !this.props.wideScreen) ? 'toolbar_mobile' : 'toolbar'}>
                    <Link to={'/'} style={{ textDecoration: 'none', color: '#222222' }}>
                      {(isMobile || !this.props.wideScreen) ? (
                        <img
                          onClick={this.handleSearchShow}
                          style={{ maxWidth: '60px', maxHeight: '60px', margin: '0px' }}
                          src={owl_letters}
                          alt="loading..."
                        />
                      ) : (
                        <img
                          onClick={this.handleSearchShow}
                          style={{ margin: '0px', maxWidth: '60px', maxHeight: '60px' }}
                          src={owl_letters}
                          alt="loading..."
                        />
                      )}
                    </Link>

                    {(this.props.wideScreen) ? (
                      <div>
                        <Link to={'/'} style={{ textDecoration: 'none', color: '#222222' }}>
                          <Button onClick={this.handleSearchShow} className={classes.appButton}>
                            Home
                          </Button>
                        </Link>

                        <Link to={'/about'} style={{ textDecoration: 'none', color: '#222222' }}>
                          <Button onClick={this.handleSearchHide} className={classes.appButton}>
                            About
                          </Button>
                        </Link>

                        <Link to={'/feedback'} style={{ textDecoration: 'none', color: '#222222' }}>
                          <Button onClick={this.handleSearchHide} className={classes.appButton}>
                            Feedback
                          </Button>
                        </Link>

                        <Button
                          href="mailto:info@3wise.io?subject=Feedback on your 3Wise Web Application"
                          className={classes.appButton}
                        >
                          Contact us
                        </Button>

                        
                      </div>
                    ) : null}

                    <div className={classes.grow} />

                    {/*<IconButton
                        aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                        aria-haspopup="true"
                        aria-controls="signin-menu"
                        onClick={this.handleCoinMenuOpen}
                        color="inherit"
                       >
                        <img src={coin_image} height={30} width={30}/>
                      </IconButton>*/}


                    {/*!this.props.currentUser.pending &&
                      this.props.currentUser.isLoggedIn &&
                      this.props.currentUser.type == 1 ? (
                      <Link to={'/infoplus'} style={{ textDecoration: 'none' }}>
                        <Button
                          className={classes.ShowPlusButton}
                          onClick={this.handleSearchHide}
                          variant="contained"
                        >
                          Join Plus
                        </Button>


                      </Link>
                      ) : null*/}



                    {!this.props.currentUser.pending &&
                      !this.props.currentUser.isLoggedIn ? (
                      <Link to={'/signin'} style={{ textDecoration: 'none', color: '#222222' }}>
                        <Button
                          variant="contained"
                          onClick={this.handleSearchHide}
                          className={classes.appButton2}
                          size='small'
                        >
                          Sign in
                        </Button>
                      </Link>
                    ) : null}



                    {!this.props.currentUser.pending &&
                      !this.props.currentUser.isLoggedIn ? (
                      <Link to={'/signup'} style={{ textDecoration: 'none', color: '#222222' }} size='small'>
                        <Button
                          className={classes.appButton2}
                          onClick={this.handleSearchHide}
                          variant="contained"
                          size='small'
                        >
                          Sign Up
                        </Button>
                      </Link>
                    ) : null}

                    <div className="userIcon">
                      {
                        <IconButton
                          className={classes.iconContainer}
                          aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                          aria-haspopup="true"
                          aria-controls="signin-menu"
                          onClick={this.handleProfileMenuOpen}
                          color="inherit"
                        >

                          {this.props.currentUser.isLoggedIn ? (
                            this.props.currentUser.firebase.photoURL ? (
                              <Avatar
                                alt="X"
                                src={this.props.currentUser.firebase.photoURL}
                                className={classes.avatar}
                              />
                            ) : (
                              <AccountCircle fontSize="large" />
                            )
                          ) : (

                            <AccountCircle fontSize="large" />

                          )}

                        </IconButton>
                      }
                      {this.props.currentUser.isLoggedIn && this.props.currentUser.type == 3 ? (
                        <div className="prosymbol" >
                          Pro
                        </div>
                      ) : null}
                      {this.props.currentUser.isLoggedIn && this.props.currentUser.type == 2 ? (
                        <div className="plussymbol" >
                          Plus
                        </div>
                      ) : null}
                      {this.props.currentUser.isLoggedIn && this.props.currentUser.type == 10 ? (
                        <div className="plussymbol" >
                          Admin
                        </div>
                      ) : null}


                    </div>

                    {this.renderMenu(classes, this.props.currentUser)}
                    {this.renderMenuCoin(classes, this.props.currentUser)}

                    {(!this.props.wideScreen) ? (
                      <IconButton
                        className={classes.iconContainer}
                        color="inherit"
                        aria-label="Open drawer"
                        onClick={this.handleDrawerOpen}
                        style={{ marginRight: '-15px' }}

                      >
                        <MenuIcon fontSize="large" />
                      </IconButton>
                    ) : null}
                  </Toolbar>
                </AppBar>


                {this.renderDrawer(classes, showSideDrawer)}
              </MuiThemeProvider>
            </div>


            <div className={showSearch ? "bannerShow" : "bannerHide"}>
              <div className={showSearch ? "search-block" : "search-blockHide"}>
                <h3> Don’t ever get caught by a surprise swear word again</h3>
                <p> Find swear words in movies and TV series</p>
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    id="SearchInput"
                    ref="SearchInput"
                    inputRef={el => (this.searchRef = el)}
                    placeholder="Search a movie, TV series or actor"
                    onChange={this.doSearch}
                    fullWidth={true}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                  />
                </div>
              </div>

              <div style={{ width: '100%' }} >
                {!this.state.fetchingDB ? (
                  <WrapBlockedWordSliders blockedwords={this.state.blockedwords} activesearch={activeSearch} closeSearch={this.closeSearch} />
                ) : null}
              </div>

            </div>


            {/* FIXME Make overlay wotk with green */}
            <LoadingOverlay active={this.state.loading} spinner>
              {/*<Route path="/search"
                render = {(props) => <WrapSearch {...props} movies={this.state.dbResult} />}
                    />*/}
              {/*<Route path ="/search:search_name" 
                render = {(props) => <WrapSearch {...props} search={this.state.searchString} />}
                //path="/search/:search_name/:filter_choices_startyear/:filter_choices_endyear/:filter_choices_blasphemy/:filter_choices_profanity/:filter_choices_sexual/:filter_choices_racial/:filter_choices_rating/:filter_choices_bwords/:filter_choices_ageratings"
                //path="/search/:search_name/:filter_choices_startyear/:filter_choices_endyear/:filter_choices_blasphemy/:filter_choices_profanity/:filter_choices_sexual/:filter_choices_racial/:filter_choices_rating"  
                  />*/}
              {/*<Route exact path="/" component={WrapBrowser}/>*/}
              <Switch>
                <Route path="/about" component={WrapAbout} />

                <Route
                  path="/search/:search_name/:filter_choices_startyear/:filter_choices_endyear/:filter_choices_blasphemy/:filter_choices_profanity/:filter_choices_sexual/:filter_choices_racial/:filter_choices_rating/:filter_choices_bwords/:filter_choices_ageratings/:filter_choices_netflix/:filter_choices_region"
                  render={props => <ListsOfSearchesInfinite {...props} />}
                />
                <Route path="/movie/:movie_id" component={MovieInfo} />
                {/*<Route path="/signin" component={WrapSignInPage OnDone = {this.handleSearchShow}}/>*/}
                <Route
                  path="/signin"
                  render={props => <WrapSignInPage {...props} OnDone={this.handleSearchShow} />}
                />
                <Route
                  path="/profile"
                  render={props => <WrapProfile {...props} OnDone={this.handleSearchShow} />}
                />
                {/*<Route path="/feedback"component={WrapFeedback}/>*/}
                <Route
                  path="/signup"
                  render={props => <WrapSignUpPage {...props} OnDone={this.handleSearchShow} />}
                />

                <Route
                  path="/passwordforget"
                  render={props => <WrapForgotPassword {...props} OnDone={this.handleSearchShow} />}
                />

                <Route
                  path="/passwordchange"
                  render={props => <WrapChangePassword {...props} OnDone={this.handleSearchShow} />}
                />

                <Route
                  path="/feedback"
                  render={props => <WrapFeedback {...props} OnDone={this.handleSearchShow} />}
                />

                <Route
                  path="/userprofile"
                  component={WrapUserProfile}
                />
                <Route
                  path="/infoplus"
                  render={props => <WrapInfoPlus {...props} OnDone={this.handleSearchShow} />}
                />
                <Route
                  path="/upgradeprofile"
                  render={props => <WrapUpgradeProfile {...props} OnDone={this.handleSearchShow} />}
                />

                <Route
                  exact path="/"
                  render={props => <WrapBrowser {...props} movieShuffleSeed={this.state.movieShuffleSeed} region={this.state.region} />}
                />
                {/*<Route
                  render={() => {
                    return (
                      <Redirect to="/" />
                    )
                  }}
                />*/}


              </Switch>
              {/*<Route exact path="/" 
                render = {(props) => <WrapBrowser />}
                />*/}
            </LoadingOverlay>
            <WiseFooter />

            {/*<BottomNavigation
            showLabels
            className={classes.root}
          >  
          </BottomNavigation> */}
          </div>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

RootNavigation.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
    wideScreen: state.startup.wide
  };
}

const mapDispatchToProps = dispatch => ({
  fetchUser: () => dispatch(fetchUserRequest()),
  logoutUser: () => dispatch(userLogoutRequest()),
  startup: () => dispatch(startupRequest()),
  setMovieInfo: content => dispatch(setMovieInfo(content)),
  closeMovieInfo: () => dispatch(closeMovieInfo()),
  setRegion: region => dispatch(setRegion(region)),
  screenResize: data => dispatch(screenResize(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RootNavigation));
