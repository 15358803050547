import React, { Component } from 'react';
import { Router, browserHistory } from 'react-router';

import { connect } from 'react-redux';
import {
  userUpdatePassword
} from '../../../redux/actions';

import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import * as constants from '../constants.js'
// <SignUpLink />

const styles = theme => ({
  container: {
    margin: 'auto',
    isInvalid: true,
    marginTop: 100,
    maxWidth: 400,
    color: '#222222',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#EEEEEE',
  },

  loginError: {
    width: '340px',
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingBottom: '10px',
    paddingTop: '10px',
    fontSize: '14px',
    color: '#222222',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#ffefef',
    border: '1px solid #cc0000',
  },

  updated: {
    width: '340px',
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingBottom: '10px',
    paddingTop: '10px',
    fontSize: '14px',
    color: '#222222',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#feffc8',
    border: '1px solid #BBBBBB',
  },

  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    minWidth: 300,
  },

  button: {
    margin: theme.spacing.unit,
    textTransform: 'none',
  },

  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },


});

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pass1: '',
      pass2: '',
      message: '',
      email: '',
      goHome: false,
      cancel: false,
      open: false,
      error: '',
      errorType: '',
      update: ''
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  handleClose = () => {
    this.props.OnDone();
    this.setState({ open: false, goHome: true });
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  onFormSubmit(event) {
    event.preventDefault();
    // TODO remove depricated "this."
    //console.log('Profile: onFormsubmit')
    const email = this.state.email;
    const pass = this.state.pass2;
    this.props.userUpdatePassword({ pass });
    //this.props.history.push('/');
    //this.setState({ updated: 'Your password has been changed' })

  }

  componentWillReceiveProps(nextProps) {
    //console.log('ChangePassword: currentuser: ', nextProps.currentUser)

    if (nextProps.currentUser.error != null) {

      if (nextProps.currentUser.error.errorCode == constants.ERROR_CODE_PASSWORD_SHORT) {
        //console.log(constants.ERROR_MSG_PASSWORD_SHORT);
        this.setState({ error: constants.ERROR_MSG_PASSWORD_SHORT, errorType: constants.ERROR_CODE_PASSWORD_SHORT });
        //console.log('ChangePassword: error ');
      }
      if (nextProps.currentUser.error.errorCode == constants.ERROR_CODE_ACCOUNT_RELOGIN) {
        //console.log(constants.ERROR_MSG_ACCOUNT_RELOGIN);
        this.setState({ error: constants.ERROR_MSG_ACCOUNT_RELOGIN, errorType: constants.ERROR_CODE_ACCOUNT_RELOGIN });
      }
    }
    if (nextProps.currentUser.passwordUpdate != null) {
      if (nextProps.currentUser.passwordUpdate)
        this.setState({ updated: 'Your password has been changed' })
      else
        this.setState({ updated: '' })
    }



  }

  shouldComponentUpdate(nextProps, nextState) {

    return true;
  }

  handleClose = () => {
    this.setState({ goHome: true, cancel: true });
    this.props.OnDone();
  };

  render() {
    const { classes, ...other } = this.props;
    const { pass1, pass2, error, errorType, updated } = this.state;

    if (this.state.goHome || this.state.cancel) {
      return <Redirect to="/" />;
    }

    const isInvalid = pass1 !== pass2 || pass1 === '' || pass2 === '';

    return (
      <div>


        <form
          onSubmit={this.onFormSubmit}
          className={classes.container}
          noValidate
          autoComplete="off"
        >
          <h4>Reset your password</h4>

          {(errorType == constants.ERROR_CODE_PASSWORD_SHORT || errorType == constants.ERROR_CODE_ACCOUNT_RELOGIN) ? (
            <div className={classes.loginError}>
              <div>
                {error}
              </div>
            </div>
          ) : null}



          {updated ? (
            <div>
              <div className={classes.updated}>
                {updated}
              </div>
              <br />
            </div>

          ) : null}

          {/*{this.props.currentUser.email? (
          <p>Current user: {this.props.currentUser.email}</p>
          ):null} */}


          <TextField
            name="pass1"
            label="New Password"
            id="txtPass1"
            color="default"
            value={this.state.pass1}
            className={classes.textField}
            onChange={e => this.handleChange(e)}
            type="password"
            margin="normal"
          />
          <TextField
            name="pass2"
            label="Confirm Password"
            id="txtPass2"
            color="default"
            value={this.state.pass2}
            className={classes.textField}
            onChange={e => this.handleChange(e)}
            type="password"
            margin="normal"
          />


          <div>
            <Button
              variant="contained"
              disabled={isInvalid}
              size="medium"
              color="default"
              fullWidth={true}
              type="submit"
              className={classes.button}
              style={{ width: '200px', marginTop: '20px' }}
            >
              Reset Password
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              size="medium"
              color="default"
              fullWidth={true}
              type="submit"
              onClick={() => {
                this.handleClose();
              }}
              className={classes.button}
              style={{ width: '200px', marginBottom: '40px' }}
            >
              Cancel
            </Button>
          </div>
        </form>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {/*<InfoIcon style={{fontSize: '32px', padding: '10px'}}/>*/}
                Your password has been reset
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" variant="contained" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
  };
}

const mapDispatchToProps = dispatch => ({
  userUpdatePassword: pass => dispatch(userUpdatePassword(pass)),
});

const WrapForgotPassword = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ForgotPassword));

export default WrapForgotPassword;

