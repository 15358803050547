import React, { Component } from 'react';
import Animate from 'react-smooth';
import {
  showMovieInfo,
  setMovieID,
  setMovieSQLInfo,
  setMovieInfo,
  setBlockedInfo,
  setCreditsInfo,
  setVideoInfo,
} from '../../redux/actions/index';
import { connect } from 'react-redux';
import * as common from '../../common';
import LoadingOverlay from 'react-loading-overlay';
import { isMobile } from 'react-device-detect';
import Button from '@material-ui/core/Button';

class Header extends Component {
  constructor(props) {
    super(props);
    this.showcaseMovies = 10;
    this.showcaseTotal = 20;
    this.timeoutTime = 10000;
    this.mounted = false;
  }
  state = {
    movies: [],
    moviesSQL: [],
    i: 0,
    imdbID: 0,
    moviesReady: false,
    loading: false,
  };

  fetchMovie = ttmovieID => {
    var movieID;
    var moviesql = {};
    var moviesqlA = [];
    this.setState({ loading: true });

    // Strip tt from imdbID
    movieID = ttmovieID.match(/tt0*(.*)/)[1];

    // find matching moviesql
    moviesqlA = this.state.moviesSQL.filter(obj => {
      return obj.imdbID == movieID;
    });
    if (moviesqlA.length > 0) moviesql = moviesqlA[0];

    //console.log('Header: ttmovieID, movieID, moviesql: ', this.state.moviesSQL, ttmovieID, movieID, moviesql )

    if (ttmovieID == 0 || moviesqlA.length < 1) this.props.setMovieInfo({});
    else {
      var ttmovieID = 'tt';
      for (var j = 0; j < 7 - movieID.toString().length; j++) {
        ttmovieID = ttmovieID + '0';
      }
      ttmovieID = ttmovieID + movieID;
      //console.log("ttmovieID =", ttmovieID)

      const urlMovie = fetch(
        `https://api.themoviedb.org/3/movie/${ttmovieID}?api_key=17117ab9c18276d48d8634390c025df4&language=en-US`,
      );
      const urlCredits = fetch(`https://api.themoviedb.org/3/movie/${ttmovieID}/credits?api_key=17117ab9c18276d48d8634390c025df4
            `);
      const urlVideos = fetch(`https://api.themoviedb.org/3/movie/${ttmovieID}/videos?api_key=17117ab9c18276d48d8634390c025df4
            `);
      const urlBlocked = fetch(`${common.DJANGOURL}/api/findblocked/?type=movie&imdbid=${movieID}`);

      //console.log(urlMovie, urlCredits, urlVideos,urlBlocked)
      const urls = [urlMovie, urlCredits, urlVideos, urlBlocked];

      Promise.all(urls)
        .then(([r1, r2, r3, r4]) => Promise.all([r1.json(), r2.json(), r3.json(), r4.json()]))
        .then(([data1, data2, data3, data4]) => {
          //console.log('SingleMovie, Setting Redux for movie data = ', data1)
          //console.log('SingleMovie, Setting Redux for Creits = ', data2)
          //console.log('SingleMovie, Setting Redux for Video = ',data3)
          //console.log('SingleMovie, Setting Redux for blocked = ', data4)

          this.props.setMovieInfo(data1);
          this.props.setMovieSQLInfo(moviesql);
          this.props.setCreditsInfo(data2);
          this.props.setVideoInfo(data3.results);
          this.props.setBlockedInfo(data4);

          this.props.showMovieInfo();
          this.setState({ loading: false });
        })
        .catch(err => {
          //console.log(err)
          this.setState({ loading: false });
        });
    }
  };

  fetchMovieBrowser = movieID => {
    this.setState({ loading: true });
    // Add tt to ID
    //console.log('Header: Len', movieID.toString().length)
    var ttMovieID = 'tt';
    var zeros = 7 - movieID.toString().length;
    for (var j = 1; j <= zeros; j++) {
      ttMovieID = ttMovieID + '0';
    }
    ttMovieID = ttMovieID + movieID.toString();
    //console.log('Header: ttMovie:', ttMovieID);

    var tempMovies = [];
    //console.log("Header: fetchMovieBrowser, movieID", ttMovieID);
    const urlMovie = fetch(
      `https://api.themoviedb.org/3/movie/${ttMovieID}?api_key=17117ab9c18276d48d8634390c025df4&language=en-US`,
    )
      .then(r => r.json())
      .then(data => {
        //console.log("Header, result", data);
        tempMovies = this.state.movies;
        tempMovies.push(data);
        this.setState({ movies: tempMovies });
        if (this.state.movies.length >= 5) this.setState({ moviesReady: true });
        //console.log("Header: fetchMovieBrowser: ", this.state.movies, this.state.moviesReady)
        this.setState({ loading: false });
      })
      .catch(err => {
        //console.log(err)
        this.setState({ loading: false });
      });
  };

  componentDidMount() {
    this.mounted = true;
    var tempMovies = [];

    fetch(`${common.DJANGOURL}/api/titles/?startyear=2000&rating=4&count=${this.showcaseMovies}`)
      .then(res => res.json())
      .then(data => {
        //console.log("Header results", data.results);

        if (this.mounted) {
          /*for (var n=0; n< this.showcaseMovies; n++) {
            var rnum =  Math.floor((Math.random() * this.showcaseTotal) )
            tempMovies.push(data.results[rnum])
          } */

          //this.setState({ movies: tempMovies });
          this.setState({ moviesSQL: data });
          //console.log("Header: fetchMovieBrowser: movieSQL", this.state.moviesSQL);
          // Fetch movie data from TMDB

          data.forEach(item => {
            //console.log("Header: imdb_id: ", item.imdbID);
            this.fetchMovieBrowser(item.imdbID);
          });

          //console.log('Header: data.results: ', data.results)
          //console.log('Header: tempMovies: ', tempMovies)
        }
      })
      .catch(err => {
        var fill = null;
        //console.log(err);
      });
    this.startTimeout();
  }

  getIMDBID(movieID) {
    fetch(
      'https://api.themoviedb.org/3/movie/' +
        movieID +
        '/external_ids?api_key=17117ab9c18276d48d8634390c025df4',
    )
      .then(res => res.json())
      .then(data => {
        //console.log("Header: Got IMDBMovieID", data.imdb_id);
        this.fetchMovie(data.imdb_id);
      })
      .catch(err => {
        //console.log(err)
      });
  }

  startTimeout = () => {
    this.timeout = setTimeout(() => {
      if (this.state.i < this.showcaseMovies - 1) this.setState({ i: this.state.i + 1 });
      else this.setState({ i: 0 });
      this.startTimeout();
    }, this.timeoutTime);
  };

  componentWillUnmount() {
    this.mounted = false;
    clearTimeout(this.timeout);
  }

  GetMovies(movieID) {
    this.mounted = false;
    //console.log("Got movie click:", movieID);
    this.getIMDBID(movieID);
    /*if (movieID)
      WrapFetchMovies(movieID) */
  }

  render() {
    const { movies, moviesSQL, i } = this.state;

    const owl10 = common.BASEURL + '/img/icons/' + 'owl10.png';

    const divs = movies.length
      ? movies.map((mov, index) => {
          if (index < this.showcaseMovies) {
            return (
              <div
                key={index}
                className={i === index ? 'active' : null}
                onClick={() =>
                  this.setState({ i: index }, () => {
                    clearTimeout(this.timeout);
                    this.startTimeout();
                  })
                }
              />
            );
          } else return null;
        })
      : null;
    //console.log('Header:', this.showcaseMovies)
    const moviesList =
      movies.length == this.showcaseMovies ? (
        <div key={movies[i].id}>
          <Animate to="1" from="0.2" attributeName="opacity">
            <div
              style={{
                backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.6) 60%, rgba(0, 0, 0, 0) ), url("https://image.tmdb.org/t/p/original/${movies[i].backdrop_path}")`,
              }}
              className="bgImage"
            >
              <img className="owl10" src={owl10} />

              <div className={isMobile ? 'popularInfo_mobile' : 'popularInfo'}>
                <h4>{movies[i].title}</h4>
                <p className="rating">
                  <em>Rating: {movies[i].vote_average}</em>
                </p>
                <p className="release-date">
                  Release Date: {new Date(movies[i].release_date).toDateString()}
                </p>
                <p className="header-overview">{movies[i].overview}</p>
                <Button
                  onClick={() => this.fetchMovie(movies[i].imdb_id)}
                  color="primary"
                  variant="contained"
                >
                  Details
                </Button>
              </div>
              <div className="switchImg">{divs}</div>
            </div>
          </Animate>
        </div>
      ) : (
        <div className="bgImage"></div>
      );

    return (
      <header>
        <LoadingOverlay active={this.state.loading} spinner>
          {/*<h4 style={{marginTop: "50px", marginLeft: "40px", width: "100%", color: "#9c9c9c"}}> 3WISE TOP 10 </h4>*/}
          <div>{moviesList}</div>
        </LoadingOverlay>
      </header>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showMovieInfo: () => dispatch(showMovieInfo()),
    setMovieSQLInfo: content => dispatch(setMovieSQLInfo(content)),
    setMovieID: content => dispatch(setMovieID(content)),
    setMovieInfo: content => dispatch(setMovieInfo(content)),
    setCreditsInfo: content => dispatch(setCreditsInfo(content)),
    setVideoInfo: content => dispatch(setVideoInfo(content)),
    setBlockedInfo: content => dispatch(setBlockedInfo(content)),
  };
};

const WrapHeader = connect(null, mapDispatchToProps)(Header);
export default WrapHeader;
