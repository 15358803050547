import React, { Component } from 'react';
import { Router, browserHistory } from 'react-router';
import { Helmet } from 'react-helmet';

import { connect } from 'react-redux';
import {
  fetchUserRequest,
  userLoginRequest,
  userLoginWithProviderRequest,
} from '../../../redux/actions';
import { Redirect } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import google_image from '../../../assets/Google_G_Logo.svg';
import threewise_image from '../../../assets/3wise_logo_nologo.png';
import { Link } from 'react-router-dom';
import View from 'react';
import Text from 'react';

import * as constants from '../constants.js'

// <SignUpLink />

const styles = theme => ({
  container: {
    margin: 'auto',
    marginTop: 100,
    maxWidth: 400,
    color: '#222222',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#EEEEEE',
  },

  loginError: {
    width: '340px',
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingBottom: '10px',
    paddingTop: '10px',
    fontSize: '14px',
    color: '#222222',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#ffefef',
    border: '1px solid #cc0000',
  },

  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    minWidth: 300,
  },

  button: {
    margin: theme.spacing.unit,
    textTransform: 'none',
  },

  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});

class SignInPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      pass: '',
      goHome: false,
      cancel: false,
      goSignUp: false,
      error: '',
      errorType: ''
    };
    //this.onFormSubmit = this.onFormSubmit.bind(this);
    this.loginWithProvider = this.loginWithProvider.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    //console.log('Signin: componentWillReceiveProps: ', nextProps.currentUser)

    //console.log('Signin Page: componentWillReceiveProps ')
    //this.props.history.push('/');
    //browserHistory.push('/')
    //console.log('Signin: componentWillReceiveProps error: ', nextProps.currentUser.error)
    if (nextProps.currentUser.error) {
      //console.log('Signin: componentWillReceiveProps errorCode: ', nextProps.currentUser.error.errorCode)
      if (nextProps.currentUser.error.errorCode == constants.ERROR_CODE_USER_NOT_FOUND) {
        //console.log(constants.ERROR_MSG_USER_NOT_FOUND);
        this.setState({ error: constants.ERROR_MSG_USER_NOT_FOUND, errorType: constants.ERROR_CODE_USER_NOT_FOUND });
      }
      if (nextProps.currentUser.error.errorCode == constants.ERROR_CODE_WRONG_PASSWORD) {
        //console.log(constants.ERROR_MSG_WRONG_PASSWORD);
        this.setState({ error: constants.ERROR_MSG_WRONG_PASSWORD, errorType: constants.ERROR_CODE_WRONG_PASSWORD });
      }
    }

  }

  handleChange = event => {

    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  createAccount = () => {
    //console.log('SignIn: Create Account');
    this.setState({ goSignUp: true });
  };

  signinUserPass = () => {
    //event.preventDefault();
    // TODO remove depricated "this."
    const email = this.state.email;
    const pass = this.state.pass;
    this.props.loginUser({ email, pass });
    //this.props.history.push('/');
  }

  loginWithProvider(provider) {
    this.props.loginWithProvider(provider);

    //this.props.history.push('/');
    //browserHistory.push('/profile')
    // this.props.loginWithProvider(provider).then((data) => {
    //     if (data.payload.errorCode) {
    //         this.setState({ message: data.payload.errorMessage })
    //     } else {
    //         browserHistory.push('/profile')
    //     }
    // })
    //this.props.OnDone()
    this.setState({ goHome: true });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.currentUser.isLoggedIn) {
      this.props.OnDone();
      this.setState({ goHome: true });
    }
    //console.log('Signin: ShouldUpdate')
    return true;
  }

  handleClose = () => {
    this.setState({ goHome: true, cancel: true });
    this.props.OnDone();
  };

  render() {
    const { classes, ...other } = this.props;
    const { error } = this.state;
    //console.log ('SignIn: ', this.state.goSignUp, this.props.currentUser)

    if (this.state.goHome && (this.props.currentUser.isLoggedIn || this.state.cancel)) {
      return <Redirect to="/" />;
    }

    if (this.state.goSignUp) {
      return <Redirect to="/signup" />;
    }

    return (

      <div className={classes.container}>
        <Helmet>
          <title>SignIn</title>
          <meta name="author" content="David Lloyd johnson" />
          <meta name="robots" content="noindex"/>
          <link rel="canonical" href="https://www.3wise.io/signin" />
        </Helmet>

        <h4>Log in to 3Wise</h4>

        {error ? (
          <div className={classes.loginError}>
            <div>
              {error}
              {' '}
              <Link to={'/passwordforget'} style={{ textDecoration: 'none', color: 'blue' }}>
                Have you forgotten your password?
                </Link>
            </div>
          </div>
        ) : null}

        <TextField
          name="email"
          label="Email"
          id="txtEmail"
          color="default"
          value={this.state.email}
          className={classes.textField}
          onChange={e => this.handleChange(e)}
          margin="normal"
        />
        <TextField
          name="pass"
          label="Password"
          id="txtPass"
          color="default"
          value={this.state.pass}
          className={classes.textField}
          onChange={e => this.handleChange(e)}
          type="password"
          margin="normal"
        />

        <div
          style={{
            minWidth: '300px',
            marginmarginTop: '0px',
            fontSize: 15,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <p>
            <Link to={'/passwordforget'} style={{ textDecoration: 'none', color: 'blue' }}>
              Forgot your password?
              </Link>
          </p>
        </div>

        <div>
          <Button
            variant="contained"
            size="medium"
            color="default"
            fullWidth={true}
            onClick={this.signinUserPass}
            className={classes.button}
            style={{ width: '250px', marginTop: '20px' }}
          >
            Sign In
            </Button>
        </div>

        <div>
          <Button
            variant="contained"
            size="medium"
            color="default"
            fullWidth={true}

            onClick={() => {
              this.loginWithProvider('google');
            }}
            className={classes.button}
            style={{ width: '250px', marginBottom: '20px' }}
          >
            <div style={{ display: 'flex' }}>
              <img src={google_image} height={20} width={20} />{' '}
              <div>&nbsp; Sign In with Google</div>
            </div>
          </Button>
        </div>
        <br />

        <h4 className="lineText">
          <span>New to 3Wise?</span>
        </h4>

        <br />

        <div>
          <Button
            variant="contained"
            size="medium"
            color="default"
            fullWidth={true}
            type="submit"
            className={classes.button}
            style={{ width: '250px', marginTop: '0px' }}
            onClick={() => {
              this.createAccount();
            }}
          >
            <div style={{ display: 'flex' }}>
              <img src={threewise_image} height={20} width={30} />{' '}
              <div>&nbsp; Create free account</div>
            </div>
          </Button>
        </div>

        <div>
          <Button
            variant="contained"
            size="medium"
            color="default"
            fullWidth={true}
            type="submit"
            onClick={() => {
              this.handleClose();
            }}
            className={classes.button}
            style={{ width: '250px', marginBottom: '20px' }}
          >
            Home
            </Button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
  };
}

const mapDispatchToProps = dispatch => ({
  fetchUser: () => dispatch(fetchUserRequest()),
  loginUser: user => dispatch(userLoginRequest(user)),
  loginWithProvider: provider => dispatch(userLoginWithProviderRequest(provider)),
});

const WrapSignInPage = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SignInPage));

export default WrapSignInPage;
