import React, { Component } from 'react';
import { Router, browserHistory } from 'react-router';


import { connect } from 'react-redux';
import {
    fetchUserRequest,
    userLoginRequest,
    userLoginWithProviderRequest,
    userUpdatePassword,
    userUpdateRequest,
} from '../../../redux/actions';
import { Redirect } from 'react-router-dom';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import google_image from '../../../assets/Google_G_Logo.svg';
import threewise_image from '../../../assets/3wise_logo_nologo.png';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import View from 'react';
import Text from 'react';

import CheckIcon from '@material-ui/icons/Check';
import RemoveIcon from '@material-ui/icons/Remove';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { isMobile } from 'react-device-detect';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import ReactPlayer from 'react-player'
import Slider from "react-slick";

import * as constants from '../constants.js'
import * as common from '../../../common.js'

import './infoplus.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const StyledTableCellLight = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableCellLightHead = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableCellDark = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);


const StyledTableCellLightYellow = withStyles((theme) => ({
    root: {
        backgroundColor: 'rgb(254,207,0,0.4)',
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);


const StyledTableCellDarkYellow = withStyles((theme) => ({
    root: {
        backgroundColor: 'rgb(254,207,0,0.6)',
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableCellLightYellowHead = withStyles((theme) => ({
    head: {
        backgroundColor: 'rgb(254,207,0,0.4)',
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);


const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const settings = {

    dots: true,
    infinite: true,
    speed: 1000,
    pauseOnFocus: true,
    pauseOnHover: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    arrows: false
};


function createData(feature, basic, plus) {
    return { feature, basic, plus };
}

const rows = [
    createData(['Live mute for Netflix and Youtube', 'Live muting on your Chrome browser using the 3Wise Plus plugin for a large selection of Netflix titles and generic muting for titles not yet in the database and on Youtube'], 0, 1),
    createData(['Netflix link', 'Get filter results for Netflix in your country (62% of Netflix titles available and growing)'], 0, 1),
    createData(['Custom profiles', 'Save your favourite custom filter profiles'], 0, 1),
    createData(["Word-level filtering", "Select specific swear words you don't want to see in your browser or search results e.g. All John Cusack movies that don't use the F**k word"], 1, 1),
    createData(['Comprehensive filter', 'Browse or search Movies/TV Series using year range, maximum swear words (Blasphemy, Profanity, Sexual and Racial words) and Age Ratings'], 1, 1),
    createData(['Timeline of swear words', 'See exactly when a swear word occurs and the exact phrase used with swear word starred out'], 1, 1),
    createData(['Search 65000 titles', 'Comprehensive up to date movies and TV series titles updated every 2 days dating back to 1901'], 1, 1),
];

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});


// <SignUpLink />
const plusnetflix = common.BASEURL + '/img/icons/' + 'plus_netflix.png';
const plusfilter = common.BASEURL + '/img/icons/' + 'plus_filter.png';


const plusfilterArray = [
    common.BASEURL + '/img/large/' + 'Word_filters1.jpg',
    common.BASEURL + '/img/large/' + 'Word_filters2.jpg',
    common.BASEURL + '/img/large/' + 'Word_filters3.jpg',
    common.BASEURL + '/img/large/' + 'Word_filters4.jpg',
    common.BASEURL + '/img/large/' + 'Word_filters5.jpg'
]

const netflixArray = [
    common.BASEURL + '/img/large/' + 'Netflix1.jpg',
    common.BASEURL + '/img/large/' + 'Netflix2.jpg',
    common.BASEURL + '/img/large/' + 'Netflix3.jpg'
]
const savefilterArray = [
    common.BASEURL + '/img/large/' + 'Save_filters1.jpg',
    common.BASEURL + '/img/large/' + 'Save_filters2.jpg',
    common.BASEURL + '/img/large/' + 'Save_filters3.jpg',
    common.BASEURL + '/img/large/' + 'Save_filters4.jpg',
    common.BASEURL + '/img/large/' + 'Save_filters5.jpg',
    common.BASEURL + '/img/large/' + 'Save_filters6.jpg'
]


const styles = theme => ({
    container: {
        margin: 'auto',
        marginTop: 100,
        maxWidth: 400,
        color: '#222222',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#EEEEEE',
    },

    memberOuter: {
        margin: '10px'
    },

    memberBox: {
        width: 340,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid #CCCCCC',
        padding: '5px',
        boxShadow: '0 0 5px #BBBBBB'
    },


    loginError: {
        width: '340px',
        paddingLeft: '5px',
        paddingRight: '5px',
        paddingBottom: '10px',
        paddingTop: '10px',
        fontSize: '14px',
        color: '#222222',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#ffefef',
        border: '1px solid #cc0000',
    },

    updated: {
        width: '340px',
        paddingLeft: '5px',
        paddingRight: '5px',
        paddingBottom: '10px',
        paddingTop: '10px',
        fontSize: '14px',
        color: '#222222',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#feffc8',
        border: '1px solid #BBBBBB',
    },


    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        minWidth: 300,
    },

    button: {
        margin: theme.spacing.unit,
        textTransform: 'none',
    },

    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },

    PlusButton: {
        marginLeft: '0px',
        marginRight: '0px',
        fontSize: 15,
        backgroundColor: 'rgb(254,201,0)',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'white',
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: 'rgb(200,200,200)',
            },
        },
    },
    BasicButton: {
        textTransform: 'none',
        fontSize: 15,
        marginLeft: '0px',
        marginRight: '0px',
        '&:hover': {
            backgroundColor: 'white',
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: 'rgb(200,200,200)',
            },
        },
    },

    VideoDemo: {
        marginLeft: '20px',
        marginRight: '20px',
        fontSize: 15,

        backgroundColor: 'rgb(254,201,0)',

        textTransform: 'none',
    },
});

class InfoPlus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: this.props.currentUser.email,
            pass: '',
            goHome: false,
            goChangePassword: false,
            cancel: false,
            error: '',
            updated: '',
            joinDate: this.props.currentUser.join,
            memberPlan: this.props.currentUser.type,
            memberNews: this.props.currentUser.newsletter,
            revealPassword: false,
            currentPass: '',
            pass1: '',
            pass2: '',
            openDialogNetflix: false,
            openDialogFilter: false,
            openDialogSaveFilter: false

        };
        this.onFormSubmit = this.onFormSubmit.bind(this);

    }

    componentWillReceiveProps(nextProps) {
        //console.log('Profile, will receiveprops: all: ', nextProps.currentUser)
        if (nextProps.currentUser.isLoggedIn && !nextProps.currentUser.pending) {
            //console.log('Signin Page: componentWillReceiveProps ')
            //this.props.history.push('/');
            //browserHistory.push('/')
            //console.log('Profile, will receiveprops: ', nextProps.currentUser, this.props.currentUser);

            this.setState({
                joinDate: nextProps.currentUser.join,
                memberPlan: nextProps.currentUser.type,
                memberNews: nextProps.currentUser.newsletter,
                email: nextProps.currentUser.email
            })
            if (!this.props.currentUser.pending) {
                if ((nextProps.currentUser.joinDate != this.props.currentUser.joinDate) ||
                    (nextProps.currentUser.type != this.props.currentUser.type) ||
                    (nextProps.currentUser.newsletter != this.props.currentUser.newsletter) ||
                    (nextProps.currentUser.email != this.props.currentUser.email)) {

                    this.setState({ updated: 'Your changes have been saved' })


                }
            }


        }
        if (!nextProps.currentUser.isLoggedIn)
            this.setState({ goHome: true })


        //console.log('SignUp: ', nextProps.currentUser);
        if (nextProps.currentUser.error != null) {

            if (nextProps.currentUser.error.errorCode == constants.ERROR_CODE_ACCOUNT_RELOGIN) {
                //console.log(constants.ERROR_MSG_ACCOUNT_RELOGIN);
                this.setState({ error: constants.ERROR_MSG_ACCOUNT_RELOGIN, errorType: constants.ERROR_CODE_ACCOUNT_RELOGIN });
            }
            if (nextProps.currentUser.error.errorCode == constants.ERROR_CODE_INVALID_EMAIL) {
                //console.log(constants.ERROR_MSG_INVALID_EMAIL);
                this.setState({ error: constants.ERROR_MSG_INVALID_EMAIL, errorType: constants.ERROR_CODE_INVALID_EMAIL });
            }
            if (nextProps.currentUser.error.errorCode == constants.ERROR_CODE_ACCOUNT_EXISTS) {
                //console.log(constants.ERROR_MSG_ACCOUNT_EXISTS);
                this.setState({ error: constants.ERROR_MSG_ACCOUNT_EXISTS, errorType: constants.ERROR_CODE_ACCOUNT_EXISTS });
            }

        }



    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    changePassword = () => {
        this.setState({ goChangePassword: true })
    }

    createAccount = () => {
        //console.log('SignIn: Create Account');
        this.setState({ goSignUp: true });
    };

    onFormSubmit(event) {

        var datapack = {
            uid: this.props.currentUser.uid,
            old: {
                email: this.props.currentUser.email,
                accType: this.props.currentUser.type,
                newsletter: this.props.currentUser.newsletter
            },
            new: {
                email: this.state.email,
                accType: this.state.memberPlan,
                newsletter: this.state.memberNews
            }
        };


        //console.log('Profile datapack: ', datapack);
        this.props.updateUser(datapack);

        /*fetch(`${common.DJANGOURL}/api/users/${this.props.currentUser.uid}/`, {
          method: 'patch',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Accept: 'application/json',
          },
          body: JSON.stringify(datapack),
        })
          .then(function (response) {
            return response.json();
          })
          .catch(function (ex) {
            //console.log("Profile update failed: ", ex);
          });*/


    }



    handlenewsToggle = () => {
        //console.log('togglenews')
        this.setState({ memberNews: !this.state.memberNews })
    }

    handleOpenDialogNetflix = () => {
        this.setState({ openDialogNetflix: true })
    }

    handleCloseDialogNetflix = () => {
        this.setState({ openDialogNetflix: false })
    }
    handleOpenDialogFilter = () => {
        this.setState({ openDialogFilter: true })
    }

    handleCloseDialogFilter = () => {
        this.setState({ openDialogFilter: false })
    }
    handleOpenDialogSaveFilter = () => {
        this.setState({ openDialogSaveFilter: true })
    }

    handleCloseDialogSaveFilter = () => {
        this.setState({ openDialogSaveFilter: false })
    }

    shouldComponentUpdate(nextProps, nextState) {

        //console.log('Signin: ShouldUpdate')
        return true;

    }

    handleClose = () => {
        //console.log'InfoPlus: ', this.state.goHome)
        this.setState({ goHome: true, cancel: true });
        this.props.OnDone();
    };

    render() {
        const { classes, ...other } = this.props;
        const { error, updated } = this.state;
        //console.log('Profile: ', this.props.currentUser, this.state)
        if (this.state.goHome) {
            return <Redirect to="/" />;
        }

        return (
            <div>
                <div className={isMobile ? "netflixLinkM" : "netflixLink"}>
                    <div className={isMobile ? "plusImageM" : "plusImage"}>
                        <Slider {...settings}>
                            {netflixArray.map(item =>
                                <div>
                                    <img className={isMobile ? "plusnetflixM" : "plusnetflix"} src={item} alt={item} />
                                </div>
                            )}
                        </Slider>
                    </div>
                    <div className={isMobile ? "netflixtextM" : "netflixtext"}>
                        <p className="plusHeading"> Mute swear words in Netflix and Youtube </p>
                        <p className="plusParagraph"> Now you can finally enjoy your favourite movies and TV shows and even Youtube videos without all the filthy words. Just sign up for 3Wise Plus, install the Chrome plugin and you can watch your show with the whole family knowing you are protected</p>
                        <Button
                            className={classes.VideoDemo}
                            onClick={this.handleOpenDialogNetflix}
                            variant="contained"
                        >
                            Video Demo
                        </Button>
                    </div>
                    
                </div>
                <div className={isMobile ? "netflixLinkM" : "netflixLink"}>

                    <div className={isMobile ? "netflixtextM" : "netflixtext"}>
                        <p className="plusHeading"> Quickly find movies and TV Series that match your filters on Netflix </p>
                        <p className="plusParagraph"> Finally find those Netflix movies that are safe for your family to watch and easily launch the Netflix title directly from 3Wise when watching on a laptop, PC, phone or tablet</p>
                        <Button
                            className={classes.VideoDemo}
                            onClick={this.handleOpenDialogNetflix}
                            variant="contained"
                        >
                            Video Demo
                        </Button>
                    </div>
                    <div className={isMobile ? "plusImageM" : "plusImage"}>
                        <Slider {...settings}>
                            {netflixArray.map(item =>
                                <div>
                                    <img className={isMobile ? "plusnetflixM" : "plusnetflix"} src={item} alt={item} />
                                </div>
                            )}
                        </Slider>
                    </div>
                </div>


                {isMobile ?
                    (
                        <div className="filteringM">
                            <div className="netflixtextM">
                                <p className="plusHeading"> Fine grained filtering for browsing and search </p>
                                <p className="plusParagraph"> Select specific swear words you don't want to see while browsing for a movie or TV series to watch or when searching for a title or actor </p>
                                <Button
                                    className={classes.VideoDemo}
                                    onClick={this.handleOpenDialogFilter}
                                    variant="contained"
                                >
                                    Video Demo
                        </Button>
                            </div>
                            <div className="plusImageM">
                                <Slider {...settings}>
                                    {plusfilterArray.map(item =>
                                        <div>
                                            <img className="plusfilteringM" src={item} alt={item} />
                                        </div>
                                    )}
                                </Slider>
                            </div>
                        </div>
                    ) :
                    (
                        <div className="filtering">
                            <div className="plusImage">
                                <Slider {...settings}>
                                    {plusfilterArray.map(item =>
                                        <div>
                                            <img className="plusfiltering" src={item} alt={item} />
                                        </div>
                                    )}
                                </Slider>
                            </div>
                            <div className="netflixtext">
                                <p className="plusHeading"> Fine grained filtering for browsing and search </p>
                                <p className="plusParagraph"> Select specific swear words you don't want to see while browsing for a movie or TV series to watch or when searching for a title or actor </p>
                                <Button
                                    className={classes.VideoDemo}
                                    onClick={this.handleOpenDialogFilter}
                                    variant="contained"
                                >
                                    Video Demo
                        </Button>
                            </div>
                        </div>
                    )}


                {isMobile ?
                    (
                        <div className="profilesM" >
                            <div className="netflixtextM" >
                                <p className="plusHeading"> Save your favourite filter profiles </p>
                                <p className="plusParagraph"> Save profiles for different genre, swearing, year range, rating and age rating combinations for yourself or different family members. </p>
                                <Button
                                    className={classes.VideoDemo}
                                    onClick={this.handleOpenDialogSaveFilter}
                                    variant="contained"
                                >
                                    Video Demo
                        </Button>
                            </div>
                            <div className="plusImageM">
                                <Slider {...settings}>
                                    {savefilterArray.map(item =>
                                        <div>
                                            <img className="plusfilteringM" src={item} alt={item} />
                                        </div>
                                    )}
                                </Slider>
                            </div>
                        </div>
                    ) :
                    (
                        <div className="profiles" >
                            <div className="netflixtext" >
                                <p className="plusHeading"> Save your favourite filter profiles </p>
                                <p className="plusParagraph"> Save profiles for different genre, swearing, year range, rating and age rating combinations for yourself or different family members. </p>
                                <Button
                                    className={classes.VideoDemo}
                                    onClick={this.handleOpenDialogSaveFilter}
                                    variant="contained"
                                >
                                    Video Demo
                        </Button>
                            </div>
                            <div className="plusImage">
                                <Slider {...settings}>
                                    {savefilterArray.map(item =>
                                        <div>
                                            <img className="plusfiltering" src={item} alt={item} />
                                        </div>
                                    )}
                                </Slider>
                            </div>
                        </div>

                    )}


                <div className="compare" >
                    <div className={isMobile ? "tablecompareM" : "tablecompare"}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCellLightHead align="left" width="50%" ><span className="tabletitle">Choose the right membership for your needs</span></StyledTableCellLightHead>
                                        <StyledTableCellLightHead align="center"><b>Basic</b></StyledTableCellLightHead>
                                        <StyledTableCellLightYellowHead align="center"><b>Plus</b></StyledTableCellLightYellowHead>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row, index) => (
                                        <TableRow key={row.feature}>
                                            {index % 2 ? (
                                                <StyledTableCellLight >
                                                    <b>{row.feature[0]}</b>
                                                    <br />
                                                    {row.feature[1]}
                                                </StyledTableCellLight>
                                            ) : (
                                                    <StyledTableCellDark >
                                                        <b>{row.feature[0]}</b>
                                                        <br />
                                                        {row.feature[1]}
                                                    </StyledTableCellDark>
                                                )}
                                            {index % 2 ? (
                                                <StyledTableCellLight align="center" >{row.basic ? (<CheckIcon style={{ fontSize: 20, fontWeight: 'bold' }} />) : (<RemoveIcon style={{ fontSize: 12 }} />)}</StyledTableCellLight>
                                            ) :
                                                (
                                                    <StyledTableCellDark align="center" >{row.basic ? (<CheckIcon style={{ fontSize: 20, fontWeight: 'bold' }} />) : (<RemoveIcon style={{ fontSize: 12 }} />)}</StyledTableCellDark>
                                                )}
                                            {index % 2 ? (
                                                <StyledTableCellLightYellow align="center"  >{row.plus ? (<CheckIcon style={{ fontSize: 20, fontWeight: 'bold' }} />) : (<RemoveIcon style={{ fontSize: 12 }} />)}</StyledTableCellLightYellow>
                                            ) : (
                                                    <StyledTableCellDarkYellow align="center"  >{row.plus ? (<CheckIcon style={{ fontSize: 20, fontWeight: 'bold' }} />) : (<RemoveIcon style={{ fontSize: 12 }} />)}</StyledTableCellDarkYellow>
                                                )}
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <StyledTableCellLight></StyledTableCellLight>
                                        <StyledTableCellLight align="center">

                                            <Button
                                                className={classes.BasicButton}
                                                variant="contained"
                                                onClick={this.handleClose}

                                            >
                                                Stay on Basic
                                            </Button>


                                        </StyledTableCellLight>
                                        <StyledTableCellLight align="center">
                                            <Link to={'/upgradeprofile'} style={{ textDecoration: 'none', color: '#222222' }}>
                                                <Button
                                                    className={classes.PlusButton}

                                                    variant="contained"
                                                >
                                                    Join 3Wise Plus
                                            </Button>
                                            </Link>
                                        </StyledTableCellLight>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>




                <Dialog
                    open={this.state.openDialogNetflix}
                    onClose={this.handleCloseDialogNetflix}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="md"
                    fullScreen={true}
                >
                    <DialogContent>
                        <ReactPlayer
                            url={common.BASEURL + '/videos/NETFLIX_demo.mp4'}
                            width='100%'
                            height='100%'
                            playing
                            controls
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleCloseDialogNetflix}
                            style={{ margin: '10px', textTransform: 'none' }}
                            variant="contained"
                            autoFocus
                        >
                            OK
            </Button>

                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.openDialogFilter}
                    onClose={this.handleCloseDialogFilter}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="md"
                    fullScreen={true}
                >
                    <DialogContent>
                        <ReactPlayer
                            url={common.BASEURL + '/videos/FILTER_demo.mp4'}
                            width='100%'
                            height='100%'
                            playing
                            controls
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleCloseDialogFilter}
                            style={{ margin: '10px', textTransform: 'none' }}
                            variant="contained"
                            autoFocus
                        >
                            OK
            </Button>

                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.openDialogSaveFilter}
                    onClose={this.handleCloseDialogSaveFilter}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="md"
                    fullScreen={true}
                >
                    <DialogContent>
                        <ReactPlayer
                            url={common.BASEURL + '/videos/SAVEFILTER_demo.mp4'}
                            width='100%'
                            height='100%'
                            playing
                            controls
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleCloseDialogSaveFilter}
                            style={{ margin: '10px', textTransform: 'none' }}
                            variant="contained"
                            autoFocus
                        >
                            OK
            </Button>

                    </DialogActions>
                </Dialog>





            </div>


        );
    }
}

function mapStateToProps(state) {
    return {
        currentUser: state.currentUser,
    };
}

const mapDispatchToProps = dispatch => ({
    fetchUser: () => dispatch(fetchUserRequest()),
    updateUser: (userdata) => dispatch(userUpdateRequest(userdata)),
    loginUser: user => dispatch(userLoginRequest(user)),
    loginWithProvider: provider => dispatch(userLoginWithProviderRequest(provider)),
});

const WrapInfoPlus = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(InfoPlus));

export default WrapInfoPlus;