import React from 'react';
import Button from '@material-ui/core/Button';
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll,
  scrollSpy,
  scroll,
  scroller,
} from 'react-scroll';
import TextField from '@material-ui/core/TextField';

import PlayIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import ResetIcon from '@material-ui/icons/Replay';
import UpIcon from '@material-ui/icons/ArrowDropUp';
import DownIcon from '@material-ui/icons/ArrowDropDown';
import IconButton from '@material-ui/core/IconButton';

import beep_warn from '../../assets/beep.mp3';
import { isMobile } from 'react-device-detect';

import * as common from '../../common.js';

import { connect } from 'react-redux';
import MediaIcon from '@material-ui/icons/VolumeOff';
import isEqual from "react-fast-compare";

import './StopWatch.css';

class BlockedDisplay extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    timerOn: false,
    timerStart: 0,
    timerTime: 0,
    stretchTime: 0,
    FStretchTime: 1.052939,

    words: [],

    timeList: [],
    timeSubtitles: [],
    timeSubtitlesStack: [],

    timeNextMute: 0,
    timeCurrentMute: 10000000,
    currentMute: {},
    SubtitlePos: 400,

    timeNextSubtitle: 0,
    timeDelay: 0,

    timerDiff: 0,
    NextLine: 0,
    lineNextMute: '',
    muted: false,
    continueMute: false,
    reachedEnd: false,
  };

  /*componentDidMount = () => {
    Events.scrollEvent.register('begin', function(to, element) {
      //console.log("begin", arguments);
    });

    Events.scrollEvent.register('end', function(to, element) {
      //console.log("end", arguments);
    });

    scrollSpy.update();
  };*/

  sendMute = () => {
    //console.log('StopWatch: Attempting Mute')

    var muteURL = common.mutetv;
    fetch(muteURL)
      .then(response => {
        // Ensure service worker exists, and that we really are getting a JS file.
        const contentType = response.headers.get('content-type');
        if (
          response.status === 404 ||
          (contentType != null && contentType.indexOf('javascript') === -1)
        ) {
          //console.log ('StopWatch: Send MUTE comand to TV: 404 Error')
        } else {
          // Service worker found. Proceed as normal.
          //console.log ('StopWatch: Send MUTE command to TV: SUCCESS')
        }
      })
      .catch(() => {
        //console.log('Send MUTE comand to TV: No connecton');
      });
  };

  testTVMute = () => {
  
    //console.log('StopWatch: Attempting TV Mute: ', common.mutetv)
    var muteURL = common.mutetv;
    setTimeout(function() {
      fetch(muteURL);
    }, 100);
  
  };
  
  sendTVMute = () => {
    if (this.props.tvOn) {
      //console.log('StopWatch: Attempting TV Mute: ', common.mutetv)
      var muteURL = common.mutetv;
      setTimeout(function() {
        fetch(muteURL);
      }, 100);
    }
  };

  sendRokuPlay = () => {
    //console.log('StopWatch: Attempting TV Mute')
    var playURL = common.playtv;
    fetch(playURL);
  };

  sendTVKeepAlive = () => {
    if (this.props.tvOn) {
      //console.log('StopWatch: Attempting TV Mute')
      var aliveURL = common.keepalive;
      fetch(aliveURL);
    }
  };

  sendTVUnMute = () => {
    if (this.props.tvOn) {
      //console.log('StopWatch: Attempting TV Mute')
      var muteURL = common.mutetv;
      setTimeout(function() {
        fetch(muteURL);
      }, 100);
    }
  };

  sendPCMute = () => {
    //console.log('StopWatch: Attempting PC Mute')
    var muteURL = common.mutepc
    fetch(muteURL);
  };

  sendPCUnMute = () => {
    //console.log('StopWatch: Attempting PC UnMute')
    var unmuteURL = common.unmutepc;
    fetch(unmuteURL);
  };

  TVMute = () => {
    if (this.props.tvOn) {
      if (!this.state.muted) {
        this.sendTVMute();
        this.sendPCMute();
        this.setState({ muted: true });
      } else {
        this.sendTVUnMute();
        this.sendPCUnMute();
        this.setState({ muted: false });
      }
    }
  };

  ShowSubtitles = event => {
    //console.log('StopWatch: Show Subtitles')
    this.props.ShowSubs();
  };

  AdjustTime = pos => {
    var diff, newTimerStart;
    diff = this.state.timerTime - pos;
    /// #DJ CHECK
    //newTimerStart = this.state.timerStart + diff - 500;
    newTimerStart = this.state.timerStart + diff;
    this.setState({ timerStart: newTimerStart });
    //console.log('StopWatch, Got adjusted time:', pos, this.state.timerTime, this.state.timerStart )
  };

  ShiftTime = stime => {
    var newTimerStart = this.state.timerStart + stime;
    this.setState({ timerStart: newTimerStart });
    //console.log('StopWatch, Received Shift time', stime )
  };

  StretchTime = ptime => {
    var newStretchDelta = ptime;
    var newFStretchTime =
      (this.state.timerTime * this.state.FStretchTime + newStretchDelta) / this.state.timerTime;
    this.setState({ stretchTime: newStretchDelta, FStretchTime: newFStretchTime });
    //console.log('StopWatch, Received Stretch time: ', this.state.timerTime*this.state.FStretchTime, newStretchDelta, newFStretchTime )
  };

  Upms = () => {
    var newTimerStart = this.state.timerStart - 200;
    this.setState({ timerStart: newTimerStart });
    //console.log('StopWatch, Adjust time up ms' )
  };

  Downms = () => {
    var newTimerStart = this.state.timerStart + 200;
    this.setState({ timerStart: newTimerStart });
    //console.log('StopWatch, Adjust time down ms' )
  };

  UpSecs = () => {
    var newTimerStart = this.state.timerStart - 1000;
    this.setState({ timerStart: newTimerStart });
    //console.log('StopWatch, Adjust time up sec' )
  };

  DownSecs = () => {
    var newTimerStart = this.state.timerStart + 1000;
    this.setState({ timerStart: newTimerStart });
    //console.log('StopWatch, Adjust time down sec' )
  };

  handleChangeDelay = name => event => {
    this.setState({ timeDelay: Number(event.target.value) });
  };

  SetDelay = () => {
    this.setState({ timerDiff: this.state.timeDelay });
  };

  startTimer = () => {
    this.setState({
      timerOn: true,
      timerTime: this.state.timerTime,
      timerStart: Date.now() - Math.round(this.state.timerTime / this.state.FStretchTime),
    });
    //console.log ('BlockedDisplay: StartTime after: ', this.state.timerTime, Date.now(),this.state.timerStart)

    this.timer = setInterval(() => {
      //console.log ('StopWatch: Timer', this.state.timeList, this.props.TBefore, this.props.TAfter, this.state.timerTime, this.state.muted, this.state.reachedEnd )
      // Check if we have reached mute point
      if (!this.state.reachedEnd) {
        // Update timeline
        /*if ((this.state.timerTime % 2000) < 100) {
          //console.log('Category Lines: Update timeline', Math.round(this.state.timerTime/1000))
          this.props.timelineChange(Math.round(this.state.timerTime/1000))
        }*/

        if (
          !this.state.muted &&
          this.state.timerTime > this.state.timeNextMute - 5000 &&
          this.state.timerTime <= this.state.timeNextMute - 4800
        ) {
          this.PlayBeep();
        }

        /* First check for mute points */
        if (
          !this.state.muted &&
          this.state.timerTime >= this.state.timeNextMute - this.props.TBefore
        ) {
          //this.sendPCMute();
          //console.log('StopWatch, Inside MUTE IF')

          // Check case where an adjustment has been made and we need to skip to next mute point
          while (
            !this.state.reachedEnd &&
            this.state.timerTime >= this.state.timeNextMute - this.props.TBefore
          ) {
            var times = this.state.timeList;
            this.setState({ timeCurrentMute: this.state.timeNextMute });
            if (times.length != 0) {
              var nmute = times.shift();
              //console.log('StopWatch,  MUTE SHIFT')
              this.setState({
                timeList: times,
                timeNextMute: nmute['starttime'],
                currentMute: nmute,
              });
            } else {
              this.setState({ timeNextMute: 1000000000 });
              this.setState({ reachedEnd: true });
            }
          }

          if (this.state.timerTime < this.state.timeCurrentMute + this.props.TAfter) {
            this.setState({ muted: true });
            //console.log('StopWatch,  MUTE TV NOW')
            if (!this.state.continueMute) this.sendTVMute();
            this.sendPCMute();
          } else {
            if (this.state.reachedEnd) this.setState({ lineNextMute: 'No more mute phrases ... ' });
            else this.setState({ lineNextMute: nmute['line'] });
          }
        }

        // Check case where next mute point is inside mute period
        if (this.state.timeList.length > 0) {
          if (this.state.timerTime > this.state.timeList[0]['starttime'] - this.props.TBefore) {
            var times = this.state.timeList;
            this.setState({ timeCurrentMute: this.state.timeNextMute });
            if (times.length != 0) {
              var nmute = times.shift();
              this.setState({
                timeList: times,
                timeNextMute: nmute['starttime'],
                lineNextMute: nmute['line'],
                currentMute: nmute,
              });
            } else {
              this.setState({ timeNextMute: 1000000000 });
              this.setState({ reachedEnd: true });
            }
          }
        }

        /*if (this.state.timerTime > this.state.timeNextMute) {
        var times = this.state.timeList
        var nmute = times.shift();
        this.setState({timeList: times, timeNextMute: nmute['starttime'], lineNextMute: nmute['line']})
       } */
      }

      if (
        this.state.muted &&
        this.state.timerTime >= this.state.timeCurrentMute + this.props.TAfter
      ) {
        //console.log('StopWatch, reached UNMUTE')
        this.setState({ timeCurrentMute: 1000000000 });
        if (this.state.reachedEnd) this.setState({ lineNextMute: 'No more mute phrases ... ' });
        else this.setState({ lineNextMute: this.state.currentMute['line'] });

        this.setState({ muted: false });

        /* Check if next mute is < 1 sec away */
        if (this.state.timerTime + 1000 > this.state.timeNextMute - this.props.TBefore) {
          this.setState({ continueMute: true });
        } else {
          this.sendTVUnMute();
          this.sendPCUnMute();
          this.setState({ continueMute: false });
        }
      }

      /* Check Subtitle points */
      if (this.state.timeSubtitlesStack.length > 0) {
        //console.log('StopWatch Subtitles diff: ', Math.abs(this.state.timerTime -this.state.timeNextSubtitle) )
        if (this.state.timerTime > this.state.timeNextSubtitle) {
          while (
            this.state.timerTime > this.state.timeNextSubtitle &&
            this.state.timeSubtitlesStack.length > 0
          ) {
            var nsubtitleT = this.state.timeSubtitlesStack.shift();
            //console.log('BUG: StopWatch, subtitle line : ',nsubtitleT)
            this.setState({
              timeNextSubtitle: nsubtitleT['starttime'],
              SubtitlePos: this.state.SubtitlePos + 1,
            });
            //console.log('StopWatch, time Subtitle, Pos', this.state.timeNextSubtitle, this.state.SubtitlePos)
            this.props.lineChange(this.state.SubtitlePos);
          }
        }
      }
      //FIXME This was causing movie timer not to start
      //else {
      //  //console.log('BUG: StopTimer timeSubtitlesStack : ',this.state.timeSubtitlesStack)
      //  this.stopTimer()
      //}

      // Send periodic keep alive to TV

      if (this.state.timerTime % 5000 < 200) {
        //console.log('BUG: Keep alive')
        this.sendTVKeepAlive();
      }

      var oldtime = Date.now() - this.state.timerStart + this.state.timerDiff;
      var newtime = oldtime * this.state.FStretchTime;
      //console.log('oldtimer: ', oldtime)
      //console.log('newtimer: ', newtime )
      //console.log('FStretchTime: ', this.state.FStretchTime )

      this.setState({
        timerTime: newtime,
      });
    }, 200);

    // If you click play then store this as a movie you watched
    this.props.PlayPressed();
  };

  stopTimer = () => {
    this.setState({ timerOn: false });
    clearInterval(this.timer);
  };

  resetTimer = () => {
    this.setState({
      timerStart: 0,
      timerTime: 0,
      stretchTime: 0,
      FStretchTime: 1.0379841,
    });
  };

  dynamicSort = property => {
    var sortOrder = 1;

    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }

    //console.log('StopWatch: dyanmicSort', property)
    return function(a, b) {
      if (sortOrder == -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    };
  };

  ExtractTimeStampsBlocked = words_all => {
    var times = [];
    var hrs, min, sec, msec, start_totmsec, end_totmsec;
    var word_c, tdict;

    Object.keys(words_all).map(word_c =>
      Object.keys(words_all[word_c]).map(key => {
        if (! (key == 'count' && key =='exclude') )
          Object.keys(words_all[word_c][key])
            .filter(item => item !== 'count')
            .filter(item => item !== 'exclude')
            .map(key2 =>
              words_all[word_c][key][key2].map(t => {
                hrs = Number(t['time'].slice(0, 2));
                min = Number(t['time'].slice(3, 5));
                sec = Number(t['time'].slice(6, 8));
                msec = Number(t['time'].slice(9, 12));

                start_totmsec = 1000 * (hrs * 3600 + min * 60 + sec) + msec;

                hrs = Number(t['time'].slice(17, 19));
                min = Number(t['time'].slice(20, 22));
                sec = Number(t['time'].slice(23, 25));
                msec = Number(t['time'].slice(26, 29));

                end_totmsec = 1000 * (hrs * 3600 + min * 60 + sec) + msec;

                tdict = { starttime: start_totmsec, endtime: end_totmsec, line: t['line'] };
                times.push(tdict);
              }),
            );
      }),
    );
    //console.log('StopWatch: Time Stamps', times)

    times.sort((a, b) => (a.starttime > b.starttime ? 1 : -1));
    //console.log('StopWatch: Time Stamps', times)

    if (times.length > 0) {
      var nmute = times.shift();
      this.setState({ timeNextMute: nmute['starttime'], lineNextMute: nmute['line'] });
      //console.log('StopWatch: NMute', nmute['starttime'], nmute['line'])
    } else {
      this.setState({ timeNextMute: 0, lineNextMute: 'No more mute phrases ... ' });
    }

    return times;
  };

  ExtractTimeStampsSubtitles = words_all => {
    var times = [];
    var hrs, min, sec, msec, start_totmsec, end_totmsec;
    var word_c, tdict;

    words_all.map(line => {
      if (line['time'].length > 10) {
        hrs = Number(line['time'].slice(0, 2));
        min = Number(line['time'].slice(3, 5));
        sec = Number(line['time'].slice(6, 8));
        msec = Number(line['time'].slice(9, 12));

        start_totmsec = 1000 * (hrs * 3600 + min * 60 + sec) + msec;

        hrs = Number(line['time'].slice(17, 19));
        min = Number(line['time'].slice(20, 22));
        sec = Number(line['time'].slice(23, 25));
        msec = Number(line['time'].slice(26, 29));

        end_totmsec = 1000 * (hrs * 3600 + min * 60 + sec) + msec;

        tdict = { starttime: start_totmsec, endtime: end_totmsec, line: line['line'] };
        times.push(tdict);
      }
    });

    return times;
  };

  PlayBeep = () => {
    if (this.props.audioOn) {
      //console.log('BEEP played')
      this.beep.play();
    }
  };

  CompareDict = (obj1, obj2) => {
    //Loop through properties in object 1
    for (var p in obj1) {
      //Check property exists on both objects
      if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;

      switch (typeof obj1[p]) {
        //Deep compare objects
        case 'object':
          if (!this.CompareDict(obj1[p], obj2[p])) return false;
          break;
        //Compare function code
        case 'function':
          if (
            typeof obj2[p] == 'undefined' ||
            (p != 'compare' && obj1[p].toString() != obj2[p].toString())
          )
            return false;
          break;
        //Compare values
        default:
          if (obj1[p] != obj2[p]) return false;
      }
    }

    //Check object 2 for any extra properties
    for (var p in obj2) {
      if (typeof obj1[p] == 'undefined') return false;
    }
    return true;
  };

  componentWillReceiveProps(nextProps, nextState) {
    var TList = [];
    //console.log('BlockDisplay componentWillReceiveProps')

    {
      /*}
    if  (nextProps.pos == this.props.pos) {
      //console.log('StopWatch, nextProps.words: ', nextProps.words)
      TList = this.ExtractTimeStampsBlocked(nextProps.words);
      this.setState({timeList:TList})
    } */
    }

    //if (!this.CompareDict(nextProps.words,this.state.words)) {
    if (nextProps.words) {
      if (JSON.stringify(nextProps.words) != JSON.stringify(this.state.words)) {
        if (!nextProps.subset) {
          //console.log('StopWatch REsetting all values')
          this.setState({
            timerTime: 0,
            timeList: [],
            timeSubtitles: [],
            timeSubtitlesStack: [],

            words: [],

            timeNextMute: 0,
            timeCurrentMute: 10000000,
            currentMute: {},
            SubtitlePos: 0,

            timeNextSubtitle: 200,
            timeDelay: 0,

            timerDelay: 0,
            NextLine: 0,
            lineNextMute: '',
            muted: false,
            reachedEnd: false,
          });
        }

        /* Deep COPY */
        //console.log('StopWatch received blocked words new: ', nextProps.words)
        //console.log('StopWatch received blocked words old:', this.state.words)
        this.setState({ words: JSON.parse(JSON.stringify(nextProps.words)) });

        //console.log'StopWatch, nextProps.words: ', nextProps.words)
        TList = this.ExtractTimeStampsBlocked(nextProps.words);
        this.setState({ timeList: TList });
      }

      if (
        this.state.timeSubtitles != nextProps.subtitles_times &&
        nextProps.subtitles_times.length > 0
      ) {
        //console.log('BUG: StopWatch received  subtitles stack', nextProps.subtitles_times)

        var nsubtitle = nextProps.subtitles_times.shift();
        //console.log('StopWatch received line: ',nsubtitle)
        var stime = nsubtitle['starttime'];


        this.setState({
          timeSubtitlesStack: [...nextProps.subtitles_times],
          timeSubtitles: nextProps.subtitles_times,
          timeNextSubtitle: stime,
          
          SubtitlePos: 0,
        });
        //console.log('StopWatch received subtitles, start time ', nextProps.subtitles_times, stime)
      }

      
        /*if (nextProps.words != this.props.words) {

        this.setState({timeSubtitlesStack: [...nextProps.subtitles_times] })
        if (this.state.timeSubtitlesStack.length > 0) {
          var nsubtitle= this.state.timeSubtitlesStack.shift();
          this.setState({timeNextSubtitle:  nsubtitle['starttime'], SubtitlePos:0})
          //console.log('StopWatch received subtitles', this.state.timeNextSubtitle, this.state.timeSubtitlesStack )
        }
      }*/
      

      if (this.props.pos != nextProps.pos) {
        this.AdjustTime(nextProps.pos);
        //console.log('StopWatch received position: ', nextProps.pos, this.props.pos)
      }

      if (this.props.timeShift != nextProps.timeShift) {
        this.ShiftTime(nextProps.timeShift - this.props.timeShift);
        //console.log('StopWatch Received new TimeShift: ', nextProps.timeShift-this.props.timeShift)
      }

      if (this.props.timeStretch != nextProps.timeStretch) {
        this.StretchTime(nextProps.timeStretch - this.props.timeStretch);
        //console.log('StopWatch Received new TimeStretch: ', nextProps.timeStretch)
      }

      if (this.state.FStretchTime != nextProps.timeFactor) {
        this.setState({ FStretchTime: nextProps.timeFactor });
        //console.log('StopWatch Received new FStretchTime: ', nextProps.timeFactor)
      }
    }
  }

  componentDidMount = () => {
    var TList = [];
    //console.log('BlockDisplay componentDidMount: ', this.props )

    Events.scrollEvent.register('begin', function(to, element) {
      //console.log("begin", arguments);
    });

    Events.scrollEvent.register('end', function(to, element) {
      //console.log("end", arguments);
    });

    scrollSpy.update();

    {
      /*}
    if  (nextProps.pos == this.props.pos) {
      //console.log('StopWatch, nextProps.words: ', nextProps.words)
      TList = this.ExtractTimeStampsBlocked(nextProps.words);
      this.setState({timeList:TList})
    } */
    }

    //if (!this.CompareDict(nextProps.words,this.state.words)) {
    if (this.props.words) {
      if (JSON.stringify(this.props.words) != JSON.stringify(this.state.words)) {
        if (!this.props.subset) {
          //console.log('StopWatch REsetting all values')
          this.setState({
            timerTime: 0,
            timeList: [],
            timeSubtitles: [],
            timeSubtitlesStack: [],

            words: [],

            timeNextMute: 0,
            timeCurrentMute: 10000000,
            currentMute: {},
            SubtitlePos: 0,

            timeNextSubtitle: 200,
            timeDelay: 0,

            timerDelay: 0,
            NextLine: 0,
            lineNextMute: '',
            muted: false,
            reachedEnd: false,
          });
        }

        /* Deep COPY */
        //console.log('StopWatch received blocked words new: ', nextProps.words)
        //console.log('StopWatch received blocked words old:', this.state.words)
        this.setState({ words: JSON.parse(JSON.stringify(this.props.words)) });

        //console.log'StopWatch, nextProps.words: ', nextProps.words)
        TList = this.ExtractTimeStampsBlocked(this.props.words);
        this.setState({ timeList: TList });
      }

      if (
        this.state.timeSubtitles != this.props.subtitles_times &&
        this.props.subtitles_times.length > 0
      ) {
        //console.log('BUG: StopWatch received  subtitles stack', nextProps.subtitles_times)

        var nsubtitle = this.props.subtitles_times.shift();
        //console.log('StopWatch received line: ',nsubtitle)
        var stime = nsubtitle['starttime'];


        this.setState({
          timeSubtitlesStack: [...this.props.subtitles_times],
          timeSubtitles: this.props.subtitles_times,
          timeNextSubtitle: stime,
          
          SubtitlePos: 0,
        });
        //console.log('StopWatch received subtitles, start time ', nextProps.subtitles_times, stime)
      }

      {
        /*if (nextProps.words != this.props.words) {

        this.setState({timeSubtitlesStack: [...nextProps.subtitles_times] })
        if (this.state.timeSubtitlesStack.length > 0) {
          var nsubtitle= this.state.timeSubtitlesStack.shift();
          this.setState({timeNextSubtitle:  nsubtitle['starttime'], SubtitlePos:0})
          //console.log('StopWatch received subtitles', this.state.timeNextSubtitle, this.state.timeSubtitlesStack )
        }
      }*/
      }

    
    }
  }

  render() {
    const { timerTime, timeSubtitles } = this.state;
    const { words, TBefore, TAfter, subtitles } = this.props;

    let centiseconds = ('0' + (Math.floor(timerTime / 100) % 10) + '0').slice(-2);
    let seconds = ('0' + (Math.floor(timerTime / 1000) % 60)).slice(-2);
    let minutes = ('0' + (Math.floor(timerTime / 60000) % 60)).slice(-2);
    let hours = ('0' + Math.floor(timerTime / 3600000)).slice(-2);

    //console.log('BlockedDisplay: props: ',this.props)

    return (
      <div className="Stopwatch-container">
        <audio
          ref={beep => {
            this.beep = beep;
          }}
        >
          <source src={beep_warn} type="audio/mpeg"></source>
        </audio>

        {/*<div className="Delay-display">
      <div className="Delay-text">
      <TextField
        id="delay"
        label="Delay"
        onChange={this.handleChangeDelay('delay')}
        value={this.state.timeDelay}
        className="textField"
        margin="normal"
      />
      </div>

        <button className="buttonDelay" onClick={this.SetDelay}>Set Delay</button>

        </div> */}

        <div
          className={
            this.state.muted || this.state.continueMute
              ? 'Stopwatch-nextword-muted'
              : 'Stopwatch-nextword'
          }
        >
          <div className="Stopwatch-display">
            <div className="Stopwatch-timer">
              <div className={isMobile ? 'Stopwatch-numbers-mobile' : 'Stopwatch-numbers'}>
                {hours}
              </div>
              <div className={isMobile ? 'Stopwatch-numbers-mobile' : 'Stopwatch-numbers'}>:</div>
              <div className={isMobile ? 'Stopwatch-numbers-mobile' : 'Stopwatch-numbers'}>
                {minutes}
              </div>
              <div className={isMobile ? 'Stopwatch-numbers-mobile' : 'Stopwatch-numbers'}>:</div>
              <div className={isMobile ? 'Stopwatch-numbers-mobile' : 'Stopwatch-numbers'}>
                {/*<IconButton  aria-label="Expand" onClick={this.UpSecs}>
                <UpIcon   className="Arrows"   style={{ fontSize: 50, margin: -30 }} />
              </IconButton>*/}
                {seconds}
                {/*<IconButton   aria-label="Expand" onClick={this.DownSecs}>
                <DownIcon  className="Arrows" style={{ fontSize: 50, margin: -30 }} />
            </IconButton>*/}
              </div>
              {/*<div className="Stopwatch-numbers"> 
            : 
            </div>
            <div className="Stopwatch-numbers"> 
            <IconButton  aria-label="Expand" onClick={this.Upms}>
                  <UpIcon   className="Arrows"  style={{ fontSize: 50, margin: -30 }}   />
              </IconButton>
            {centiseconds} 
            <IconButton   aria-label="Expand" onClick={this.Downms}>
                <DownIcon   className="Arrows" style={{ fontSize: 50, margin: -30 }} />
              </IconButton>
            </div>*/}
            </div>

            <div className="Stopwatch-buttons">
              {this.state.timerOn === false && (
                <IconButton
                  aria-label="Expand"
                  onMouseDown={this.sendRokuPlay}
                  onClick={this.startTimer}
                >
                  <PlayIcon marginLeft="10px" style={{ fontSize: 20 }} />
                </IconButton>
              )}
              {this.state.timerOn === true && (
                <IconButton
                  aria-label="Expand"
                  onMouseDown={this.sendRokuPlay}
                  onClick={this.stopTimer}
                >
                  <PauseIcon marginLeft="10px" style={{ fontSize: 20 }} />
                </IconButton>
              )}

              {this.state.timerOn === false && this.state.timerTime > 0 && (
                <IconButton aria-label="Expand" onClick={this.resetTimer}>
                  <ResetIcon marginLeft="10px" style={{ fontSize: 20 }} />
                </IconButton>
              )}

              {/*<button onClick={this.PlayBeep}>Beep</button>*/}
            </div>
          </div>

          <div className={isMobile ? 'NextMute-mobile' : 'NextMute'}>
            Next mute phrase: {this.state.lineNextMute}
          </div>
        </div>
        <div className="NextMute">
            <Button
                  aria-label="Expand"
                  onClick={this.testTVMute}
                >
                  <MediaIcon marginLeft="10px" style={{ fontSize: 30 }} />
                  Test mute system
              </Button>  
        </div>

        {/*<Element className="scroll-container" id="scroll-container" >
        {timeSubtitles.map((Sline, i) => {
          return (
          <Element className="element" name={i} onClick={this.AdjustTime(Sline.starttime)} >
            {Sline.line}
          </Element>)
        })}
                    
      </Element> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
    movieSQL: state.movies_redux.movieSQL,
    movie: state.movies_redux.movie,
  };
}

export default connect(mapStateToProps)(BlockedDisplay);
