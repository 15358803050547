import { put, call } from 'redux-saga/effects';
import {
  userLoginSuccess,
  userLoginFailure,
  fetchUserFailure,
  fetchUserSuccess,
  userLogoutSuccess,
  userLogoutFailure,
  userLoginWithProviderSuccess,
  userLoginWithProviderFailure,
  userUpdateSuccess,
  userUpdateFailure,
  fetchUserDBSuccess,
  fetchUserDBFailure,
  passwordUpdateFailure,
  passwordUpdateSuccess,
  fetchCountrySuccess,
  fetchCountryFailure,
  userAddFilterSuccess,
  userAddFilterFailure,
  fetchUserDBSuccessWithFilter,
  userAddFilterRequest,
  userRegisterSuccess,
  userRegisterWithProviderSuccess,
  userRegisterWithProviderFailure,
  bookmarkDBAddSuccess,
  bookmarkDBAddFailure,
  initBookmarkTitle,
  zeroBookmarkTitle
} from '../actions';



import FireBaseTools from '../../utils/firebase';
import ReadUserDB from '../../utils/dbutils';
import * as common from '../../common.js';

export function* userAuthSagas(action) {
  try {
    //console.log('Saga: userAuthSaga: ', action.payload);
    const user = yield call(FireBaseTools.loginUser, action.payload);
    if (user.errorMessage) {
      yield put(userLoginFailure(user));
    } else {
      yield put(userLoginSuccess(user));
      // do another call
      //console.log ('saga-userAuth: Call fetchUserDB  ')
    }
  } catch (error) {
    yield put(userLoginFailure(error));
  }
}

export function* userAuthWithProvider(action) {
  try {
    const user = yield call(FireBaseTools.loginWithProvider, action.payload);
    if (user.errorMessage) {
      yield put(userLoginWithProviderFailure(user));
    } else {
      yield put(userLoginWithProviderSuccess(user));
      // do another call
      //console.log ('saga-userAuth: Call fetchUserDB ')
    }
  } catch (error) {
    yield put(userLoginWithProviderFailure(error));
  }
}

export function* userRegister(action) {
  try {
    console.log('Saga: userRegister call: ', action.payload);
    const user = yield call(FireBaseTools.registerUser, action.payload.userpass);
    if (user.errorMessage) {
      yield put(userLoginFailure(user));
    } else {
      //console.log'Saga: UserRegister success: ', user)
      yield put(userRegisterSuccess({user: user, region: action.payload.region}));
      // do another call
      //console.log ('saga-userAuth: Call fetchUserDB  ')
    }
  } catch (error) {
    yield put(userLoginFailure(error));
  }
}


export function* userRegisterWithProvider(action) {
  try {
    const user = yield call(FireBaseTools.loginWithProvider, action.payload.provider);
    console.log ('userRegisterWithProvider: ', user)

    // action.payload.user.metadata.creationTime
    // Check if current data / time >> this time
    // Sat, 12 Jan 2019 18:57:45 GMT

    const d = new Date(user.user.metadata.creationTime)
    const cd = new Date()
    const diff = cd-d
    //console.log'Difference Date: ', diff)
    
    
    if (diff > 120000) { // This user already exists - creation time greater than 2 mins
      var error = {errorCode: 'auth/provider-email-already-in-use'}
      yield put(userRegisterWithProviderFailure(error));
    }
    else if (user.errorMessage) {
      yield put(userRegisterWithProviderFailure(user));
    } else {
      console.log ('userRegisterWithProvider: ', user)
      yield put(userRegisterWithProviderSuccess({user: user, region: action.payload.region}));
      // do another call
      //console.log ('saga-userAuth: Call fetchUserDB ')
    }
  } catch (error) {
    yield put(userRegisterWithProviderFailure(error));
  }
}



export function* userDBAdd(action) {
  console.log('userDBAdd: ', action.payload)
  var newuser = '';
  if (action.payload.uid)
    newuser = action.payload.uid; 
  else
    newuser = action.payload.user.user.uid;  //firebase result
    
  try {
    //console.log'userDBAdd, Data: ', action.payload)
    var datapack = {
      firebaseID: newuser,
      accType: 1,
      newsletter: true,
      region: action.payload.region,
    };
    console.log('datapack: ', datapack, action.payload.user, action.payload.user.uid, action.payload.region)


    let response = yield call(
      fetch, `${common.DJANGOURL}/api/writeusers/`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(datapack),
      }
    )

    //console.log('saga-userAuth: after fetch ', response);
    let responseBody = yield response.json();

    if (responseBody != null) {
      var responseUser = responseBody
      //console.log'saga-userDBAdd: after json call: ', responseUser);
      if (responseUser) {// user has stored filters 
        //yield put(userDBAddSuccess(responseUser))
        var datapack = common.defaultFilters
        datapack.filterShortName = "Default"
        datapack.filterLongName = "Default"
        datapack.uid = newuser
        yield put(userAddFilterRequest(datapack))
      }
      else { // need to add Default Filter
        yield put (fetchUserFailure())
      }
      //yield put(fetchUserDBSuccess(responseBody[0]));

    } else {
      //console.log ('saga-userAuth: user result: NONE')
      yield put(fetchUserFailure());
    }
  } catch (error) {
    yield put(fetchUserFailure());
  }
}





export const delay = ms => new Promise(res => setTimeout(res, ms));


export function* userDBFetch(action) {
  try {
    var newuser = '';
    if (action.payload.uid)
      newuser = action.payload.uid;  //firebase result
    else
      newuser = action.payload.user.uid;

    //console.log('saga-userAuth: uid ', newuser);
    let response = yield call(fetch, `${common.DJANGOURL}/api/users/?id=${newuser}`);
    //console.log('saga-userAuth: after fetch ', response);
    let responseBody = yield response.json();

    if (responseBody != null) {
      var responseUser = responseBody[0]
      //console.log'saga-userAuth: after json call: ', responseUser);
      if (responseUser['saved_filters_set'].length > 0) // user has stored filters
        yield put(fetchUserDBSuccessWithFilter(responseUser))
      else { // need to add Default Filter
        var datapack = common.defaultFilters
        datapack.filterShortName = "Default"
        datapack.filterLongName = "Default"
        datapack.uid = newuser
        yield put(userAddFilterRequest(datapack))
      }
      //yield put(fetchUserDBSuccess(responseBody[0]));

    } else {
      //console.log ('saga-userAuth: user result: NONE')
      yield put(fetchUserFailure());
    }
  } catch (error) {
    yield put(fetchUserFailure());
  }
}

/*
export function* userDBCheckForFilter(action) {
  //try {
    console.log ('userDBCheckForFilter: ', action.payload)
    var savedfilters = action.payload['saved_filters_set']
    if (savedfilters.length>0) {
      yield put(fetchUserDBSuccessWithFilter(action.payload))
    }
    else {
      var datapack = common.defaultFilters
      datapack.filterShortName = "Default"
      datapack.filterLongName = "Default"
      datapack.uid = action.payload.firebaseID
      yield put(userAddFilterRequest(datapack))
    }
  //} catch (error) {
  //  yield put(fetchUserDBFailure());
  //}

}
*/


export function* userCountryFetch(action) {
  try {
    //console.log'userCountryFetch: before fetch');
    let response = yield call(fetch, `https://ssl.geoplugin.net/json.gp?k=bd8f933fce5a2f9e`);
    //console.log'userCountryFetch: after fetch ', response);
    let responseBody = yield response.json();
    //console.log'userCountryFetch: responseBody ', responseBody);
    if (responseBody != null) {
      if (responseBody['geoplugin_countryCode']) {
        const countrycode = responseBody['geoplugin_countryCode'].toLowerCase()
        if(common.NetflixRegions.some(e => e.code == countrycode )) {
          //console.log'userCountryFetch: countrycode ', countrycode);
          yield put(fetchCountrySuccess(countrycode));
        }
        else 
          yield put(fetchCountryFailure());
      }
      else  
        yield put(fetchCountryFailure());
      
    } else {
      yield put(fetchCountryFailure());
    }
  } catch (error) {
    yield put(fetchCountryFailure());
  }
}




export function* userFetchSagas() {
  try {
    //console.log'Saga: FetchUser')
    const user = yield call(FireBaseTools.fetchUser);
    if (user !== null) {
      yield put(fetchUserSuccess(user));
    } else {
      yield put(fetchUserFailure());
    }
  } catch (error) {
    yield put(fetchUserFailure());
  }
}

export function* userLogout(user) {
  try {
    const data = yield call(FireBaseTools.logoutUser, user);
    yield put(userLogoutSuccess(data));
  } catch (error) {
    yield put(userLogoutFailure(error));
  }
}

export function* updateUserProfile(userdata) {
  try {

    //console.log('updateUserProfile', userdata, userdata.payload.old.newsletter, userdata.payload.new.newsletter)
    /* compare the old and new data to see what to update */

    if (userdata.payload.old.email != userdata.payload.new.email) {
      //console.log('updateUserProfile: userUpdateFirebase: email: ', userdata.payload.new.email)
      const user = yield call(FireBaseTools.updateUserEmail, userdata.payload.new.email);
      if (user) {
        yield put(userUpdateFailure(user));
      } else {
        yield put(userUpdateSuccess(userdata.payload));
        // do another call
        //console.log ('saga-userAuth: Call fetchUserDB  ')
      }
    }
    else {
      yield put(userUpdateSuccess(userdata.payload));
    }

  } catch (error) {
    yield put(userUpdateFailure(error));
  }
}


export function* updateUserPassword(action) {
  try {
    //console.log('updateUserPassword: newPassword: ', action.payload.pass)
    const user = yield call(FireBaseTools.changePassword, action.payload.pass);
    //console.log('updateUserPassword: data: ', user)
    if (user) {
      yield put(passwordUpdateFailure(user));
    } else {
      yield put(passwordUpdateSuccess());
      // do another call
      //console.log ('saga-userAuth: Call fetchUserDB  ')
    }
  } catch (error) {
    yield put(passwordUpdateFailure(error));
  }
}

export function* userAddFilter(action) {
  try {
  const userdata = action.payload
  
    const blockedwordsStr = userdata.blockedwords.toString();
    const ageratingsStr = userdata.ageratings.toString();
    const genresStr = userdata.genres.toString();
    //console.log' userAddFilter: ', userdata)
    
    const datapack = {  
      filterShortName: userdata.filterShortName,
      filterLongName: userdata.filterLongName, 
      user: userdata.uid,
      blockedwords: blockedwordsStr,
      ageratings: ageratingsStr,
      genres: genresStr,
      blasphemy: userdata.blasphemy,
      profanity: userdata.profanity,
      sexual: userdata.sexual,
      racial: userdata.racial, 
      rating: userdata.rating,
      startyear: userdata.startyear,
      endyear: userdata.endyear,
      excludeBlasphemy: userdata.excludeBlasphemy,
      excludeProfanity: userdata.excludeProfanity,
      excludeSexual: userdata.excludeSexual,
      excludeRacial: userdata.excludeRacial,
      netflix: userdata.netflix,
      original_language: userdata.original_language,
      locklanguage: userdata.locklanguage,
      showMovies: userdata.showMovies
    };
    //console.log'SAGA: AddFilterDB:  ', datapack)
    const response = yield call(
      fetch, `${common.DJANGOURL}/api/savedfilters/`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(datapack),
      }
    );

    //console.log('saga-userAuth: after fetch ', response);
    //console.log'SAGA AddFilterDB response: ', response)
    let responseBody = yield response.json();
    //console.log'SAGA: AddFilterDB: after json call: ', responseBody);
    if (responseBody != null) {
      yield put(userAddFilterSuccess(responseBody));
    } else {
      //console.log ('saga-userAuth: user result: NONE')
      yield put(userAddFilterFailure({error: 'ResponseBody is Null'}));
    }
  } catch (error) {
    //console.log'SAGA: AddFilterDB: error: ', error);
    yield put(userAddFilterFailure(error));
  }
}


export function*  bookmarkDBAdd(action) {
  try {

    const userdata = action.payload
    var datapack = {
      user: userdata.userID,
      titleID: userdata.imdbID,
      tvID: userdata.tvID,
    };
  

    //console.log'bookmarkDBAdd: ', datapack)

    const response = yield call(
      fetch, `${common.DJANGOURL}/api/titlehistorypost/`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(datapack),
      }
    );

    //console.log('saga-userAuth: after fetch ', response);
    //console.log'SAGA AddbookmarkDB response: ', response)
    let responseBody = yield response.json();
    if (responseBody != null) {
      yield put(bookmarkDBAddSuccess({...userdata, id:responseBody.id}));
    } else {
      //console.log ('saga-userAuth: user result: NONE')
      yield put(bookmarkDBAddFailure({error: 'ResponseBody is Null'}));
    } 
  } catch (error) {
    //console.log'SAGA: AddbookmarkrDB: error: ', error);
    yield put(bookmarkDBAddFailure(error));
  }
}


export function*  bookmarkDBDel(action) {
  try {

    const userdata = action.payload
    //console.log'bookmarkDBAdd: ', userdata)

    const response = yield call(
      fetch, `${common.DJANGOURL}/api/titlehistorypost/${userdata}`, {
        method: 'delete',
        headers: {
          'Content-Type': 'application/json',
        },
        body: {},
      }
    );

    //console.log('saga-userAuth: after fetch ', response);
    //console.log'SAGA DelbookmarkrDB response: ', response)
    /*let responseBody = yield response.json();
    if (responseBody != null) {
      yield put(user(responseBody));
    } else {
      //console.log ('saga-userAuth: user result: NONE')
      yield put(bookmarkDBAddSuccess({error: 'ResponseBody is Null'}));
    } */
  } catch (error) {
    //console.log'SAGA: DelbookmarkrDB: error: ', error);
    //yield put(bookmarkDBAddSuccess(error));
  }
}

export function*  bookmarkDBInit(action) {
  yield put(initBookmarkTitle(action.payload.movie_history_set))
}

export function* bookmarkDBZero() {
  yield put(zeroBookmarkTitle())
}

