import React, { Component } from 'react';
import { Router, browserHistory } from 'react-router';
import { Helmet } from 'react-helmet';

import { connect } from 'react-redux';
import { userLoginWithProviderRequest, registerUser, registerUserWithProvider } from '../../../redux/actions';
//import { registerUser } from '../../../redux/actions/firebase_actions';

import { Redirect } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import google_image from '../../../assets/Google_G_Logo.svg';
import threewise_image from '../../../assets/3wise_logo_nologo.png';
import { Link } from 'react-router-dom';
import View from 'react';
import Text from 'react';
import { PayPalButton } from "react-paypal-button-v2";

import * as constants from '../constants.js'
import * as common from '../../../common.js'

// <SignUpLink />

const styles = theme => ({
  container: {
    margin: 'auto',
    marginTop: 100,
    maxWidth: 400,
    color: '#222222',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#EEEEEE',
  },

  loginError: {
    width: '340px',
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingBottom: '10px',
    paddingTop: '10px',
    fontSize: '14px',
    color: '#222222',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#ffefef',
    border: '1px solid #cc0000',
  },

  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    minWidth: 300,
  },

  button: {
    margin: theme.spacing.unit,
    textTransform: 'none',
  },

  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});


class SignUpPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      pass1: '',
      pass2: '',
      goHome: false,
      goProfile: false,
      error: '',
      errorType: '',
      update: ''
    };
    //this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  handleChange = event => {
    //console.log('SignUp: event change: ', event.target.value, event.target.name)
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  componentWillReceiveProps(nextProps) {

    //console.log('SignUp: ', nextProps.currentUser);
    if (nextProps.currentUser.error != null) {

      if (nextProps.currentUser.error.errorCode == constants.ERROR_CODE_ACCOUNT_EXISTS) {
        //console.log(constants.ERROR_MSG_ACCOUNT_EXISTS);
        this.setState({ error: constants.ERROR_MSG_ACCOUNT_EXISTS, errorType: constants.ERROR_CODE_ACCOUNT_EXISTS });
      }
      if (nextProps.currentUser.error.errorCode == constants.ERROR_CODE_ACCOUNT_PROVIDER_EXISTS) {
        //console.log(constants.ERROR_MSG_ACCOUNT_EXISTS);
        this.setState({ error: constants.ERROR_MSG_ACCOUNT_PROVIDER_EXISTS, errorType: constants.ERROR_CODE_ACCOUNT_PROVIDER_EXISTS });
      }

      if (nextProps.currentUser.error.errorCode == constants.ERROR_CODE_PASSWORD_SHORT) {
        //console.log(constants.ERROR_MSG_PASSWORD_SHORT);
        this.setState({ error: constants.ERROR_MSG_PASSWORD_SHORT, errorType: constants.ERROR_CODE_PASSWORD_SHORT });
      }

    }
  }

 registerUserPass = () => {
    var region = common.region
    //console.log('region: ', this.props.currentUser.region)
    //event.preventDefault();

    // TODO remove depricated "this."
    const email = this.state.email;
    const pass = this.state.pass1;
    //console.log('SignUp: formsubmit ', email,pass);
    if (this.props.currentUser)
      if (this.props.currentUser.region)
        region = this.props.currentUser.region
    this.props.registerUser({userpass:{ email, pass}, region: region });
    //this.props.history.push('/');

  }

  registerWithProvider(provider) {
    var region = common.region

    //console.log('region: ', this.props.currentUser.region)
    if (this.props.currentUser)
      if (this.props.currentUser.region)
        region = this.props.currentUser.region
    this.props.registerUserWithProvider({provider: provider, region: region});

    //this.props.history.push('/');
    //browserHistory.push('/profile')
    // this.props.loginWithProvider(provider).then((data) => {
    //     if (data.payload.errorCode) {
    //         this.setState({ message: data.payload.errorMessage })
    //     } else {
    //         browserHistory.push('/profile')
    //     }
    // })
    //this.props.OnDone()
    this.setState({ goProfile: true });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.currentUser.isLoggedIn) {
      this.props.OnDone();
      this.setState({ goHome: true });
    }

    return true;
  }

  handleClose = () => {
    this.setState({ goHome: true });
    this.props.OnDone();
  };

  render() {
    const { classes, ...other } = this.props;
    const { pass1, pass2, email, error, errorType } = this.state;



    if (this.state.goHome) {
      return <Redirect to="/" />;
    }

    if (this.state.goProfile && (this.props.currentUser.isLoggedIn)) {
      return <Redirect to="/profile" />;
    }


    const isInvalid = pass1 !== pass2 || pass1 === '' || pass2 === '' || email === '';
    //console.log('SignUp: errorType', errorType)
    return (
      <div className={classes.container}>
        <Helmet>
          <title>SignUp</title>
          <meta name="author" content="David Lloyd johnson" />
          <meta name="robots" content="noindex"/>
        </Helmet>
        <h4>Create a 3Wise account</h4>


        {(errorType == constants.ERROR_CODE_ACCOUNT_EXISTS) ? (
          <div className={classes.loginError}>
            <div>
              {error}{' '}
              <a style={{ color: 'blue', textDecoration: 'none' }} href="/passwordforget">
                Have you forgotten your password?
            </a>
            </div>
          </div>
        ) : null}
        {(errorType == constants.ERROR_CODE_ACCOUNT_PROVIDER_EXISTS) ? (
          <div className={classes.loginError}>
            <div>
              {error}{' '}
              <a style={{ color: 'blue', textDecoration: 'none' }} href="/signin">
                Try Sign In?
            </a>
            </div>
          </div>
        ) : null}

        {errorType == constants.ERROR_CODE_PASSWORD_SHORT ? (
          <div className={classes.loginError}>
            <div>
              {error}
            </div>
          </div>
        ) : null}







        <TextField
          name="email"
          label="Email"
          id="txtEmail"
          color="default"
          value={this.state.email}
          className={classes.textField}
          onChange={e => this.handleChange(e)}
          margin="normal"
        />
        <TextField
          name="pass1"
          label="Password"
          id="txtPass"
          color="default"
          value={this.state.pass1}
          className={classes.textField}
          onChange={e => this.handleChange(e)}
          type="password"
          margin="normal"
        />
        <TextField
          name="pass2"
          label="Confirm Password"
          id="txtPass2"
          color="default"
          value={this.state.pass2}
          className={classes.textField}
          onChange={e => this.handleChange(e)}
          type="password"
          margin="normal"
        />

        <div>
          <Button
            disabled={isInvalid}
            variant="contained"
            size="medium"
            color="default"
            fullWidth={true}
            onClick={this.registerUserPass}
            className={classes.button}
            style={{ width: '250px', marginTop: '20px' }}
          >
            Submit
          </Button>
        </div>

        <div>
          <Button
            variant="contained"
            size="medium"
            color="default"
            fullWidth={true}
            onClick={() => {
              this.registerWithProvider('google');
            }}
            className={classes.button}
            style={{ width: '250px', marginBottom: '20px' }}
          >
            <div style={{ display: 'flex' }}>
              <img src={google_image} height={20} width={20} />{' '}
              <div>&nbsp; Sign Up with Google</div>
            </div>
          </Button>
        </div>

        <div>
          <Button
            variant="contained"
            size="medium"
            color="default"
            fullWidth={true}
            onClick={() => {
              this.handleClose();
            }}
            className={classes.button}
            style={{ width: '250px', marginBottom: '20px' }}
          >
            Home
          </Button>

         


          {/*<PayPalButton
            amount="1.00"
            // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
            onSuccess={(details, data) => {
              alert("Transaction completed by " + details.payer.name.given_name);
            }}
          />*/}

        </div>
        </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
  };
}

const mapDispatchToProps = dispatch => ({
  registerUser: user => dispatch(registerUser(user)),
  loginWithProvider: provider => dispatch(userLoginWithProviderRequest(provider)),
  registerUserWithProvider: provider => dispatch(registerUserWithProvider (provider))
});


const WrapSignUpPage = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SignUpPage));

export default WrapSignUpPage;
