import React, { Component } from 'react';
import { Router, browserHistory } from 'react-router';

import { connect } from 'react-redux';
import {
  fetchUserRequest,
  userLoginRequest,
  userLoginWithProviderRequest,
  userUpdatePassword,
  userUpdateRequest,
} from '../../../redux/actions';
import { Redirect } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import google_image from '../../../assets/Google_G_Logo.svg';
import threewise_image from '../../../assets/3wise_logo_nologo.png';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import View from 'react';
import Text from 'react';

import * as constants from '../constants.js'

// <SignUpLink />

const styles = theme => ({
  container: {
    margin: 'auto',
    marginTop: 100,
    maxWidth: 400,
    color: '#222222',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#EEEEEE',
  },

  memberOuter: {
    margin: '10px'
  },

  memberBox: {
    width: 340,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1px solid #CCCCCC',
    padding: '5px',
    boxShadow: '0 0 5px #BBBBBB'
  },


  loginError: {
    width: '340px',
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingBottom: '10px',
    paddingTop: '10px',
    fontSize: '14px',
    color: '#222222',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#ffefef',
    border: '1px solid #cc0000',
  },

  updated: {
    width: '340px',
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingBottom: '10px',
    paddingTop: '10px',
    fontSize: '14px',
    color: '#222222',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#feffc8',
    border: '1px solid #BBBBBB',
  },


  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    minWidth: 300,
  },

  button: {
    margin: theme.spacing.unit,
    textTransform: 'none',
  },

  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: this.props.currentUser.email,
      pass: '',
      goHome: false,
      goChangePassword: false,
      cancel: false,
      error: '',
      updated: '',
      joinDate: this.props.currentUser.join,
      memberPlan: this.props.currentUser.type,
      memberNews: this.props.currentUser.newsletter,
      revealPassword: false,
      currentPass: '',
      pass1: '',
      pass2: ''
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);

  }

  componentWillReceiveProps(nextProps) {
    //console.log('Profile, will receiveprops: all: ', nextProps.currentUser)
    if (nextProps.currentUser.isLoggedIn && !nextProps.currentUser.pending) {
      //console.log('Signin Page: componentWillReceiveProps ')
      //this.props.history.push('/');
      //browserHistory.push('/')
      //console.log('Profile, will receiveprops: ', nextProps.currentUser, this.props.currentUser);

      this.setState({
        joinDate: nextProps.currentUser.join,
        memberPlan: nextProps.currentUser.type,
        memberNews: nextProps.currentUser.newsletter,
        email: nextProps.currentUser.email
      })
      if (!this.props.currentUser.pending) {
        if ((nextProps.currentUser.joinDate != this.props.currentUser.joinDate) ||
          (nextProps.currentUser.type != this.props.currentUser.type) ||
          (nextProps.currentUser.newsletter != this.props.currentUser.newsletter) ||
          (nextProps.currentUser.email != this.props.currentUser.email)) {

          this.setState({ updated: 'Your changes have been saved' })


        }
      }


    }
    if (!nextProps.currentUser.isLoggedIn)
      this.setState({goHome: true})
 



    //console.log('SignUp: ', nextProps.currentUser);
    if (nextProps.currentUser.error != null) {

      if (nextProps.currentUser.error.errorCode == constants.ERROR_CODE_ACCOUNT_RELOGIN) {
        //console.log(constants.ERROR_MSG_ACCOUNT_RELOGIN);
        this.setState({ error: constants.ERROR_MSG_ACCOUNT_RELOGIN, errorType: constants.ERROR_CODE_ACCOUNT_RELOGIN });
      }
      if (nextProps.currentUser.error.errorCode == constants.ERROR_CODE_INVALID_EMAIL) {
        //console.log(constants.ERROR_MSG_INVALID_EMAIL);
        this.setState({ error: constants.ERROR_MSG_INVALID_EMAIL, errorType: constants.ERROR_CODE_INVALID_EMAIL });
      }
      if (nextProps.currentUser.error.errorCode == constants.ERROR_CODE_ACCOUNT_EXISTS) {
        //console.log(constants.ERROR_MSG_ACCOUNT_EXISTS);
        this.setState({ error: constants.ERROR_MSG_ACCOUNT_EXISTS, errorType: constants.ERROR_CODE_ACCOUNT_EXISTS });
      }

    }



  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  changePassword = () => {
    this.setState({ goChangePassword: true })
  }

  createAccount = () => {
    //console.log('SignIn: Create Account');
    this.setState({ goSignUp: true });
  };

  onFormSubmit(event) {

    var datapack = {
      uid: this.props.currentUser.uid,
      old: {
        email: this.props.currentUser.email,
        accType: this.props.currentUser.type,
        newsletter: this.props.currentUser.newsletter
      },
      new: {
        email: this.state.email,
        accType: this.state.memberPlan,
        newsletter: this.state.memberNews
      }
    };


    //console.log('Profile datapack: ', datapack);
    this.props.updateUser(datapack);

    /*fetch(`${common.DJANGOURL}/api/users/${this.props.currentUser.uid}/`, {
      method: 'patch',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/json',
      },
      body: JSON.stringify(datapack),
    })
      .then(function (response) {
        return response.json();
      })
      .catch(function (ex) {
        //console.log("Profile update failed: ", ex);
      });*/

  }



  handlenewsToggle = () => {
    //console.log('togglenews')
    this.setState({ memberNews: !this.state.memberNews })
  }

  shouldComponentUpdate(nextProps, nextState) {

    //console.log('Signin: ShouldUpdate')
    return true;

  }

  handleClose = () => {
    this.setState({ goHome: true, cancel: true });
    this.props.OnDone();
  };



  render() {
    const { classes, ...other } = this.props;
    const { error, updated } = this.state;
    //console.log('Profile: ', this.props.currentUser, this.state)

    if (this.state.goHome) {
      return <Redirect to="/" />;
    }

    if (this.state.goChangePassword && (this.props.currentUser.isLoggedIn)) {
      return <Redirect to="/passwordchange" />;
    }



    return (
      <div>

        <form

          className={classes.container}
          noValidate
          autoComplete="off"
        >
          <h4 style={{ marginTop: '10px' }}>3Wise Account</h4>
          <hr style={{ border: '1px', height: '1px', opacity: '0.3', backgroundColor: '#222222' }} />
          {error ? (
            <div>
              <div className={classes.loginError}>
                {error}
              </div>
              <br />
            </div>
          ) : null}

          {updated ? (
            <div>
              <div className={classes.updated}>
                {updated}
              </div>
              <br />
            </div>

          ) : null}


          <h4 className="lineText">
            <span>Sign-In Details</span>
          </h4>

          <TextField
            name="email"
            label="Email"
            id="txtEmail"
            color="default"
            value={this.state.email}
            className={classes.textField}
            onChange={e => this.handleChange(e)}
            margin="normal"
          />


          <div>
            <Button
              variant="contained"
              size="medium"
              color="default"
              fullWidth={true}

              className={classes.button}
              style={{ width: '200px', marginTop: '20px' }}
              onClick={() => {
                this.changePassword();
              }}
            >
              Change password
            </Button>
          </div>


          <br />
          <h4 className="lineText">
            <span>Membership Details</span>
          </h4>

          <div className={classes.memberOuter}>
            <p>You have been a member since <b>{this.state.joinDate}</b></p>

            <div className={classes.memberBox}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', justifyContent: 'center' }}>
                <div><b>Membership Plan</b></div>
                {this.state.memberPlan == 1 ? (
                  <div>Basic</div>
                ) : (
                    <div>Pro</div>
                  )
                }

              </div>

              {/*this.state.memberPlan == 1 ? (
                <div>
                   <Link to={'/upgradeprofile'} style={{ textDecoration: 'none', color: '#222222' }}>
                  <Button
                    variant="contained"
                    size="medium"
                    color="default"
                    fullWidth={true}
                    type="submit"
                    className={classes.button}
                    style={{ width: '150px' }}
                  >
                    3Wise Plus
                  </Button>
                  </Link>
                </div>
              ) : (
                  <div>
                    <Button
                      variant="contained"
                      size="medium"
                      color="default"
                      fullWidth={true}
                      type="submit"
                      className={classes.button}
                      style={{ width: '150px' }}
                    >
                      3Wise Basic
                    </Button>
                  </div>
                )
              */}


            </div>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Checkbox
              checked={this.state.memberNews}
              onChange={() => { this.handlenewsToggle(); }}
              value="memberNews"
              color="primary"
            />
            Subscribe to newsletter
          </div>
          <br />

          <hr style={{ border: '1px', height: '1px', opacity: '0.3', backgroundColor: '#222222' }} />

          <div>
            <Button
              variant="contained"
              size="medium"
              color="default"
              fullWidth={true}

              onClick={() => {
                this.onFormSubmit();
              }}
              className={classes.button}
              style={{ width: '200px', marginBottom: '20px' }}
            >
              Save
            </Button>
          </div>



        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
  };
}

const mapDispatchToProps = dispatch => ({
  fetchUser: () => dispatch(fetchUserRequest()),
  updateUser: (userdata) => dispatch(userUpdateRequest(userdata)),
  loginUser: user => dispatch(userLoginRequest(user)),
  loginWithProvider: provider => dispatch(userLoginWithProviderRequest(provider)),
});

const WrapProfile = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Profile));

export default WrapProfile;