import * as common from '../common.js';

/*const ReadUserDB = (uid) => {
    //console.log('userAuth ReadUserDB userAuth: ', uid)

    // Write ID to database

   
    //console.log('Feedback: You did a submit')
    const urlUsers = fetch(`${common.DJANGOURL}/api/users/?id=${uid}`);
    var urls = [urlUsers]
    Promise.all(urls) 
    .then(([res]) => Promise.all([res.json()]))
    .then(([data]) => {
        //console.log("dbUtils.js Response: ", data[0]);
        return(data[0])
    })
    .catch (err => {
        //console.log("dbUtils.js: error", err);
    })
} */

const ReadUserDB = uid => {
  //console.log('userAuth ReadUserDB userAuth: ', uid)

  // Write ID to database

  //console.log('Feedback: You did a submit')
  return fetch(`${common.DJANGOURL}/api/users/?id=${uid}`);
};

export default ReadUserDB;
