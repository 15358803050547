import * as actionTypes from '../constants/action-types';
import * as common from '../../common.js';
import { put, call } from 'redux-saga/effects';
import {initBookmarkTitle} from '../actions/'


const initialState = {
  username: null,
  email: null,
  error: null,
  isLoggedIn: false,
  uid: null,
  type: 1,
  newsletter: 1,
  region: common.region,
  pending: true,
  firebase: {},
  passwordUpdate: false,
  filters: [common.defaultFilters],
  currentfilter: common.defaultFilters,
  currentfilterIndex: 0,
  
};

const logoutState = {
  username: null,
  email: null,
  error: null,
  isLoggedIn: false,
  uid: null,
  type: 1,
  newsletter: 1,
  region: common.region,
  pending: false,
  firebase: {},
  passwordUpdate: false,
  filters: [common.defaultFilters],
  currentfilter: common.defaultFilters,
  currentfilterIndex: 0
};


const ProcessFilters = filterDB => {
}



const WriteUserDB = uid => {
  

  // Write ID to database
  var d = new Date();

  var datapack = {
    firebaseID: uid,
    accType: 1,
    newsletter: true,
    region: common.region,
  };

  //console.log'userAuth: WriteUserDB ', datapack)

  //console.log('Feedback: You did a submit')
  fetch(`${common.DJANGOURL}/api/writeusers/`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      Accept: 'application/json',
    },
    body: JSON.stringify(datapack),
  })
    .then(function (response) {



      return response.json();
    })
    .then(function (data) {
      //console.log("User: Data is ok", data);
    })
    .catch(function (ex) {
      //console.log("User: parsing failed", ex);
    });

  //console.log('User: Completed submit')
};



const UpdateUserDB = userdata => {
  //console.log('userAuth: updateUserDB:  ', userdata)

  // Write ID to database
  var uid = userdata.uid;


  var datapack = {
    newsletter: userdata.new.newsletter,
    type: userdata.new.accType

  };

  //console.log('userUpdateDB: You did a submit')
  fetch(`${common.DJANGOURL}/api/users/${uid}/`, {
    method: 'PATCH',
    //mode: 'no-cors',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      Accept: 'application/json',
    },
    body: JSON.stringify(datapack),
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      //console.log("User: Data is ok", data);
    })
    .catch(function (ex) {
      //console.log("User Update Issue: ", ex);
    });

  //console.log('User: Completed submit')
};


const UpdateFilterDB = (userdata) => {
  //console.log('userAuth: updateUserDB:  ', userdata)

  if ('blockedwords' in userdata) {
    const blockedwordsStr = userdata.blockedwords.toString();
    const ageratingsStr = userdata.ageratings.toString();
    const genresStr = userdata.genres.toString();
    //console.log('UpdateFilterDB: ', blockedwordsStr, ageratingsStr, genresStr)
    const fid = userdata.id
    
    const datapack = {
      blockedwords: blockedwordsStr,
      ageratings: ageratingsStr,
      genres: genresStr,
      blasphemy: userdata.blasphemy,
      profanity: userdata.profanity,
      sexual: userdata.sexual,
      racial: userdata.racial, 
      rating: userdata.rating,
      startyear: userdata.startyear,
      endyear: userdata.endyear,
      excludeBlasphemy: userdata.excludeBlasphemy,
      excludeProfanity: userdata.excludeProfanity,
      excludeSexual: userdata.excludeSexual,
      excludeRacial: userdata.excludeRacial,
      netflix: userdata.netflix,
      original_language: userdata.original_language,
      locklanguage: userdata.locklanguage,
      showMovies: userdata.showMovies
    };


    //console.log('Feedback: You did a submit')
    fetch(`${common.DJANGOURL}/api/savedfilters/${fid}/`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/json',
      },
      body: JSON.stringify(datapack),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
       //console.log("Filter: Data is ok", data);
      })
      .catch(function (ex) {
        //console.log("Filter: parsing failed", ex);
      });

    //console.log('Filter: Completed submit')
  }
};

/*const AddFilterDB = (userdata, uid) => {
  

  if ('blockedwords' in userdata) {
    const blockedwordsStr = userdata.blockedwords.toString();
    const ageratingsStr = userdata.ageratings.toString();
    const genresStr = userdata.genres.toString();
    //console.log('UpdateFilterDB: ', blockedwordsStr, ageratingsStr, genresStr)
   
    const datapack = {  
      filterShortName: userdata.filterShortName,
      filterLongName: userdata.filterLongName, 
      user: uid,
      blockedwords: blockedwordsStr,
      ageratings: ageratingsStr,
      genres: genresStr,
      blasphemy: userdata.blasphemy,
      profanity: userdata.profanity,
      sexual: userdata.sexual,
      racial: userdata.racial, 
      rating: userdata.rating,
      startyear: userdata.startyear,
      endyear: userdata.endyear,
      excludeBlasphemy: userdata.excludeBlasphemy,
      excludeProfanity: userdata.excludeProfanity,
      excludeSexual: userdata.excludeSexual,
      excludeRacial: userdata.excludeRacial,
      netflix: userdata.netflix,
      original_language: userdata.original_language,
      locklanguage: userdata.locklanguage,
      showMovies: userdata.showMovies
    };
    //console.log'userAuth: AddFilterDB:  ', datapack)

    //console.log('Feedback: You did a submit')
    fetch(`${common.DJANGOURL}/api/savedfilters/`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/json',
      },
      body: JSON.stringify(datapack),
    })
      .then(r => r.json())
      .then(data => {
       //console.log("Filter: Data is ok", data);
       //console.log'userAuth: AddFilterDB Response 2 : ', data);
       userAddFilterSuccess(data);
      })
      .catch(function (ex) {
        //console.log("Filter: parsing failed", ex);
      });

    //console.log('Filter: Completed submit')
  }

};*/

const DelFilterDB = (fid) => {
  //console.log('userAuth: updateUserDB:  ', userdata)
  //console.log'Received filter del before: ', fid)
  if (fid) {
     //console.log'Received filter del after: ', fid)
    
    //console.log('Feedback: You did a submit')
    fetch(`${common.DJANGOURL}/api/savedfilters/${fid}`, {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/json',
      },
      body: {},
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
       //console.log("Filter: Data is ok", data);
      })
      .catch(function (ex) {
        //console.log("Filter: parsing failed", ex);
      });

    //console.log('Filter: Completed submit')
  }
};



export const userAuth = (state = initialState, action) => {
  const currentDate = new Date().toDateString();
  switch (action.type) {
    // user fetch

    case actionTypes.SET_REGION:
      return { ...state, region: action.payload}

    case actionTypes.USER_FETCH_REQUEST:
      return { ...state };
    case actionTypes.USER_FETCH_SUCCESS:
      //console.log'userAuth: USER_FETCH_SUCCESS: ', action.payload)
      /*if (action.payload) {
        WriteUserDB(action.payload.uid);
      }*/
    
      return {
        firebase: action.payload,
        email: action.payload.email,
        username: action.payload.displayName,
        uid: action.payload.uid,
        isLoggedIn: true,
        type: 1,
        join: 0,
        newsletter: 1,
        region: state['region'],
        pending: true,
        passwordUpdate: false,
        filters: [common.defaultFilters],
        currentfilter: common.defaultFilters,
        currentfilterIndex: 0
      };


    case actionTypes.USERDB_FETCH_SUCCESS_WITH_FILTER:
      //console.log('USERDB_FETCH_SUCCESS_WITH_FILTER: ', action.payload)
      var savedfilters = action.payload['saved_filters_set']
      savedfilters.map((filt,index) => {
        var blockedwords = filt.blockedwords.split(',')
        var ageratings = filt.ageratings.split(',')
        var genres = filt.genres.split(',')
        if (blockedwords.length <= 1)
          blockedwords = []
        if (ageratings.length <= 1)
          ageratings = []
        if (genres.length <= 1)
          genres = []
        savedfilters[index].blockedwords = blockedwords

        // Expand the ageratings to mix movies and TV and find equivalents between each


        savedfilters[index].ageratings = common.ageRatingMatch(ageratings)


        savedfilters[index].genres = genres
        savedfilters[index]['christian'] = false
      })
      var defaultfilter = savedfilters[0]
     

      return {
        firebase: state['firebase'],
        email: state['email'],
        username: state['username'],
        uid: state['uid'],
        isLoggedIn: state['isLoggedIn'],
        type: action.payload['accType'],
        join: action.payload['joined'],
        newsletter: action.payload['newsletter'],
        region: action.payload['region'],
        pending: false,
        passwordUpdate: false,
        filters: savedfilters,
        currentfilter: defaultfilter,
        currentfilterIndex: 0,
      };
    
    /*

    
    case actionTypes.USERDB_FETCH_SUCCESS:
      var defaultfilter = {}
      var savedfilters = action.payload['saved_filters_set']
      if (savedfilters.length>0) {
        savedfilters.map((filt,index) => {
            var blockedwords = filt.blockedwords.split(',')
            var ageratings = filt.ageratings.split(',')
            var genres = filt.genres.split(',')
            if (blockedwords.length <= 1)
              blockedwords = []
            if (ageratings.length <= 1)
              ageratings = []
            if (genres.length <= 1)
              genres = []
            savedfilters[index].blockedwords = blockedwords
            savedfilters[index].ageratings = ageratings
            savedfilters[index].genres = genres
        })
        defaultfilter = savedfilters[0]
      }
      else {
        // Add a default Filter
        defaultfilter = common.defaultFilters
        defaultfilter['uid'] = state['uid']

        savedfilters = [common.defaultFilters]
        defaultfilter = common.defaultFilters
      }

      return {
        firebase: state['firebase'],
        email: state['email'],
        username: state['username'],
        uid: state['uid'],
        isLoggedIn: state['isLoggedIn'],
        type: action.payload['accType'],
        join: action.payload['joined'],
        newsletter: action.payload['newsletter'],
        region: action.payload['region'],
        pending: false,
        passwordUpdate: false,
        filters: savedfilters,
        currentfilter: defaultfilter,
        currentfilterIndex: 0,
      };*/

    

    case actionTypes.USER_FETCH_FAILURE:
      return { ...state, pending: true, error: action.payload};

    

    // user login


    case actionTypes.COUNTRY_FETCH_SUCCESS:
      return { ...state, pending: false, region: action.payload}

    case actionTypes.COUNTRY_FETCH_FAILURE:
      return { ...state, pending: false, region: 'us'}

    /*case actionTypes.USER_LOGIN_REQUEST:
      return action.payload;*/

    case actionTypes.USER_LOGIN_SUCCESS:
      //console.log('userAuth: USER_LOGIN_SUCCESS', action.payload)
      /*if (action.payload) {
        WriteUserDB(action.payload.user.uid);
      }*/

      var displayName = 'none';

      if (action.payload.displayName != null) displayName = action.payload.user.displayName;
      

      return {
        firebase: action.payload.user,
        email: action.payload.user.email,
        username: displayName,
        uid: action.payload.user.uid,
        isLoggedIn: true,
        join: currentDate,
        type: 1,
        newsletter: 1,
        region: state['region'],
        pending: true,
        passwordUpdate: false,
        filters: [common.defaultFilters],
        currentfilter: common.defaultFilters,
        currentfilterIndex: 0
      };

    case actionTypes.USER_REGISTER_SUCCESS:
      var displayName = 'none';
      //console.log'USER_REGISTER_SUCCESS: ', action.payload)
      if (action.payload.user.displayName != null) displayName = action.payload.user.displayName;
      

      return {
        firebase: action.payload.user.user,
        email: action.payload.user.user.email,
        username: displayName,
        uid: action.payload.user.user.uid,
        isLoggedIn: true,
        join: currentDate,
        type: 1,
        newsletter: 1,
        region: state['region'],
        pending: true,
        passwordUpdate: false,
        filters: [common.defaultFilters],
        currentfilter: common.defaultFilters,
        currentfilterIndex: 0
      };
    
    /*case actionTypes.USERDB_ADD_SUCCESS
        return { ...state, pending: true}*/

    case actionTypes.USER_REGISTER_WITH_PROVIDER_SUCCESS:
      var displayName = 'none';
      //console.log'USER_REGISTER_SUCCESS: ', action.payload)
      if (action.payload.user.displayName != null) displayName = action.payload.user.displayName;
      

      return {
        firebase: action.payload.user.user,
        email: action.payload.user.user.email,
        username: displayName,
        uid: action.payload.user.user.uid,
        isLoggedIn: true,
        join: currentDate,
        type: 1,
        newsletter: 1,
        region: state['region'],
        pending: true,
        passwordUpdate: false,
        filters: [common.defaultFilters],
        currentfilter: common.defaultFilters,
        currentfilterIndex: 0
      };


    case actionTypes.USER_LOGIN_FAILURE:
      //console.log('USER_LOGIN_FAILURE: ', action.error)
      return { ...state, pending: false, error: action.error };

    // auth with providers

    /*case actionTypes.USER_LOGIN_WITH_PROVIDER_REQUEST:
      return action.payload;*/

    case actionTypes.USER_LOGIN_WITH_PROVIDER_SUCCESS:
      //console.log('userAuth: USER_LOGIN_WITH_PROVIDER_SUCCESS', action.payload.user)

      // Write ID to database if doesn't exist
      //console.log'userAuth: USER_LOGIN_PROVIDER_SUCCESS', action.payload)
      /*if (action.payload) {
        WriteUserDB(action.payload.user.uid);
      }*/

      var displayName = 'none';

      if (action.payload.displayName != null) displayName = action.payload.user.displayName;

      return {
        firebase: action.payload.user,
        email: action.payload.user.email,
        username: displayName,
        uid: action.payload.user.uid,
        isLoggedIn: true,
        type: 1,
        newsletter: 1,
        region: state['region'],
        join: 0,
        pending: true,
        passwordUpdate: false,
        filters: [common.defaultFilters],
        currentfilter: common.defaultFilters,
        currentfilterIndex: 0
      };

    
    case actionTypes.USER_LOGIN_WITH_PROVIDER_FAILURE:
      return { ...state, pending: false, error: action.error };
    // user logout
    /*case actionTypes.USER_LOGOUT_REQUEST:
      return { ...state };*/

    case actionTypes.USER_REGISTER_WITH_PROVIDER_FAILURE:
      return { ...state, pending: false, error: action.error };
    
    case actionTypes.USER_LOGOUT_SUCCESS:
      return { ...logoutState, region:state['region'] };

    case actionTypes.USER_LOGOUT_FAILURE:
      return { ...state, error: action.error };


    case actionTypes.USER_UPDATE_SUCCESS:
      if (action.payload) {
        /*//console.log(
          'userAuth: USER_UPDATE_SUCCESS payload',
          action.payload,
          state
        );*/
        if ((action.payload.old.newsletter != action.payload.new.newsletter) || (action.payload.old.accType != action.payload.new.accType)) {
          UpdateUserDB(action.payload)
        }

        return {
          firebase: state['firebase'],
          email: action.payload.new.email,
          username: state['username'],
          uid: state['uid'],
          isLoggedIn: state['isLoggedIn'],
          type: action.payload.new.accType,
          join: action.payload['joined'],
          newsletter: action.payload.new.newsletter,
          region: state['region'],
          pending: false,
          passwordUpdate: false,
          filters: state['filters'],
          currentfilter: state['currentfilter'],
          currentfilterIndex: state['currentfilterIndex']
        }
      };

    
    
    case actionTypes.USER_UPDATE_FAILURE:
      //console.log('userAuth.js: ', action);
      return { ...state, error: action.payload };

    case actionTypes.PASSWORD_UPDATE_FAILURE:
      //console.log('userAuth.js: ', action);
      state.passwordUpdate = false
      return {...state, error: action.payload };

    case actionTypes.PASSWORD_UPDATE_SUCCESS:
      //console.log('userAuth.js: PASSWORD_UPDATE_SUCCESS: ')
      return {...state, passwordUpdate: true}

    
    case actionTypes.USER_FILTER_UPDATE:
      //console.log('USER_FILTER_UPDATE: ', action.payload)
      var newcurrentfilter = state.currentfilter
      if (action.payload) {
        var newcurrentfilter = action.payload
        // Do age ratings matching
        newcurrentfilter.ageratings = common.ageRatingMatch(newcurrentfilter.ageratings)
        console.log('USER_FILTER_UPDATE: ',newcurrentfilter)
        var newfilters = state.filters
        newcurrentfilter.id = state.currentfilter.id
        
        newfilters[state['currentfilterIndex']] = newcurrentfilter
        
        return { ...state, filters: newfilters, currentfilter: newcurrentfilter}
      }
      else
        return state

    case actionTypes.USER_FILTER_SAVE:
      var newcurrentfilter = state.currentfilter
      if (action.payload) {
        var newcurrentfilter = action.payload
        var newfilters = state.filters
        newcurrentfilter.id = state.currentfilter.id
        newfilters[state['currentfilterIndex']] = newcurrentfilter
        UpdateFilterDB(newcurrentfilter)
      }

      //console.log('USER_FILTER_SAVE')
      return { ...state, filters: newfilters, currentfilter: newcurrentfilter}

  

    case actionTypes.USER_FILTER_SELECT:
      //console.log'USER_FILTER_SELECT REACHED')
      if (action.payload!=null ) {    
        //console.log'USER_FILTER_SELECT SET currentfilter')
        var currentfilterIndex = parseInt(action.payload)
        var newcurrentfilter = state.filters[currentfilterIndex]
        //console.log'USER_FILTER_SELECT SET currentfilter', currentfilterIndex, newcurrentfilter)    
        return { ...state, currentfilter: newcurrentfilter, currentfilterIndex:  currentfilterIndex}
      }
      else
        return state

    case actionTypes.USER_FILTER_DEL:
      //console.log'USER_FILTER_DEL: ', state.currentfilterIndex,  state.currentfilter )
      if ( (state.filters.length >1) && (state.currentfilterIndex!=0) ) {
        var newfilters = state.filters
        const filterDelID = state.currentfilter.id
        //console.log'USER_FILTER_DEL: DelID', filterDelID )
        var removed  = newfilters.splice(state.currentfilterIndex,1)
        var newcurrentfilterIndex = state.currentfilterIndex-1
        var newcurrentfilter = newfilters[newcurrentfilterIndex]
        DelFilterDB(filterDelID)
        //console.log'USER_FILTER_DEL: After DELETE', newfilters, newcurrentfilterIndex, newcurrentfilter )
        return { ...state, currentfilter: newcurrentfilter, currentfilterIndex:  newcurrentfilterIndex, filters:newfilters}
        
      }
      else
        return state
  
    
    /*case actionTypes.USER_FILTER_ADD:

      if(action.payload) {
        AddFilterDB(action.payload, state.uid)
        //console.log"USER_FILTER_ADD")
        return {...state, pending: true}
      }
      else
        return state*/
    

    case actionTypes.USER_FILTER_ADD_SUCCESS:  
      //console.log'USER_ADD_FILTER_SUCCESS IN userAuth: ', action.payload)
      var newfilters = state.filters
      var newcurrentfilter = action.payload

      if (newcurrentfilter['blockedwords']) {
        var newblockedwords = newcurrentfilter.blockedwords.split(',')
        //console.log'USER_ADD_FILTER_SUCCESS blockedwords: ', newblockedwords)
        newcurrentfilter.blockedwords = newblockedwords
      }
      else  
        newcurrentfilter.blockedwords = []
      if (newcurrentfilter['ageratings']) {
        var newageratings = newcurrentfilter.ageratings.split(',')
        newcurrentfilter.ageratings = newageratings
      }
      else
        newageratings = []

      if (newcurrentfilter['genres']) {
        var newgenres = newcurrentfilter.genres.split(',')
        newcurrentfilter.genres = newgenres
      }
      else
        newgenres = []
      
      var newcurrentfilterIndex = 0
      if ( (newfilters.length == 0) || (newcurrentfilter.filterShortName == 'Default')  ) {
        newfilters = [newcurrentfilter]
        newcurrentfilterIndex = 0
      }
      else {
        newfilters.push(newcurrentfilter)
        newcurrentfilterIndex = newfilters.length-1
      }

      //console.log'USER_ADD_FILTER_SUCCESS Final Filters: ', newcurrentfilter,newcurrentfilterIndex, newfilters)
      
      //return { ...state, currentfilter: newcurrentfilter, currentfilterIndex: newcurrentfilterIndex,  filters:newfilters, pending:false}
      return { ...state,  filters:newfilters, currentfilter: newcurrentfilter, currentfilterIndex: newcurrentfilterIndex, pending:false}

    case actionTypes.USER_FILTER_ADD_FAILURE:
      //console.log'USER_ADD_FILTER_FAILURE IN userAuth: ', action.payload)
      return { ...state, error: action.payload, pending: false};

  
    
    default:
      return state;
  }
};
