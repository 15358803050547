export const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

export const ERROR_MSG_ACCOUNT_EXISTS = 'The email address is already in use by another account';
export const ERROR_CODE_ACCOUNT_PROVIDER_EXISTS = 'auth/provider-email-already-in-use';
export const ERROR_MSG_ACCOUNT_PROVIDER_EXISTS = 'The Google email address is already in use by another account';
export const ERROR_CODE_PASSWORD_SHORT = 'auth/weak-password';
export const ERROR_MSG_PASSWORD_SHORT = 'Password should be at least 6 characters'
export const ERROR_CODE_ACCOUNT_RELOGIN = 'auth/requires-recent-login';
export const ERROR_MSG_ACCOUNT_RELOGIN = 'You need to login again to carry out this function';
export const ERROR_CODE_INVALID_EMAIL = 'auth/invalid-email';
export const ERROR_MSG_INVALID_EMAIL = 'The email address is invalid';

export const ERROR_CODE_USER_NOT_FOUND = 'auth/user-not-found';
export const ERROR_MSG_USER_NOT_FOUND = 'the user was not found';
export const ERROR_CODE_WRONG_PASSWORD = 'auth/wrong-password';
export const ERROR_MSG_WRONG_PASSWORD = 'Incorrect password';

