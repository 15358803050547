import { combineReducers } from 'redux';
import { userAuth as userReducer } from './userAuth';
import { movies_redux as moviesReducer } from './movies';
import { startup } from './startup';

import * as actionTypes from '../constants/action-types';

const rootReducer = combineReducers({
  currentUser: userReducer,
  startup,
  movies_redux: moviesReducer,
});

export default function root(state, action) {
  return rootReducer(state, action);
}
