import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';

import Slider from '@material-ui/core/Slider';

import { unstable_Box as Box } from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { isMobile } from 'react-device-detect';

import Button from '@material-ui/core/Button';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import './Feedback.css';
import * as common from '../../common.js';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import InfoIcon from '@material-ui/icons/Info';

import { Redirect } from 'react-router-dom';
import { FlexibleHeightXYPlot } from 'react-vis/dist/make-vis-flexible';
import ReactGA from 'react-ga';
import { green } from '@material-ui/core/colors';
import { fade } from '@material-ui/core/styles';

const styles = theme => ({
  toggle_button_root: {
    minWidth: 20,
    height: '40px',
    //color: 'black'
    color: fade(theme.palette.action.active, 0.50),
    "&$selected": {
      backgroundColor: '#333333'
    }

  },

  toggle_button_selected: {
    minWidth: 20,
    color: 'blue',
    backgroundColor: 'red',
    
  },


  toggle_container: {
    width: '80px',
    minWidth: '80px',
    height: '40px',
    marginBottom: '5px',
    marginTop: '5px',
    marginRight: '10px',
    backgroundColor: 'transparent',
   
  },

  lineColor: {
    color: 'black',
  },

  formControl: {
    marginLeft: theme.spacing.unit * 3,
  },

  slider: {
    padding: '22px 0px',
  },

  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});

const devices = [
  {
    value: 'NA',
    label: '',
  },
  {
    value: 'MP',
    label: 'Mobile Phone',
  },
  {
    value: 'TB',
    label: 'Tablet',
  },
  {
    value: 'PC',
    label: 'Desktop PC',
  },
  {
    value: 'LP',
    label: 'Laptop',
  },
];

const features = [
  'ALL: TV Series titles to be added',
  'ALL: Allow users to submit incorrectly labelled language markers and be rewarded',
  'ALL: Allow users to submit Sex/Nudity/Viloence markers for movies and be rewarded',
  'ALL: Provide Sex/Nudity/Violence scene markers for movies',
  "ALL: Users can see history of what they've selected as watched and build a favourites list",
  'ALL: Users can share favorite title lists with other users',
  'PLUS: Provide streaming Links to Netflix, Hulu, Amazon, AppleTV and Mubi',
  'PLUS: Allow users to customize offensive word list (can add own words and remove words)',
  'PLUS: Users will receive suggestions for next TV series and episode',
  'PRO: Live filtering of unwanted words and scenes on TV or streaming device from 3Wise',
];

const questions = [
  'Was 3Wise useful for choosing what to watch?',
  'Did you find the movie you were looking for?',
  'Is there anything about the site that is confusing/unclear?',
  'Did you experience any problems/bugs?',
  'Did you notice any incorrectly labelled words?',
];

class Feedback extends React.Component {
  state = {
    alignment: 'center',
    multiline: 'Controlled',
    open: false,
    openError: false,
    errorMsg: '',
    extraVisible: true,
    goHome: false,
    checkValues: [],
    email: '',
    device: '',
    confusing: '',
    problems: '',
    features: '',
    movies: '',
    compliments: '',
  };

  componentDidMount() {
    ReactGA.initialize('UA-140786114-1');
    ReactGA.pageview('/feedback');
  }

  componentWillMount() {
    var checkStates = [];
    for (var y in questions) {
      checkStates.push('U');
    }

    this.setState({ checkValues: checkStates });
  }

  handleClose = () => {
    this.props.OnDone();
    this.setState({ open: false, goHome: true });
  };

  handleCloseError = () => {
    this.setState({ openError: false });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
    //console.log('Feedback name change', event.target.value )
  };

  handleChangeCheck = index => (event, v) => {
    var newValues = this.state.checkValues;
    if (v != null && v != 'U') newValues[index] = v;

    //console.log ('Feedback hangleChangeCheck', v);

    this.setState({ checkValues: newValues });
    //console.log('Feedback checked:',this.state.checkValues)
  };

  errorDialog = msg => {
    return (
      <Dialog
        open={this.state.openError}
        onClose={this.handleCloseError}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {/*<InfoIcon style={{fontSize: '32px', padding: '10px'}}/>*/}
              {msg}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseError} color="primary" variant="contained" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  handleSubmit = event => {
    var i;
    var qLeft = false;

    var checkval = 0;
    /*for (i=0; i<questions.length; i++) {
        checkval = checkval + (this.state.checkValues[i]=='Y')*Math.pow(2,i)
      }*/
    for (i = 0; i < questions.length; i++) {
      checkval = checkval + (this.state.checkValues[i] == 'N') * Math.pow(3, i);
      checkval = checkval + (this.state.checkValues[i] == 'Y') * 2 * Math.pow(3, i);
    }
    /* Check if all questiones answered */
    /*for (i=0; i<questions.length; i++) {
      if (this.state.checkValues[i]=='U')
      qLeft = true;
    } */

    /*if (qLeft) {
      //console.log('Feedback: Question Left')
      this.setState({errorMsg: 'Please Select Yes or No to all feedback questions'});
      this.setState({openError: true});
    */

    /*//console.log(
      'Feedback: ',
      !checkval,
      this.state.email,
      (this.state.email = ''),
      this.state.email == undefined,
      this.state.email == null,
    );*/
    /* Check if something has been submitted */
    if (
      !checkval &&
      this.state.email == '' &&
      this.state.device == '' &&
      this.state.confusing == '' &&
      this.state.problems == '' &&
      this.state.features == '' &&
      this.state.movies == '' &&
      this.state.compliments == ''
    ) {
      this.setState({ errorMsg: 'Please complete at last one feedback item - thanks' });
      this.setState({ openError: true });
    } else if (this.state.confusing.length >= 3000 || this.state.movies.length >= 3000) {
      //console.log('Feedback: Too many characters');
      /*confusing: this.state.confusing,
      problems: this.state.problems,
      features: this.state.features,
      movies: this.state.movies,
      compliments: this.state.compliments */
      this.setState({ errorMsg: 'Please keep answers to under 3000 characters' });
      this.setState({ openError: true });
    } else {
      /* Calculate checks value */

      var datapack = {
        checks: checkval,
        email: this.state.email,
        device: this.state.device,
        confusing: this.state.confusing,
        problems: this.state.problems,
        features: this.state.features,
        movies: this.state.movies,
        compliments: this.state.compliments,
      };

      //console.log('Feedback: You did a submit')
      fetch(`${common.DJANGOURL}/api/feedback`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(datapack),
      })
        .then(function(response) {
          return response.json();
        })
        .then(function(data) {
          //console.log("Feedback: Data is ok", data);
        })
        .catch(function(ex) {
          //console.log("Feedback: parsing failed", ex);
        });
      //console.log('Feedback: Completed submit')
      this.setState({ open: true });
    }
  };

  extraDetail = (classes, show) => {
    if (show) {
      return (
        <fieldset className="optional">
          <legend> Additional Information </legend>

          <p style={{ marginTop: '5px', width: '100%' }}>
            Select the device you will mostly use to access 3Wise.
          </p>
          <TextField
            id="device"
            style={{ marginTop: '10px', marginLeft: '0px' }}
            select
            className={!isMobile ? 'textSelect' : 'textSelect_mobile'}
            value={this.state.device}
            onChange={this.handleChange('device')}
            SelectProps={{
              native: true,
              MenuProps: {
                className: classes.menu,
              },
            }}
            margin="normal"
          >
            {devices.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>

          <p style={{ marginTop: '40px', width: '100%' }}>
            {' '}
            Any specific Movies that you couldn't find
          </p>

          <TextField
            id="movies"
            label="Movies not found"
            onChange={this.handleChange('movies')}
            value={this.state.movies}
            multiline
            rows="5"
            className="textField"
            margin="none"
            variant="outlined"
          />

          <p style={{ marginTop: '20px', width: '100%' }}>
            {' '}
            Describe any aspects of the site that are confusing or unclear.
          </p>

          <TextField
            id="confusing"
            label="Confusing / unclear issues"
            onChange={this.handleChange('confusing')}
            value={this.state.confusing}
            multiline
            rows="5"
            className="textField"
            margin="none"
            variant="outlined"
          />

          <p style={{ marginTop: '20px', width: '100%' }}>
            {' '}
            Describe any bugs or problems you encountered.
          </p>
          <TextField
            id="problems"
            label="Problems / Bugs"
            onChange={this.handleChange('problems')}
            value={this.state.problems}
            multiline
            rows="5"
            className="textField"
            margin="none"
            variant="outlined"
          />

          <p style={{ marginTop: '40px', width: '100%' }}>
            {' '}
            Describe features or improvements you would like to see{' '}
          </p>
          <TextField
            id="features"
            label="Features and Improvements"
            onChange={this.handleChange('features')}
            value={this.state.features}
            multiline
            rows="5"
            className="textField"
            margin="nonel"
            variant="outlined"
          />

          <p style={{ marginTop: '40px', width: '100%' }}>Compliments are always welcome.</p>

          <TextField
            id="compliments"
            label="Compliments"
            onChange={this.handleChange('compliments')}
            value={this.state.compliments}
            multiline
            rows="4"
            className="textField"
            margin="none"
            variant="outlined"
          />
        </fieldset>
      );
    } else {
      return null;
    }
  };

  render() {
    const { classes } = this.props;
    const { sliderValue, alignment } = this.state;

    //console.log('Feedback: Started render')

    if (this.state.goHome) {
      return <Redirect to="/" />;
    }
    

    return (
      <div className="root">
         <Helmet>
       
          <title>Feedback</title>
          <meta name="author" content="David Lloyd johnson" />
          <link rel="canonical" href="https://www.3wise.io/feedback" />
    
        </Helmet> 
        <div className={!isMobile ? 'root_inner' : 'root_inner_mobile'}>
          <form className="container" noValidate autoComplete="off">
            <p style={{ width: '100%', marginTop: '10px' }}>
              {' '}
              Before you go, we would love to get your feedback
            </p>
            <p style={{ width: '100%', marginTop: '5px', color: 'grey' }}>
              {' '}
              <i>Complete one or more of the items below</i>
            </p>

            {/*<FormControl style={{marginTop: "10px", width: "100%" }} component="fieldset" className={classes.formControl}  autoFocus> 
          <FormGroup>
            {questions.map( (value, index) => (
             
             <FormControlLabel style={{marginTop: "-15px"}}
                control={
                  <Checkbox checked={this.state.checkValues[index]} onChange={this.handleChangeCheck(index)} value={index} />
                }
                label={value}
              />
            
            ))}
          </FormGroup>
              </FormControl> */}

            {questions.map((value, index) => (
              <div style={{ width: '100%' }}>
                <hr
                  style={{
                    border: 'none',
                    height: '1px',
                    color: '#888888' /* old IE */,
                    backgroundColor: '#888888',
                  }}
                />

                <div className="question_container">
                  <ToggleButtonGroup
                    
                    classes={{root: classes.toggle_container}}
                    value={this.state.checkValues[index]}
                    exclusive
                    onChange={this.handleChangeCheck(index)}
                  >
                    <ToggleButton  classes={{ root: classes.toggle_button_root}} value="N">
                      <b>N</b>
                    </ToggleButton>
                    <ToggleButton classes={{ root: classes.toggle_button_root }} value="U">
                    </ToggleButton>
                    <ToggleButton classes={{ root: classes.toggle_button_root }} value="Y">
                      <b>Y</b>
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <p>{value} </p>
                </div>
                {index == questions.length - 1 ? (
                  <hr
                    style={{
                      border: 'none',
                      height: '1px',
                      color: '#333' /* old IE */,
                      backgroundColor: '#333',
                    }}
                  />
                ) : null}
              </div>
            ))}

            <TextField
              id="standard-email"
              label="Email"
              className="textBox"
              value={this.state.email}
              onChange={this.handleChange('email')}
              margin="normal"
            />
            <p style={{ marginTop: '5px', width: '100%' }}>
              {' '}
              If you want to be contacted by 3Wise for news and updates{' '}
            </p>

            <div style={{ width: '100%', display: 'flex' }}>
              <DialogActions>
                <Button
                  style={{ margin: '0px', marginTop: '10px' }}
                  onClick={() => this.handleSubmit()}
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
              </DialogActions>
            </div>

            {this.extraDetail(classes, this.state.extraVisible)}

            <div style={{ width: '100%', display: 'flex' }}>
              <DialogActions>
                <Button
                  style={{ margin: '0px', marginTop: '10px' }}
                  onClick={() => this.handleSubmit()}
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
              </DialogActions>
            </div>
          </form>
        </div>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {/*<InfoIcon style={{fontSize: '32px', padding: '10px'}}/>*/}
                Your Feedback has been submitted Thank you!
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" variant="contained" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
        {this.errorDialog(this.state.errorMsg)}
      </div>
    );
  }
}

Feedback.propTypes = {
  classes: PropTypes.object.isRequired,
};

const WrapFeedback = withStyles(styles)(Feedback);
export default WrapFeedback;
