import React, { Component } from 'react';
import { Stage, Layer, Rect, Line, Group, Image } from 'react-konva';
import shortid from 'shortid';
import * as common from '../../common';
import useImage from 'use-image';

import { XYPlot, XAxis, YAxis, HorizontalGridLines, LineSeries } from 'react-vis';
import { timeFormat } from 'd3-time-format';
import '../../../node_modules/react-vis/dist/style.css';
import { isMobile } from 'react-device-detect';
import LoadingOverlay from 'react-loading-overlay';
import isEqual from "react-fast-compare";

import './TimeLine.css';

const ONE_DAY = 86400000; // milliseconds
const ONE_HOUR = 3600000; //
const ONE_MIN = 60000;
const ONE_SEC = 1000;

/* FIXME - Werid time thing */
const FIXTIME = -ONE_HOUR * 2;

/*
const FilmImage = (props) => {
  const [image] = useImage(common.BASEURL + '/img/icons/' + 'film2.svg');
  return <Image x={40} image={image} width={2000}  height={100} />;
};

function randomNumber() {
  return Math.random() * (40 - 0) + 0;
}

class URLImage extends React.Component {
  state = {
    image: null
  };
  componentDidMount() {
    this.loadImage();
  }
  componentDidUpdate(oldProps) {
    if (oldProps.src !== this.props.src) {
      this.loadImage();
    }
  }
  componentWillUnmount() {
    this.image.removeEventListener('load', this.handleLoad);
  }


  loadImage() {
    // save to "this" to remove "load" handler on unmount
    this.image = new window.Image();
    this.image.src = this.props.src;
    this.image.addEventListener('load', this.handleLoad);
  }
  handleLoad = () => {
    // after setState react-konva will update canvas and redraw the layer
    // because "image" property is changed
    this.setState({
      image: this.image
    });
    // if you keep same image object during source updates
    // you will have to update layer manually:
    // this.imageNode.getLayer().batchDraw();
  };
  render() {
    return (
      <Image
        x={this.props.x}
        y={this.props.y}
        image={this.state.image}
        ref={node => {
          this.imageNode = node;
        }}
      />
    );
  }
}
*/

class TimeLine extends Component {
  state = {
    tstampsSec: [],
  };

  CategoryLines = (category, runtime, tstamps, width, height) => {
    var lcolor = 'black';
    var scolor = 'grey';

    //console.log('Category Lines: Category: ' + category)
    if (category.includes('Blasphemy')) {
      //console.log('Category Lines: Case Blasphemy passed');
      lcolor = 'red';
      scolor = 'palered';
    }
    if (category.includes('Profanity')) {
      //console.log('Category Lines: Profanity');
      lcolor = 'black';
      scolor = 'gray';
    }
    if (category.includes('Sexual')) {
      lcolor = 'blue';
      scolor = 'aqua';
    }
    if (category.includes('Racial')) {
      lcolor = 'green';
      scolor = 'lightgreen';
    }

    if (category.includes('CurrentTime')) {
      lcolor = 'green';
      scolor = 'lightgreen';
    }

    //console.log('Category Lines: ', category, tstamps, runtime);

    return (
      <Group
        x={parseInt((this.props.width * 2) / 100)}
        y={parseInt((this.props.height * 10) / 100)}
        width={parseInt((this.props.width * 96) / 100)}
        height={parseInt((this.props.height * 80) / 100)}
      >
        {tstamps.map(val => (
          <Line
            x={0}
            y={0}
            points={[
              parseInt(((val / runtime) * width * 96) / 100) + 40,
              10,
              parseInt(((val / runtime) * width * 96) / 100) + 40,
              (height * 70) / 100,
            ]}
            stroke={lcolor}
            //shadowColor = {lcolor}
            strokeWidth={2}
            //shadowOpacity = {0.5}
            //shadowBlur = {10}
            key={shortid.generate()}
          />
        ))}
      </Group>
    );
  };

  ExtractTimeStamps = words_c => {
    var times = [];
    var hrs, min, sec, totsec;
    Object.keys(words_c).map(key => {
      if (key !== 'count')
        Object.keys(words_c[key])
          .filter(item => item !== 'count')
          .filter(item => item !== 'exclude')
          .map(key2 =>
            //console.log(words_c[key][key2])
            words_c[key][key2].map(t => {
              hrs = Number(t['line'].slice(0, 2));
              min = Number(t['line'].slice(3, 5));
              sec = Number(t['line'].slice(6, 8));

              totsec = hrs * 3600 + min * 60 + sec;
              times.push(totsec);
            }),
          );
    });
    //console.log('TimeLine: ', times);
    return times;
  };

  DrawLines = (category, runtime, words, width, height) => {
    var w;
    var rthrs = Number(runtime.slice(0, 2));
    var rtmin = Number(runtime.slice(3, 5));
    var rtsec = Number(runtime.slice(6, 8));
    var rttotsec = rthrs * 3600 + rtmin * 60 + rtsec;

    // correct with width for margin FIXME: Make this dynamic with variable
    width = width - 80;

    for (w in words) {
      if (w.includes(category)) {
        var tstamps = this.ExtractTimeStamps(words[w]);

        //console.log(category)
        //console.log(words[category])
        //const tstamps = [60,120,360,1000,3500]
        return this.CategoryLines(w, rttotsec, tstamps, width, height);
      }
    }
  };

  DrawTime = (runtime, time, width, height) => {
    var rthrs = Number(runtime.slice(0, 2));
    var rtmin = Number(runtime.slice(3, 5));
    var rtsec = Number(runtime.slice(6, 8));
    var rttotsec = rthrs * 3600 + rtmin * 60 + rtsec;

    return this.CategoryLines('CurrentTime', rttotsec, [time], width, height);
  };

  

  render() {
    ///console.log(parseInt(this.props.width*96/100));
    //console.log('TIMELINE: START')
    const { width, height, runtime, words, timelinePos } = this.props;
    //console.log('Timeline: runtime,words: ', runtime, words)
    const data = [
      {
        color: 'steelblue',
        //points: [{x: '00:00:00', y: 1}, {x: '00:05:00', y: 2}, {x: '00:10:00', y: 3}]
        points: [
          { x: 0, y: 0 },
          { x: 3, y: 0 },
          { x: 8, y: 0 },
        ],
      },
    ];

    var dateFormatter = timeFormat('%H:%M:%S');

    var rthrs = Number(runtime.slice(0, 2));
    var rtmin = Number(runtime.slice(3, 5));
    var rtsec = Number(runtime.slice(6, 8));
    var rttotsec = rthrs * 3600 + rtmin * 60 + rtsec;

    var timeaxisData = [
      { x: 0 + FIXTIME, y: 0 },
      { x: rttotsec * 1000 + FIXTIME, y: 0 },
    ];
    //console.log('timeline: time data: ',timeaxisData)
    var ticksTimeline = 2;

    if (isMobile) {
      ticksTimeline = 2;
    } else {
      ticksTimeline = 6;
    }

    const mgns = { left: 30, right: 20, top: 10, bottom: 40 };
    //console.log("TIMELINE: runtime", this.props.runtime);
    //console.log("TIMELINE: words", this.props.words);
    return (
      <div className="timeline-stage">
        <Stage width={this.props.width} height={this.props.height}>
          <Layer opacity={1}>
            {/*this.DrawTime(
              this.props.runtime,
              this.props.timelinePos,
              parseInt(this.props.width),
              parseInt(this.props.height)
            )*/}

            {this.DrawLines(
              'Blasphemy',
              this.props.runtime,
              this.props.words,
              parseInt(this.props.width),
              parseInt(this.props.height),
            )}

            {this.DrawLines(
              'Profanity',
              this.props.runtime,
              this.props.words,
              parseInt(this.props.width),
              parseInt(this.props.height),
            )}

            {this.DrawLines(
              'Sexual',
              this.props.runtime,
              this.props.words,
              parseInt(this.props.width),
              parseInt(this.props.height),
            )}

            {this.DrawLines(
              'Racial',
              this.props.runtime,
              this.props.words,
              parseInt(this.props.width),
              parseInt(this.props.height),
            )}
          </Layer>
        </Stage>
        <XYPlot width={this.props.width + 20} height={50} xType="time-utc" margin={mgns}>
          <HorizontalGridLines />
          <LineSeries data={timeaxisData} />
          <XAxis
            tickFormat={d => dateFormatter(d)}
            tickTotal={ticksTimeline}
            tickSizeOuter={6}
            tickSizeInner={3}
            style={{
              line: { stroke: '#222222' },
              ticks: { stroke: '#222222' },
              text: { stroke: 'none', fill: '#6b6b76', fontWeight: 600 },
            }}
          />
        </XYPlot>
      </div>
    );
  }
}

export default TimeLine;
