import React, { Component } from 'react';
import sizeMe from 'react-sizeme';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { Text } from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import Collapse from '@material-ui/core/Collapse';
import SendIcon from '@material-ui/icons/Send';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';

import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import Checkbox from '@material-ui/core/Checkbox';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CircleChecked from '@material-ui/icons/CheckCircle';

import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';

import PropTypes from 'prop-types';
import TimeLine from './TimeLine';

import View from 'react';

import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import SvgIcon from '@material-ui/core/SvgIcon';
import InboxIcon from '@material-ui/icons/Inbox';
import { ScaleLoader } from 'react-spinners';

import LoadingOverlay from 'react-loading-overlay';

import { connect } from 'react-redux';
import * as common from '../../common';

import owlRecommended from '../../assets/3wise_logo_nologo.png';
import isEqual from "react-fast-compare";

const styles2 = theme => ({
  spacing: 8,

  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },

  bigBlue: {
    color: 'blue',
    fontWeight: 'bold',
    fontSize: 30,
  },

  listtext1: {
    color: 'black',
    marginLeft: '-35px'
  },

  listtext2: {
    color: 'black',
    marginLeft: '-90px'
  },

  listtext3: {
    color: 'black',
    marginLeft: '-45px'
  },


  nested1: {
    selected: {
      color: 'black',
    },
  },

  nested2: {
    color: 'black',
    paddingLeft: theme.spacing.unit * 5,
  },

  nested3: {
    color: 'black',
    paddingLeft: theme.spacing.unit * 6,
  },

  button: {
    margin: theme.spacing.unit,
  },

  icon1: {
    color: 'black',
    transform: 'scale(1.5)',
    marginLeft: '10px',

  },

  icon2: {
    color: 'black',
    transform: 'scale(1.5)',
    marginLeft: '20px',
  },

  dialogText: {
    color: 'black',
    margin: 15,
  },

  blockedWords: {
    display: 'flex',
  },
});

function ArrowIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16 16-7.163 16-16-7.163-16-16-16zM16 29c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13z"></path>
      <path d="M11.086 22.086l2.829 2.829 8.914-8.914-8.914-8.914-2.828 2.828 6.086 6.086z"></path>
    </SvgIcon>
  );
}

class LineCollapse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedA: true,
    };
  }

  // Checkbox tick untick
  handleChange = (k1, k2, k3) => event => {
    this.setState({ checkedA: event.target.checked });
    this.props.LModifyWords(k1, k2, k3, event.target.checked);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.LParentChecked != this.props.LParentChecked) {
      //console.log'LineCollapse: componentWillReceiveProps: ', nextProps.Lkey, nextProps.LParentChecked )
      this.setState({ checkedA: nextProps.LParentChecked });
    }
  }

  componentDidMount() {
    //console.log('Lines: ', this.props)
    //const value = this.props.Lvalue;
    const key = this.props.CWkey;
    const key2 = this.props.Wkey;
    const key3 = this.props.Lkey;
    //const checked=!value[key][key2]['subs'][key3]['exclude']
    const checked = !this.props.LExclude
    this.setState({ checkedA: checked })
  }


  render() {
    const classes = this.props.Lclasses;
    //const value = this.props.Lvalue;
    const time = this.props.LTime
    const line = this.props.LLine
    const key = this.props.CWkey;
    const key2 = this.props.Wkey;
    const key3 = this.props.Lkey;
    //console.log('LineCollapse: ',value,key,key2,key3)

    var checkColor = 'black';

    if (key.includes('Blasphemy')) checkColor = 'red';
    if (key.includes('Profanity')) checkColor = 'black';
    if (key.includes('Sexual')) checkColor = 'blue';
    if (key.includes('Racial')) checkColor = 'green';

    return (
      <div>
        <ListItem
          button={true}
          className={classes.nested3}
          key={time}
        >
          <Checkbox
            checked={this.state.checkedA}
            icon={<CircleUnchecked />}
            checkedIcon={<CircleChecked />}
            onChange={this.handleChange(key, key2, key3)}
            value={key3}
            style={{
              color: checkColor,
              margin: '-10px',
            }}
            //labelStyle={{color: 'white'}}
            //iconStyle={{fill: 'white'}}

            onClick={e => {
              e.stopPropagation();
              return 0;
              // ... checkbox logic ...
            }}
          />
          <ListItemText
            inset
            classes={{ primary: classes.listtext3 }}
            primary={line}
          />
        </ListItem>
      </div>
    );
  }
}

const LineCollapseWrapped = withStyles(styles2)(LineCollapse);

class WordCollapse extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      open: false,
      checkedA: true,
    };
  }

  toggle() {
    this.setState({ open: !this.state.open });
  }

  // Checkbox tick untick
  handleChange = (k1, k2, k3) => event => {
    this.setState({ checkedA: event.target.checked });
    this.props.WModifyWords(k1, k2, k3, event.target.checked);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.WParentChecked != this.props.WParentChecked) {
      //console.log('WordCollapse: componentWillReceiveProps: ', nextProps.Wkey, nextProps.WParentChecked )
      this.setState({ checkedA: nextProps.WParentChecked });
    }
  }

  componentDidMount() {
    //console.log ('WordCollapse: ', this.props)
    const checked = !this.props.WExclude
    this.setState({ checkedA: checked })
  }

  /*componentDidMount() {
        //console.log('WordCollapse: componentDidMount: ', this.props.Wkey, this.state.checkedA)
    } */

  render() {
    var classes = this.props.Wclasses;
    var value = this.props.Wvalue;
    var key = this.props.CWkey;
    var key2 = this.props.Wkey;
    var index = this.props.Windex;

    var checkColor = 'black';

    if (key.includes('Blasphemy')) checkColor = 'red';
    if (key.includes('Profanity')) checkColor = 'black';
    if (key.includes('Sexual')) checkColor = 'blue';
    if (key.includes('Racial')) checkColor = 'green';

    return (
      <div>
        <ListItem
          button={true}
          onClick={this.toggle}
          className={classes.nested2}
          key={'WC_LI' + index}
          hideChevron={true}
        >
          <Checkbox
            checked={this.state.checkedA}
            icon={<CircleUnchecked />}
            checkedIcon={<CircleChecked />}
            onChange={this.handleChange(key, key2, null)}
            value={key2}
            style={{
              color: checkColor,
              margin: '-20px',
            }}
            //labelStyle={{color: 'white'}}
            //iconStyle={{fill: 'white'}}

            onClick={e => {
              e.stopPropagation();
              return 0;
              // ... checkbox logic ...
            }}
          />
          <ListItemIcon
            key={'WC_LII' + index}
            className={classes.icon2}
          >
            <ArrowRightIcon />
          </ListItemIcon>
          <ListItemText
            inset
            classes={{ primary: classes.listtext2 }}
            primary={key2}
            key={'WC_LIT' + index}
          />
          {this.state.open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={this.state.open} timeout="auto">
          {Object.keys(value[key][key2])
            .filter(item => item != 'count')
            .filter(item => item != 'exclude')
            .map(key3 =>
              Object.keys(value[key][key2][key3]).map(key4 => (
                <LineCollapseWrapped
                  LModifyWords={this.props.WModifyWords}
                  Lclasses={classes}
                  LTime={value[key][key2]['subs'][key4]['time']}
                  LLine={value[key][key2]['subs'][key4]['line']}
                  LExclude={value[key][key2]['subs'][key4]['exclude']}
                  LParentChecked={this.state.checkedA}
                  CWkey={key}
                  Wkey={key2}
                  Lkey={key4}
                />
              )),
            )}
        </Collapse>
      </div>
    );
  }
}

const WordCollapseWrapped = withStyles(styles2)(WordCollapse);

class CategoryCollapse extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { open: false, checkedA: true };
  }

  // Toggle the collapsed list
  toggle() {
    this.setState({ open: !this.state.open });
  }


  // Checkbox tick untick
  handleChange = name => event => {
    this.setState({ checkedA: event.target.checked });
    this.props.CModifyWords(name, null, null, event.target.checked);
  };

  componentDidMount() {
    //console.log ('CategoryCollapse: ', this.props)
    const checked = !this.props.CExclude
    this.setState({ checkedA: checked })
  }


  render() {
    var classes = this.props.Cclasses;
    var value = this.props.Cvalue;
    var key = this.props.Ckey;
    var index = this.props.Cindex;
    var checkColor = 'black';


    if (key.includes('Blasphemy')) checkColor = 'red';
    if (key.includes('Profanity')) checkColor = 'black';
    if (key.includes('Sexual')) checkColor = 'blue';
    if (key.includes('Racial')) checkColor = 'green';
    //console.log('COLOR: ', checkColor)

    return (
      <div>
        <ListItem
          button={true}
          key={'C_LI' + index}
          onClick={this.toggle}
          className={classes.nested1}
        >
          <Checkbox
            checked={this.state.checkedA}
            icon={<CircleUnchecked />}
            checkedIcon={<CircleChecked />}
            onChange={this.handleChange(key)}
            value={key}
            style={{
              color: checkColor,
              margin: '-10px',
            }}
            //labelStyle={{color: 'white'}}
            //iconStyle={{fill: 'white'}}

            onClick={e => {
              e.stopPropagation();
              return 0;
              // ... checkbox logic ...
            }}
          />

          <ListItemIcon
            button={true}
            key={'C_LII' + index}
            className={classes.icon1}
          >
            <ArrowRightIcon
            />
          </ListItemIcon>
          {/*DAVID*/}
          <ListItemText
            classes={{ primary: classes.listtext1 }}
            primary={key}
            key={'C_LIT' + index}
          />
          {this.state.open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state.open} timeout="auto">
          <List component="div" disablePadding>
            {Object.keys(value[key])
              .filter(item => item != 'count')
              .filter(item => item != 'exclude')
              .map((key2, index2) => (
                <WordCollapseWrapped
                  WModifyWords={this.props.CModifyWords}
                  Wclasses={classes}
                  Wvalue={value}
                  WParentChecked={this.state.checkedA}
                  WExclude={value[key][key2]['exclude']}
                  CWkey={key}
                  Wkey={key2}
                  Windex={index2}
                />
              ))}
          </List>
        </Collapse>
      </div>
    );
  }
}

const CategoryCollapseWrapped = withStyles(styles2)(CategoryCollapse);

class MovieSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: {},
      words: {},
      Twords: {},
      Cwidth: 0,
      loading: true,
    };
  }

  isEmpty = obj => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  InitializeTimeLineKey = (EWords) => {

    var newState = JSON.parse(JSON.stringify(EWords))

    //console.log('MovieSummary: InitializeTimeLineKey: ', newState, Object.keys(newState))
    Object.keys(newState).map(category => {
      //console.log('MovieSummary: InitializeTimeLineKey: category: ', newState[category])
      Object.keys(newState[category]).map(word => {
        //console.log('MovieSummary: InitializeTimeLineKey: word: ',newState[category][word]['subs'])
        var wordA = newState[category][word]['subs']
        for (var i = wordA.length - 1; i >= 0; i--) {
          if (wordA[i]['exclude'])
            wordA.splice(i, 1)
        }
        /*newState[category][word]['subs'].map((phrase,index,copyA) => {
          if (phrase['exclude']) {
            //console.log('Moviesummary: found exclude: ',index)
            //copyA[category][word]['subs'].splice(index,1)
            delete newState[category][word]['subs'][index];
          }
        })*/
      })
    })
    return newState
  }

  CheckParents = (Wwords, Twords) => {

    var newState = JSON.parse(JSON.stringify(Wwords))

    console.log ('CheckParents before: ', newState, Twords)
    Object.keys(Twords).map(category => {
      //console.log('MovieSummary: InitializeTimeLineKey: category: ', newState[category])
      var categoryExclude = true
      Object.keys(Twords[category]).map(word => {
        //console.log'CheckParents: subs: ',Twords[category][word]['subs'], Twords[category][word]['subs'].length)
        if (Twords[category][word]['subs'].length == 0)
          newState[category][word]['exclude'] = 1
        else {
          newState[category][word]['exclude'] = 0
          categoryExclude = false
        }
      })
      if (categoryExclude)
        newState[category]['exclude'] = 1
      else
        newState[category]['exclude'] = 0
    })
    console.log ('CheckParents after: ',newState)

    return newState
  }


  ToggleTimeLineKey = (key, key2, key3, checked) => {
    //console.log('KEY: ToggleTimeLineKey', key,key2,key3,checked)
    let newState = this.state.Twords;
    if (key && !key2 && !key3) {
      //console.log('Layer 1 ADD/DELETE KEY', key,  checked)
      if (checked) {
        //console.log('Add key')
        newState[key] = JSON.parse(JSON.stringify(this.state.words[key]));
      } else {
        //console.log('Remove key')
        delete newState[key];
      }
    }
    if (key && key2 && !key3) {
      //console.log('Layer 2 ADD/DELETE KEY', key,  checked)
      if (checked) {
        if (!(key in newState)) {
          newState[key] = {};
        }
        newState[key][key2] = JSON.parse(JSON.stringify(this.state.words[key][key2]));
      } else {
        //console.log('Remove key,key2', key,key2)
        delete newState[key][key2];
      }
    }

    if (key && key2 && key3) {
      //console.log('Layer 3 ADD/DELETE KEY', key,  checked)
      //console.log('words state: ', this.state.words)
      if (checked) {
        if (!(key in newState)) {
          //console.log('Adding key', key)
          newState[key] = {};
        }
        if (!(key2 in newState[key])) {
          //console.log('Adding key2', key2)
          newState[key][key2] = { subs: [] };
        }
        //console.log('Add key,key2,key3', key,key2,key3);
        //newState[key][key2]['subs'].push(JSON.parse(JSON.stringify(this.state.words[key][key2]['subs'][key3])) );
        newState[key][key2]['subs'][key3] = JSON.parse(
          JSON.stringify(this.state.words[key][key2]['subs'][key3]),
        );
      } else {
        //console.log('Remove key,key2,key3', key,key2,key3);
        //console.log('key3 VAL: ', newState[key][key2]['subs'][key3])
        delete newState[key][key2]['subs'][key3];
      }
    }


    this.setState({ Twords: newState });
    this.props.OnChange(newState);
  };





  componentWillReceiveProps(nextProps) {
    //console.log'MovieSummary_nodialog Receive Props: ', nextProps);

    if (!isEqual(nextProps.words, this.props.words)) {

      //console.log'MovieSummary_nodialog Receive Props changed:')
      var Twords = this.InitializeTimeLineKey(nextProps.words)
      var Wwords = this.CheckParents(nextProps.words, Twords)
      

      this.setState({
        words: Wwords,
        Twords: Twords
      });

      /*this.setState({
        words: nextProps.words,
        Twords: JSON.parse(JSON.stringify(nextProps.words)),

      });
      //console.log'MovieSummary - Twords:', this.state.Twords, this.state.words)
      this.InitializeTimeLineKey()*/
    }
  }

  componentDidMount() {
    //console.log'MovieSummary_nodialog Did Mount: ', this.props);

    // Configure TWords with exclusions
    var Twords = this.InitializeTimeLineKey(this.props.words)
    var Wwords = this.CheckParents(this.props.words, Twords)

    this.setState({
      words: Wwords,
      Twords: Twords
    });

    this.props.OnRendered();

  }



  shouldComponentUpdate(nextProps, nextState) {
    if (!isEqual(nextProps.Twords, this.state.Twords) || !isEqual(nextProps.words, this.props.words))
      return true;
  }


  /*shouldComponentUpdate(nextProps, nextState) {
        //console.log("Should component update")
        if (this.state.subwords == '') {
            this.setState(state => ({ subwords: this.props.words}))
            return true;
        }

        if (this.state != this.nextstate)
            return true;
        
        return false;
        }*/

  handleClick = key => {
    this.setState(state => ({ open: !state.open }));
  };

  renderBlockWords = (value, Cwidth) => {
    const { classes } = this.props;
    var runtime = this.props.runtime;
    //console.log'MovieSummary: renderBlockWords:', value, this.state.Twords);

    /*for (var key in value){
            //console.log('renderBlockWords: ', key, value[key] );
        }

        Object.keys(this.state.words).map((key, index) => (
            //console.log(key, index)
        ))*/
    if (!runtime || runtime == "00:00:00")
      runtime = "02:00:00"
    //console.log('Runtime: ', runtime)

    if (Object.keys(value).length == 0) {
      return (
        <div className="recommended">
          {/*<img
            style={{ maxWidth: '150px', maxHeight: '150px', margin: '30px' }}
            src={owlRecommended}
            alt="loading..."
          />*/}

          <p style={{ color: 'black' }}>
            No swearing detected in this title.
          </p>
        </div>
      );
    }
    //console.log("Twords = ", this.state.Twords);
    //console.log("runtime = ", this.props.runtime);
    //console.log('START Vals: ', Cwidth, this.props.runtime,this.state.Twords)
    else //console.log('MovieSummary: else condition');
      return (
        <div>


          <div>
            
            <TimeLine
              width={Cwidth}
              height={100}
              runtime={runtime}
              words={this.state.Twords}
            />
        

          </div>


          <List>
            {Object.keys(value).map((key, index) => (
              <CategoryCollapseWrapped
                CModifyWords={this.ToggleTimeLineKey}
                Cclasses={classes}
                Cvalue={value}
                CExclude={value[key]['exclude']}
                Ckey={key}
                Cindex={index}
              />
            ))}
          </List>
        </div>
      );
  };



  render() {
    const { classes, words, title, ...other } = this.props;
    //console.log'MovieSummary: render');

    //console.log('In Movie Summary')
    //console.log('Title: ', this.props.title)
    //console.log('Runtime:', this.props.runtime)
    //console.log('Words:', JSON.stringify(this.props.words))
    //console.log('TWords:', this.state.Twords)
    //console.log('WWords:', this.state.words)

    /* FIXME: Check this.props.size - should autoscale */

    /* Extracted from a props.size data structure */
    const { width } = this.props.size;

    //console.log('MovieSummary: props,width: ',this.props,width)

    return <div>{this.renderBlockWords(this.state.words, width)}</div>;
  }
}


MovieSummary.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
  };
}

const MovieSummaryWrapped = withStyles(styles2)(MovieSummary);

export default connect(mapStateToProps)(sizeMe()(MovieSummaryWrapped));
