/***********************************************************************
 *
 * DESCRIPTION :
 *   Firebase settings made here
 *
 * PUBLIC FUNCTIONS :
 *
 * NOTES :
 *   Import this where firebase settings are needed
 *
 * AUTHOR(S) :
 *   D.L. Johnson    START DATE : 1 Jan 2019
 *
 * Copyright 3Wise 2019. All rights reserved.
 ***********************************************************************/

module.exports = {
  // Change this to your firebase configuration! (Add Firebase to your web app)
  FIREBASE_CONFIG: {
    apiKey: 'AIzaSyA0muxVEs-mim9wMeXgZ94ZcbXau55kkEc',
    authDomain: 'wise-d3c6c.firebaseapp.com',
    databaseURL: 'https://wise-d3c6c.firebaseio.com',
    projectId: 'wise-d3c6c',
    storageBucket: 'wise-d3c6c.appspot.com',
    messagingSenderId: '349509303580',
  },
};
