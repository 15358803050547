import { STARTUP_REQUEST, STARTUP_SUCCESS, STARTUP_FAILURE, SCREEN_RESIZE } from '../constants/action-types';

export function startupRequest() {
  return {
    type: STARTUP_REQUEST,
  };
}

export function startupSuccess(status) {
  return {
    type: STARTUP_SUCCESS,
    status,
  };
}

export function startupFailure(error) {
  return {
    type: STARTUP_FAILURE,
    error,
  };
}

export function screenResize(data) {
  return {
    type: SCREEN_RESIZE,
    payload: data
  }
}

