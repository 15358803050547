/***********************************************************************
 *
 * DESCRIPTION :
 *   Common URL configurations settings for location of React, Django Mute devie
 *
 * PUBLIC FUNCTIONS :
 *
 * NOTES :
 *   Import this in all files needing common URLs
 *
 * AUTHOR(S) :
 *   D.L. Johnson    START DATE : 1 Jan 2019
 *
 * Copyright 3Wise 2019. All rights reserved.
 ***********************************************************************/

//export const BASEURL = 'http://10.5.10.5'
export const BASEURL= "https://3wise.io"
//export const BASEURL="http://localhost"
//export const BASEURL="http://test.3wise.io"
//export const BASEURL="http://10.1.2.130"
//export const BASEURL="http://ec2-3-95-149-6.compute-1.amazonaws.com"
//export const BASEURL="http://192.168.43.234"

//export const DJANGOURL="https://db.3wise.io"
//export const DJANGOURL = 'http://10.5.10.5:8000'
export const DJANGOURL="https://ec2.3wise.io"
//export const DJANGOURL="http://localhost:8000"
//export const DJANGOURL="http://10.1.2.130:8000"
//export const DJANGOURL="http://ec2.3wise.io:8000"
//export const DJANGOURL="http://test.3wise.io:8001"
//export const DJANGOURL="http://192.168.43.234:8000"


export const mutetv = 'http://localhost:9876/send?deviceMac=34ea34e3d318&codeName=mute';
export const playtv = 'http://localhost:9876/send?deviceMac=34ea34e3d318&codeName=play';
export const keepalive = 'http://localhost:9876/send';

export const mutepc = 'http://localhost:8002/volume?mute=1';
export const unmutepc = 'http://localhost:8002/volume?mute=0';

export const version = '1.0.0'
export const maxtitles = 1000
export const searchtitlesblock = 30
export const searchtitlesadd = 30
export const maxScreenWidth = 1140

 
//Netflix window in months
export const netflixWindow = 5

export const genreMapping = {
    'Action & Adventure': ['Action', 'Adventure'],
    'Animation': ['Animation'],
    'Comedy': ['Comedy'],
    'Crime': ['Crime'],
    'Documentary & Biography': ['Documentary', 'Biography'],
    'Drama': ['Drama'],
    'Fantasy': ['Fantasy'],
    'History': ['History'],
    'Horror': ['Horror'],
    'Kids & Family': ['Family'],
    'Music & Musical': ['Music', 'Musical'],
    'Mystery & Thriller': ['Mystery', 'Thriller'],
    'Romance': ['Romance'],
    'Science-Fiction': ['Sci-Fi'],
    'Sport': ['Sport'],
    'War & Military': ['War'],
    'Western': ['Western'],
    'Reality TV': ['Reality-TV']
}

export const ageRatings = {
    us: ['G', 'PG', 'PG-13', 'R', 'NC-17', 'Unrated']
}

export const ageRatingsTV = {
    us: ['TV-Y', 'TV-Y7', 'TV-G', 'TV-PG', 'TV-14', 'TV-MA', 'Unrated']
}

export const ageRatingsSearch = {
    us: ['G', 'PG', 'PG-13', 'R', 'NC-17','TV-Y', 'TV-Y7', 'TV-G', 'TV-PG', 'TV-14', 'TV-MA', 'Unrated']
}

export const region = 'us'

export const NetflixRegions = [
    { code: 'ar', country: 'Argentina' },
    { code: 'au', country: 'Australia' },
    { code: 'be', country: 'Belgium' },
    { code: 'br', country: 'Brazil' },
    { code: 'ca', country: 'Canada' },
    { code: 'cz', country: 'Czech Republic' },
    { code: 'fr', country: 'France' },
    { code: 'de', country: 'Germany' },
    { code: 'gr', country: 'Greece' },
    { code: 'hk', country: 'Hong Kong' },
    { code: 'hu', country: 'Hungary' },
    { code: 'is', country: 'Iceland' },
    { code: 'in', country: 'India' },
    { code: 'il', country: 'Israel' },
    { code: 'it', country: 'Italy' },
    { code: 'jp', country: 'Japan' },
    { code: 'lt', country: 'Lithuania' },
    { code: 'mx', country: 'Mexico' },
    { code: 'nl', country: 'Netherlands' },
    { code: 'pl', country: 'Poland' },
    { code: 'pt', country: 'Portugal' },
    { code: 'ro', country: 'Romania' },
    { code: 'ru', country: 'Russia' },
    { code: 'sg', country: 'Singapore' },
    { code: 'sk', country: 'Slovakia' },
    { code: 'za', country: 'South Africa' },
    { code: 'kr', country: 'South Korea' },
    { code: 'es', country: 'Spain' },
    { code: 'se', country: 'Sweden' },
    { code: 'ch', country: 'Switzerland' },
    { code: 'th', country: 'Thailand' },
    { code: 'tr', country: 'Turkey' },
    { code: 'gb', country: 'United Kingdom' },
    { code: 'us', country: 'United States' }
]

export const defaultFilters = {
    filterShortName: 'Default',
    filterLongName: 'Default',
    blasphemy: 5,
    profanity: 5,
    sexual: 5,
    racial: 5,
    rating: 0,
    startyear: 1980,
    endyear: 2023,
    blockedwords: [],
    ageratings: ['G','PG','PG-13','TV-Y', 'TV-Y7', 'TV-G', 'TV-PG', 'TV-14'],
    ageratingsTV: ['TV-Y', 'TV-Y7', 'TV-G', 'TV-PG', 'TV-14'],
    ageratingsSearch: ['G','PG','PG-13','TV-Y', 'TV-Y7', 'TV-G', 'TV-PG', 'TV-14'],
    excludeBlasphemy: false,
    excludeProfanity: false,
    excludeSexual: false,
    excludeRacial: false,
    netflix: false,
    genres: ['Kids & Family','Action & Adventure','Comedy','Romance'],
    original_language: 'en',
    locklanguage: true,
    showMovies: true,
    christian: false,
  }

  export const ageRatingMatch = (ageratings) => {
  
    var extras = []
  
    if (ageratings.includes('G'))
      if (!ageratings.includes('TV-G'))
        extras.push('TV-G')
    if (ageratings.includes('PG')) {
      if (!ageratings.includes('TV-PG'))
        extras.push('TV-PG')
      if (!ageratings.includes('TV-Y'))
        extras.push('TV-Y')
      if (!ageratings.includes('TV-Y7'))
        extras.push('TV-Y7')
    }
    if (ageratings.includes('PG-13')) {
      if (!ageratings.includes('TV-14'))
        extras.push('TV-14')
    }
    if ((ageratings.includes('R')) || (ageratings.includes('NC-17')) ) {
      if (!ageratings.includes('TV-MA')) 
        extras.push('TV-MA')
    }
  
    if (ageratings.includes('TV-G'))
      if (!ageratings.includes('G')) 
        extras.push('G')
    if ((ageratings.includes('TV-PG')) || (ageratings.includes('TV-Y')) ||
    (ageratings.includes('TV-Y7'))) {
      if (!ageratings.includes('PG')) 
        extras.push('PG')
    }
    if (ageratings.includes('TV-14')) {
      if (!ageratings.includes('PG-13')) 
        extras.push('PG-13')
    }
    if (ageratings.includes('TV-MA')) {
      if (!ageratings.includes('R'))
        extras.push('R')
      if (!ageratings.includes('NC-17'))
        extras.push('NC-17')
    }
    
    var newageratings = ageratings.concat(extras)
    //console.log ('userAuth: newageratings: ', newageratings)
    return newageratings
    
  }