import React, { Component } from 'react';
import WiseFooter from '../Browser/Footer';
import * as common from '../../common.js';
import MovieSummaryWrapped from '../../components/Widgets/MovieSummary_nodialog.js';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { closeTVInfo, addBookmarkTitle, delBookmarkTitle, setTVID } from '../../redux/actions/index';
import { connect } from 'react-redux';
import sizeMe from 'react-sizeme';

import { isMobile } from 'react-device-detect';
import SvgIcon from '@material-ui/core/SvgIcon';
import InfoIcon from '@material-ui/icons/Info';
import MediaIcon from '@material-ui/icons/VolumeOff';
import CloseIcon from '@material-ui/icons/Close';

import IconButton from '@material-ui/core/IconButton';


import BookmarkIcon from '@material-ui/icons/Bookmark';
import CheckIcon from '@material-ui/icons/Check';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';


import { Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import LoadingOverlay from 'react-loading-overlay';

import BlockedDisplay from '../StopWatch/BlockedDisplay';
import SubtitleDisplay from '../StopWatch/SubtitleDisplay';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import BounceLoader from 'react-spinners/BounceLoader';
import isEqual from "react-fast-compare";

import watchedTitle from '../../components/Database/database';
import imdb_icon from '../../assets/imdb_icon.png'

import './MovieInfoDialog.css';

function NetflixIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M140.803 258.904c-15.404 2.705-31.079 3.516-47.294 5.676l-49.458-144.856v151.073c-15.404 1.621-29.457 3.783-44.051 5.945v-276.742h41.08l56.212 157.021v-157.021h43.511v258.904zm85.131-157.558c16.757 0 42.431-.811 57.835-.811v43.24c-19.189 0-41.619 0-57.835.811v64.322c25.405-1.621 50.809-3.785 76.482-4.596v41.617l-119.724 9.461v-255.39h119.724v43.241h-76.482v58.105zm237.284-58.104h-44.862v198.908c-14.594 0-29.188 0-43.239.539v-199.447h-44.862v-43.242h132.965l-.002 43.242zm70.266 55.132h59.187v43.24h-59.187v98.104h-42.433v-239.718h120.808v43.241h-78.375v55.133zm148.641 103.507c24.594.539 49.456 2.434 73.51 3.783v42.701c-38.646-2.434-77.293-4.863-116.75-5.676v-242.689h43.24v201.881zm109.994 49.457c13.783.812 28.377 1.623 42.43 3.242v-254.58h-42.43v251.338zm231.881-251.338l-54.863 131.615 54.863 145.127c-16.217-2.162-32.432-5.135-48.648-7.838l-31.078-79.994-31.617 73.51c-15.678-2.705-30.812-3.516-46.484-5.678l55.672-126.75-50.269-129.992h46.482l28.377 72.699 30.27-72.699h47.295z" fill="#d81f26" />
    </SvgIcon>
  );
}

function ImdbIcon(props) {
  return (
    <img                    
      style={{ maxHeight: '20px', margin: '0px' }}
      src={imdb_icon}
      alt="imdb"
    />
  );
}

const owlurl = common.BASEURL + '/img/icons/' + 'owl.png';

const styles = theme => ({
  root: {
    overflow: 'hidden',
    overflowY: 'hidden',
    goFeedback: false,
    //backgroundColor: 'black',
    border: '10px solid white',
  },
  labelA: {
    color: 'black',
  },

  netflixbutton: {
    textTransform: 'none',
    background: '#cccccc',
    /*font-size: 18; */
    width: '60px',
    height: '38px',
    fontWeight: 'bold',
    marginLeft: '10px',
    marginRight: '10px',
    '&:hover': {
      background: '#eeeeee',
    },
  },

  ShowMuteButton: {
    backgroundColor: 'rgb(254,201,0)',
    padding: '10px 40px',
    marginBottom: '20px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'white',
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'rgb(254,201,0)',
      },
    },
  },
});

function ExpandIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="m467 0h-30c-8.285156 0-15 6.714844-15 15s6.714844 15 15 15h30c8.269531 0 15 6.730469 15 15v30c0 8.285156 6.714844 15 15 15s15-6.714844 15-15v-30c0-24.8125-20.1875-45-45-45zm0 0" />
      <path d="m497 422c-8.285156 0-15 6.714844-15 15v30c0 8.269531-6.730469 15-15 15h-30c-8.285156 0-15 6.714844-15 15s6.714844 15 15 15h30c24.8125 0 45-20.1875 45-45v-30c0-8.285156-6.714844-15-15-15zm0 0" />
      <path d="m75 482h-30c-8.269531 0-15-6.730469-15-15v-30c0-8.285156-6.714844-15-15-15s-15 6.714844-15 15v30c0 24.8125 20.1875 45 45 45h30c8.285156 0 15-6.714844 15-15s-6.714844-15-15-15zm0 0" />
      <path d="m173 482h-30c-8.285156 0-15 6.714844-15 15s6.714844 15 15 15h30c8.285156 0 15-6.714844 15-15s-6.714844-15-15-15zm0 0" />
      <path d="m241 0h-196c-24.8125 0-45 20.1875-45 45v196c0 24.8125 20.1875 45 45 45h196c24.8125 0 45-20.1875 45-45v-196c0-24.8125-20.1875-45-45-45zm15 241c0 8.269531-6.730469 15-15 15h-196c-8.269531 0-15-6.730469-15-15v-196c0-8.269531 6.730469-15 15-15h196c8.269531 0 15 6.730469 15 15zm0 0" />
      <path d="m347 422c-8.285156 0-15 6.714844-15 15s6.714844 15 15 15h90c.492188 0 .988281-.027344 1.480469-.074219.222656-.023437.441406-.066406.664062-.097656.261719-.039063.527344-.066406.785157-.117187.253906-.050782.5-.121094.75-.183594.222656-.058594.449218-.105469.671874-.175782.246094-.074218.484376-.164062.722657-.25.222656-.082031.445312-.152343.664062-.242187.226563-.09375.441407-.203125.660157-.308594.222656-.105469.449218-.203125.667968-.320312.210938-.113281.414063-.242188.621094-.367188.214844-.128906.429688-.25.640625-.386719.226563-.15625.441406-.324218.664063-.488281.171874-.128906.347656-.25.515624-.386719.765626-.628906 1.464844-1.328124 2.09375-2.09375.140626-.171874.261719-.355468.394532-.53125.160156-.214843.328125-.421874.476562-.648437.144532-.214844.269532-.4375.402344-.65625.117188-.199219.242188-.394531.355469-.597656.121093-.226563.222656-.460938.332031-.691407.097656-.210937.207031-.421874.296875-.640624.09375-.222657.167969-.457032.25-.683594.082031-.234375.171875-.460938.246094-.699219.070312-.230469.121093-.46875.179687-.703125.058594-.238281.125-.476562.175782-.71875.054687-.273438.085937-.550781.125-.824219.03125-.210937.070312-.417969.089843-.628906.050781-.496094.078125-.992187.078125-1.488281v-89.996094c0-8.285156-6.71875-15-15-15-8.285156 0-15 6.714844-15 15v53.785156l-104.398437-104.390625c-5.855469-5.859375-15.355469-5.859375-21.210938 0-5.859375 5.855469-5.859375 15.355469 0 21.210938l104.390625 104.394531zm0 0" />
      <path d="m271 482h-30c-8.285156 0-15 6.714844-15 15s6.714844 15 15 15h30c8.285156 0 15-6.714844 15-15s-6.714844-15-15-15zm0 0" />
      <path d="m369 482h-30c-8.285156 0-15 6.714844-15 15s6.714844 15 15 15h30c8.285156 0 15-6.714844 15-15s-6.714844-15-15-15zm0 0" />
      <path d="m339 30h30c8.285156 0 15-6.714844 15-15s-6.714844-15-15-15h-30c-8.285156 0-15 6.714844-15 15s6.714844 15 15 15zm0 0" />
      <path d="m497 324c-8.285156 0-15 6.714844-15 15v30c0 8.285156 6.714844 15 15 15s15-6.714844 15-15v-30c0-8.285156-6.714844-15-15-15zm0 0" />
      <path d="m15 384c8.285156 0 15-6.714844 15-15v-30c0-8.285156-6.714844-15-15-15s-15 6.714844-15 15v30c0 8.285156 6.714844 15 15 15zm0 0" />
      <path d="m497 226c-8.285156 0-15 6.714844-15 15v30c0 8.285156 6.714844 15 15 15s15-6.714844 15-15v-30c0-8.285156-6.714844-15-15-15zm0 0" />
      <path d="m497 128c-8.285156 0-15 6.714844-15 15v30c0 8.285156 6.714844 15 15 15s15-6.714844 15-15v-30c0-8.285156-6.714844-15-15-15zm0 0"></path>
    </SvgIcon>
  );
}

function ContractIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="m307 412c8.285156 0 15-6.714844 15-15v-53.785156l104.394531 104.390625c2.925781 2.929687 6.765625 4.394531 10.605469 4.394531s7.679688-1.464844 10.605469-4.394531c5.859375-5.855469 5.859375-15.355469 0-21.210938l-104.390625-104.394531h53.785156c8.285156 0 15-6.714844 15-15s-6.714844-15-15-15h-90c-.058594 0-.117188.007812-.179688.007812-.429687.007813-.863281.023438-1.296874.066407-.242188.023437-.476563.070312-.71875.105469-.242188.035156-.488282.0625-.730469.109374-.265625.054688-.523438.125-.78125.191407-.214844.054687-.429688.101562-.644531.164062-.25.078125-.496094.171875-.742188.261719-.214844.078125-.429688.148438-.644531.234375-.230469.097656-.449219.207031-.671875.3125s-.441406.203125-.65625.316406c-.214844.117188-.417969.246094-.628906.371094-.210938.128906-.425782.246094-.632813.386719-.230469.152344-.449219.324218-.667969.492187-.171875.125-.34375.242188-.511718.378907-.765626.628906-1.46875 1.332031-2.09375 2.097656-.140626.167968-.261719.347656-.390626.519531-.164062.21875-.332031.429687-.484374.65625-.140626.210937-.261719.429687-.390626.644531-.121093.207032-.25.40625-.363281.613282-.117187.222656-.21875.449218-.324219.675781-.101562.21875-.210937.433593-.304687.65625-.089844.21875-.164063.441406-.242187.664062-.085938.238281-.179688.476563-.253907.722657-.066406.21875-.113281.445312-.171875.671874-.0625.25-.132812.496094-.183594.75-.050781.257813-.082031.515626-.117187.777344-.035156.222656-.074219.445313-.097656.675782-.046875.476562-.070313.953124-.074219 1.433593v.042969 90c0 8.285156 6.714844 15 15 15zm0 0" />
      <path d="m45 286h20c8.285156 0 15-6.714844 15-15s-6.714844-15-15-15h-20c-8.269531 0-15-6.730469-15-15v-20c0-8.285156-6.714844-15-15-15s-15 6.714844-15 15v20c0 24.8125 20.1875 45 45 45zm0 0" />
      <path d="m15 80c8.285156 0 15-6.714844 15-15v-20c0-8.269531 6.730469-15 15-15h20c8.285156 0 15-6.714844 15-15s-6.714844-15-15-15h-20c-24.8125 0-45 20.1875-45 45v20c0 8.285156 6.714844 15 15 15zm0 0" />
      <path d="m128 286h30c8.285156 0 15-6.714844 15-15s-6.714844-15-15-15h-30c-8.285156 0-15 6.714844-15 15s6.714844 15 15 15zm0 0" />
      <path d="m15 173c8.285156 0 15-6.714844 15-15v-30c0-8.285156-6.714844-15-15-15s-15 6.714844-15 15v30c0 8.285156 6.714844 15 15 15zm0 0" />
      <path d="m221 30h20c8.269531 0 15 6.730469 15 15v20c0 8.285156 6.714844 15 15 15s15-6.714844 15-15v-20c0-24.8125-20.1875-45-45-45h-20c-8.285156 0-15 6.714844-15 15s6.714844 15 15 15zm0 0" />
      <path d="m128 30h30c8.285156 0 15-6.714844 15-15s-6.714844-15-15-15h-30c-8.285156 0-15 6.714844-15 15s6.714844 15 15 15zm0 0" />
      <path d="m271 113c-8.285156 0-15 6.714844-15 15v30c0 8.285156 6.714844 15 15 15s15-6.714844 15-15v-30c0-8.285156-6.714844-15-15-15zm0 0" />
      <path d="m221 286h20c24.8125 0 45-20.1875 45-45v-20c0-8.285156-6.714844-15-15-15s-15 6.714844-15 15v20c0 8.269531-6.730469 15-15 15h-20c-8.285156 0-15 6.714844-15 15s6.714844 15 15 15zm0 0" />
      <path d="m467 0h-139c-8.285156 0-15 6.714844-15 15s6.714844 15 15 15h139c8.269531 0 15 6.730469 15 15v422c0 8.269531-6.730469 15-15 15h-422c-8.269531 0-15-6.730469-15-15v-137c0-8.285156-6.714844-15-15-15s-15 6.714844-15 15v137c0 24.8125 20.1875 45 45 45h422c24.8125 0 45-20.1875 45-45v-422c0-24.8125-20.1875-45-45-45zm0 0"></path>
    </SvgIcon>
  );
}

class TVInfoDialog extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    videoWide: false,

    showMedia: false,
    seriesChoose: 1,
    episodeChoose: 1,
    episodeList: [],
    seriesList: [],
    blocked: {},
    blocked_live: {},
    subtitles: {},
    subtitles_times: [],
    loading: false,

    posSubtitles: 0, //Time position in movie
    lineSubtitles: 0, // Line in subtitles

    subsVisible: false,
    infoVisible: false,

    muteAudio: true,
    muteDevice: false,
    timelinePos: 0,

    timeShift: 0,
    timeStretch: 0,
    playClicked: false,
    summaryLoading: true,
    bookmark: false,

    titleClicked: 0,
    easterEggActive: false
  };

  componentDidMount() {
    ReactGA.initialize('UA-140786114-1');
    ReactGA.pageview('/TV');
    if (isMobile)
      this.setState({ videoWide: true });
    else
      this.setState({ videoWide: false });    
  }

  owlIcons = num => {
    num = num + 1;
    const items = [];
    for (var i = 1; i <= num; i++) {
      items.push(<img className="owl" src={owlurl} alt={owlurl} />);
    }

    return (
      <div aria-describedby="owlpop" className="owlAboutMD" onMouseOver={this.handleOwlClick}>
        {items}
      </div>
    );
  };




  summaryComplete = () => {
    this.setState({ summaryLoading: false });
  };

  PlayPressed = () => {
    //console.log('TVInfoDialog: PlayPressed ')
    if (!this.state.playClicked) {
      this.setState({ playClicked: true });
      if (this.props.currentUser.uid && this.props.movieSQL.imdbID && this.state.episodeChoose) {
        watchedTitle(
          this.props.currentUser.uid,
          this.props.movieSQL.imdbID,
          this.state.episodeChoose,
        );
      } else var fill = null;
      //console.log('TVInfoDialog: Cant update movie history values blank or NULL')
    }
  };

  Watched = () => {
    if (this.props.currentUser.uid && this.props.movieSQL.imdbID) {
      // check if title already watched
      watchedTitle(this.props.currentUser.uid, this.props.movieSQL.imdbID, null);
    }
  };

  Bookmark = (movieSQL) => {
    const datapack = { ...movieSQL, userID: this.props.currentUser.uid, tvID: null }
    this.props.addBookmarkTitle(datapack)

    /*if (this.props.currentUser.uid && this.props.movieSQL.imdbID) {
      // check if title already watched
      watchedTitle(this.props.currentUser.uid, this.props.movieSQL.imdbID, null);
    }*/
  }

  UnBookmark = (id) => {
    this.props.delBookmarkTitle(id)
  }

  

  TitleInBookmark = (title,bookmarks) => {
    //console.log'title check bookmark:' ,bookmarks, title)
    
    var check = bookmarks.find(item => item.imdbID == title)
    if (check) {
      //console.log'title found in bookmark:')
      return check.id
    }
    else {
      //console.log'title not found in bookmark:')
      return false
    }
  }


  bookmarkSelect = (movieSQL) => {
    //console.log'bookmarks: ',this.props.bookmarks, movieSQL.id)
    const val = this.TitleInBookmark(movieSQL.imdbID, this.props.bookmarks)
    if (val) {
      this.setState({bookmark:false})
      this.UnBookmark(val)
    }
    else {
      this.setState({bookmark:true})
      this.Bookmark(movieSQL)
    }
  }



  handleChangeSubs = name => event => {
    this.setState({ subsVisible: !this.state.subsVisible });
    //console.log('TVInfoDialog: handleChangeSubs')
  };

  ShowInfo = () => {
    this.setState({ infoVisible: !this.state.infoVisible });
  };

  ShowMedia = () => {
   
    if (this.props.movieSQL['imdbID'])
      this.updateSubtitles(this.state.episodeChoose)
    this.setState({ showMedia: !this.state.showMedia });

  };

  AdjustTime = pos => {
    /*var diff,newTimerStart;
    diff = this.state.timerTime - pos;
    newTimerStart = this.state.timerStart + diff;
    this.setState({timerStart: newTimerStart}) 
    //console.log('StopWatch, Got adjusted time:', pos, this.state.timerTime, this.state.timerStart ) */
    this.setState({ posSubtitles: pos });
    //console.log('StopWatch in TVInfoDialog, Got adjusted time:', pos)
  };

  ShiftTime = tshift => {
    this.setState({ timeShift: this.state.timeShift + tshift });
    //console.log('TVInfoDialog Shift:', tshift)
  };

  StretchTime = tstretch => {
    this.setState({ timeStretch: this.state.timeStretch + tstretch });
    //console.log('MovieInfoDialog Time Stretch :', this.state.timeStretch)
  };

  UpdateBlocked = bwords => {
    //console.log('TVInfoDialog, bwords:', bwords)
    var newdict = { words: bwords, runtime: this.state.blocked['runtime'] };
    this.setState({ blocked_live: newdict });
    //console.log('TVInfoDialog, blocked', newdict)
  };

  UpdateTimeline = timeSec => {
    this.setState({ timelinePos: timeSec });
  };

  UpdateSubtitleLine = line => {
    //console.log('StopWatch / TVInfoDialog, New Line pos:', line)
    this.setState({ lineSubtitles: line });
  };

  ShowSubtitles = () => {
    this.setState({ subsVisible: !this.state.subsVisible });
  };

  time_convert = num => {
    const hours = Math.floor(num / 60);
    const minutes = num % 60;
    return `${hours}h ${minutes}min`;
  };

  time_convert_hhmmss = num => {
    const hours = Math.floor(num / 60);
    const minutes = num % 60;
    if (hours < 10) var strhours = `0${hours}`;
    else strhours = `${hours}`;
    if (minutes < 10) var strmins = `0${minutes}`;
    else strmins = `${minutes}`;
    return `${strhours}:${strmins}:00`;
  };

  handleClose = () => {
    this.props.closeTVInfo();
    this.setState({ videoWide: false, summaryLoading: true });
    this.setState({ episodeChoose: -1, blocked: []})

    //console.log'Closing Dialog')
  };

  sendMute = () => {
    var muteURL = 'http://10.1.2.216:9876/send?deviceMac=34ea34e3d318&codeId=l1509876739759';
    fetch(muteURL)
      .then(response => {
        // Ensure service worker exists, and that we really are getting a JS file.
        const contentType = response.headers.get('content-type');
        if (
          response.status === 404 ||
          (contentType != null && contentType.indexOf('javascript') === -1)
        ) {
          //console.log ('Send MUTE comand to TV: 404 Error')
        } else {
          // Service worker found. Proceed as normal.
          //console.log ('Send MUTE command to TV: SUCCESS')
        }
      })
      .catch(() => {
        //console.log('Send MUTE comand to TV: No connecton');
      });
  };

  updateBlockedWords = episode => {
    //console.log("TVInfo: UPDATE BLOCKED FOR ", episode)

    this.setState({ summaryLoading: true });

    const urlBlocked = fetch(`${common.DJANGOURL}/api/findblocked/?type=tv&imdbid=${episode}`)
      .then(r => r.json())
      .then(data => {
        this.setState({ blocked: data });
        this.setState({ blocked_live: JSON.parse(JSON.stringify(data)) });

        //console.log("TVInfo Got blocked words: ",data);
        this.setState({ summaryLoading: false });
      })
      .catch(err => {
        //console.log(err)
        this.setState({ summaryLoading: false });
      });
  };
  

  updateSubtitleTimes = words_all => {
    var times = [];
    var hrs, min, sec, msec, start_totmsec, end_totmsec;
    var word_c, tdict;

    words_all.map(line => {
      if (
        !(
          line['line'].includes('Original Air Date') ||
          line['line'].includes('OpenSubtitles') ||
          line['line'].includes('Advertise your product') ||
          line['line'].includes('sync, corrected') ||
          line['line'].includes('<font')
        )
      ) {
        if (line['time'].length > 10) {
          hrs = Number(line['time'].slice(0, 2));
          min = Number(line['time'].slice(3, 5));
          sec = Number(line['time'].slice(6, 8));
          msec = Number(line['time'].slice(9, 12));

          start_totmsec = 1000 * (hrs * 3600 + min * 60 + sec) + msec;

          hrs = Number(line['time'].slice(17, 19));
          min = Number(line['time'].slice(20, 22));
          sec = Number(line['time'].slice(23, 25));
          msec = Number(line['time'].slice(26, 29));

          end_totmsec = 1000 * (hrs * 3600 + min * 60 + sec) + msec;

          tdict = {
            starttime: start_totmsec,
            endtime: end_totmsec,
            line: line['line'],
            selected: false,
          };
          times.push(tdict);
        }
      }
    });

    //times.sort((a, b) => (a.starttime > b.starttime) ? 1 : -1)
    //console.log('BUG: StopWatch: Time Stamps', times)

    return times;
  };

  /* Query subtitles */
  updateSubtitles = episode => {
    //console.log("TVInfo: UPDATE SUBTITLES FOR ", episode)

    this.setState({ summaryLoading: true });

    const urlBlocked = fetch(`${common.DJANGOURL}/api/subtitles/?type=tv&imdbid=${episode}`)
      .then(r => r.json())
      .then(data => {
        this.setState({ subtitles: data });
        this.setState({ subtitles_times: this.updateSubtitleTimes(data) });

        //console.log("BUG: TVInfo subtitles: ",data);
        this.setState({ loading: false });
      })
      .catch(err => {
        //console.log(err)
        this.setState({ loading: false });
      });
  };

  handleChangeCheckbox = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleTest = () => {
    setTimeout(() => this.sendMute(), 4000);
  };

  handleFeedback = () => {
    this.props.closeTVInfo();
    this.setState({ goFeedback: true });
  };

  handleSeriesChange = event => {
    //console.log('TVInfo handleSeriesChange', event.target.value)
    this.setState({ seriesChoose: event.target.value });
    //var EList =  this.props.tvSeriesEpisodes[event.target.value].map(el => el.episode)
    var EList = this.props.tvSeriesEpisodes[event.target.value];
    this.setState({ episodeList: EList });
    //console.log('TVInfo: EList.Length', EList.length)
    this.setState({ posSubtitles: 0, lineSubtitles: 0 });
    if (EList.length > 0) {
      var episode = EList[0].imdbID;
      //console.log('TVInfoDialog: Episodes', episode)
      this.setState({ episodeChoose: episode });
      this.updateBlockedWords(episode);
      //#DJ
      //this.updateSubtitles(episode);
    } else {
      this.setState({ episodeChoose: 0, episodeList: [] });
    }
    //console.log('TVInfo handleSeriesChange Episodes Array', this.state.episodeList)
  };

  handleEpisodeChange = event => {
    const episode = event.target.value;
    //console.log('TVInfo handleEpisodeChange', episode)
    this.setState({ episodeChoose: episode });
    this.updateBlockedWords(episode);
    //#DJ
    //this.updateSubtitles(episode);
    this.setState({ posSubtitles: 0, lineSubtitles: 0 });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  isEmpty = obj => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

    
  componentWillReceiveProps(nextProps, nextState) {
    //console.log"TVInfo: UPDATE ComponentWillReceiveProps ", nextProps)

    if ((nextProps.showTVInfo) && isEqual(nextProps.bookmarks,this.props.bookmarks)) {
      //console.log"TVInfo: ComponentWillReceiveProps Passed test updating")
      if (isMobile) this.setState({ videoWide: true });
      else this.setState({ videoWide: false });
      this.setState({summaryLoading: true})

      var SList = Object.keys(nextProps.tvSeriesEpisodes);
      if (!isEqual(SList,this.state.seriesList)) 
        this.setState({ seriesList: SList });

      //console.log"TVInfo: ComponentWillReceiveProps SList: ",SList)
      if (SList.length > 0) {
        var series = SList[0];
        this.setState({ seriesChoose: series });
        //var EList = nextProps.tvSeriesEpisodes[series].map(el=>el.episode)
        //console.log'Series: ', series, nextProps.tvSeriesEpisodes)
        var EList = nextProps.tvSeriesEpisodes[series];
        this.setState({ episodeList: EList });
        this.setState({ posSubtitles: 0, lineSubtitles: 0 });
        //console.log"TVInfo: ComponentWillReceiveProps EList: ",EList)
        if (EList.length > 0) {
          //console.log("TVInfo: UPDATE ComponentWillReceiveProps > 0 ")
          var episode = EList[0].imdbID;
          //console.log'episode check: ', episode, this.state.episodeChoose, nextProps.showTVInfo)
          if (episode != this.state.episodeChoose) {
            //console.log"TVInfo: ComponentWillReceiveProps updateblocked: ", episode, this.state.episodeChoose)
            this.setState({ episodeChoose: episode });
            this.updateBlockedWords(episode);
            //this.updateSubtitles(episode);
          }
        } else {
          this.setState({ episodeChoose: 0, episodeList: [] });
        }
      } else
        this.setState({ seriesChoose: 0, seriesList: [], episodeChoose: 0, episodeList: [] });
      
      

      //console.log("MovieInfo: imdbID: ", nextProps.movieSQL['imdbID'])
      var imdbID = nextProps.movieSQL['imdbID'];
    
      /* Fetch Subtitles for IMDBID */
      if (imdbID) {
        //console.log"MovieInfo: UPDATE ComponentWillReceiveProps when open: blocked  ", nextProps.blocked)
        if (this.TitleInBookmark(imdbID, nextProps.bookmarks)) {
          this.setState({bookmark:true})
          //console.log"Set bookmark true")
        }
        else {
          this.setState({bookmark:false})
          //console.log"Set bookmark false")
        }
        /* Create a copy of blocked words */
        //this.setState({ blocked: nextProps.blocked });
        //this.setState({ blocked_live: JSON.parse(JSON.stringify(nextProps.blocked)) });
      }

      this.setState({ showMedia: false, infoVisible: false, playClicked: false });
    }


  }

  Expand = () => {
    this.setState({ videoWide: !this.state.videoWide });
    //console.log('expand:', this.state.videoWide)
  };

  shouldComponentUpdate(nextProps, nextState) {
  
    //console.log('MovieInfoDialog: shouldComponentUpdate: ', arrayObjectsEqual(nextState.blocked, this.state.blocked))
    if (nextProps.showTVInfo != this.props.showTVInfo) {
      //console.log'TVInfoDialog: ComponentDidUpdate');
      return true
    }

    else if (
          nextProps.showTVInfo
          && !this.isEmpty(nextState.blocked) 
          && !this.isEmpty(nextProps.movie)
          && !this.isEmpty(nextProps.movieSQL)
          && ( !isEqual(nextProps, this.props) || !isEqual(nextState, this.state) )
        )
    {
      //console.log'TVInfoDialog: ComponentDidUpdate');
      return true;
    }
    else
      return false


  }

  easterEgg = () => {
    if (this.state.titleClicked >=4) {
      if (!this.state.easterEggActive ) {
        this.setState({easterEggActive: true, titleClicked:0})
      }
      if (this.state.easterEggActive) {
        this.setState({easterEggActive: false, titleClicked:0})
      }
    }
    else {
      this.setState({titleClicked:this.state.titleClicked+1})
    }
  
    //console.log('click title')
  };


  render() {
    const { currentUser, movie, movieSQL, credits, video, tvSeriesEpisodes } = this.props;
    const {
      seriesChoose,
      episodeChoose,
      seriesList,
      episodeList,
      blocked,
      subtitles,
      subtitles_times,
      blocked_live,
      posSubtitles,
      subsVisible,
      lineSubtitles,
      infoVisible,
      timeShift,
      showMedia,
      timeStretch,
    } = this.state;

    const { classes, onClose, ...other } = this.props;
    const { width } = this.props.size;

    //console.log ('TVInfoDialog Render: ', this.props)
    //console.log ('TVInfoDialog series: ', seriesList)

    var backgroundImg = '';
    var backwithPoster = '';
    var content = '';
    var title = '';
    var blockedWordList = [];
    var blockedWordRuntime = '00:00:00';
    var trailer = '';
    var age_restriction_set = [];
    var age_restriction_country = '';
    var fullScreen = false;
    var dialogPaper = '';
    var netflixLink = '';
    var movieID = movieSQL.imdbID
    var foundNetflix = false;
    var stars = '';


    if (movieSQL.title_total_ratings_set) {
      if (movieSQL.title_total_ratings_set.length > 0)
        stars = movieSQL.title_total_ratings_set[0].rating;
      else
        stars = 0
    }


    var timeFactor = 1;
    var ttMovieID = 'tt';
    if (movieID) {
      var zeros = 7 - movieID.toString().length;
      for (var j = 1; j <= zeros; j++) {
        ttMovieID = ttMovieID + '0';
      }
      ttMovieID = ttMovieID + movieID.toString();
    }

    var director = "Unknown"
    var imdbInfo = "https://www.imdb.com/title/" + ttMovieID
    var imdbParent = "https://www.imdb.com/title/" + ttMovieID + "/parentalguide"

    // Find the Director in the credits
    if ('crew' in credits) {
      for (var i = 0; i < credits.crew.length; i++) {

        if (credits.crew[i]['job'] == 'Director')
          director = credits.crew[i]['name']
      }
    }

    var netflixID = 0;
    if (movieSQL.netflix_set) {
      if (movieSQL.netflix_set.length > 0) {
        movieSQL.netflix_set.map((entry) => {
          if (entry.country == this.props.region.toUpperCase()) {
            netflixID = entry.netflixID;
            foundNetflix = true;
          }
        })
        if (foundNetflix)
          netflixLink = "https://netflix.com/watch/" + netflixID;
        else
          netflixLink = ""
      }
      else
        netflixLink = ""
    }
    else
      netflixLink = ""

      

    if (movieSQL.timing_data_set) {
      if (movieSQL.timing_data_set.length > 0) timeFactor = movieSQL.timing_data_set[0].stretch;
    }

    if (this.state.goFeedback) {
      return <Redirect to="/feedback" />;
    }

    if (isMobile) {
      fullScreen = true;
      //this.setState({videoWide:true})
      dialogPaper = {
        style: {
          backgroundColor: 'rgb(224,224,224)',
          boxShadow: 3,
        },
      };
    } else {
      fullScreen = false;
      //this.setState({videoWide:false})
      dialogPaper = {
        style: {
          backgroundColor: 'rgb(224,224,224)',
          boxShadow: 3,
          border: '1px solid white',
        },
      };
    }

    /*for (var entry of video) {
      if (entry.type == "Trailer")
          trailer = entry
    }*/

    if (video) {
      for (var i = 0; i < video.length; i++) if (video[i].type == 'Trailer') trailer = video[i];
    } else trailer = '';

    //console.log ('TVInfoDialog: movie:', movie, movieSQL)
    //console.log ('TVInfoDialog: Series:', seriesList)
    //console.log ('TVInfoDialog: Episodes:', episodeList)

    if (movie && movieSQL) {
      //console.log('movieSQL:', movieSQL)
      title = movie.title;
      movieSQL.age_rating_set.forEach(function (element) {
        if (element['country'] == 'US') {
          age_restriction_country = element['rating'];
        }
      });

      blockedWordList = blocked['words'];
      blockedWordRuntime = blocked['runtime'];

      if (Object.keys(movie).length > 0) {
        backgroundImg = {
          backgroundImage: `linear-gradient(rgba(240, 240, 240, 0.8) , rgba(240, 240, 240, 0.8)), url("https://image.tmdb.org/t/p/original/${movie.backdrop_path}")`,
        };

        backwithPoster = {
          backgroundImage: `linear-gradient(90deg, rgba(240, 240, 240, 0.8) 40%, rgba(240, 240, 240, 0.8) 60%), url("https://image.tmdb.org/t/p/original/${movie.poster_path}")`,
        };
        //console.log('Genres: ', movie.genres.length);
        //console.log('videos:', video);
      }

      content =
        Object.keys(movie).length > 0 ? (
          <div>
            {infoVisible ? (
              <div
                style={movie.backdrop_path !== null ? backgroundImg : backwithPoster}
                className="movie-background"
              >
                {!isMobile ? (
                  <div
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
                  >
                    {this.state.videoWide ? (
                      <IconButton
                        style={{ margin: '10px' }}
                        aria-label="Expand"
                        onClick={this.Expand}
                      >
                        <ContractIcon viewBox="0 0 512 512" style={{ fontSize: 25 }} />
                      </IconButton>
                    ) : (
                        <IconButton
                          style={{ margin: '10px' }}
                          aria-label="Expand"
                          onClick={this.Expand}
                        >
                          <ExpandIcon viewBox="0 0 512 512" style={{ fontSize: 25 }} />
                        </IconButton>
                      )}
                  </div>
                ) : null}

                {trailer ? (
                  <div className="video-outer">
                    <div className={this.state.videoWide ? 'video-wide' : 'video'}>
                      <iframe
                        src={`https://www.youtube.com/embed/${trailer.key}`}
                        title={trailer.name}
                      />
                    </div>
                  </div>
                ) : null}

                <div
                  className={
                    this.state.videoWide ? 'overview-container-wide' : 'overview-container'
                  }
                >
                  {credits || !('status_code' in credits) ? (
                    <p>
                      <span className="greyed">Starring: </span>
                      {credits.cast &&
                        credits.cast.map((cast, i) => {
                          if (i < 4) return <span key={cast.cast_id}>{cast.name}, </span>;
                          if (i === 4) return <span key={cast.cast_id}>{cast.name}</span>;
                          else return null;
                        })}
                    </p>
                  ) : null}

                  {movieSQL ? (
                    <p>
                      <span className="greyed">Genres: </span>
                      <span>{movieSQL.genres}</span>
                    </p>
                  ) : null}

                  {credits ? (
                    credits.crew.length > 0 ? (
                      <p>
                        <span className="greyed">Director: </span> {director}
                      </p>
                    ) : null
                  ) : null}

                  <p>
                    <span className="greyed">TMDb Rating: </span> {movie.vote_average}
                  </p>

                  <p>
                    <span className="greyed">Year: </span>{' '}
                    {new Date(movie.first_air_date).getFullYear()}
                  </p>
                  {/*<p>
                    <a href={imdbParent} target="_blank" > IMDB Parental guide </a>
                  </p>*/}




                </div>
                <div className="overview-container-description">
                  <p>{movie.overview}</p>
                </div>
              </div>
            ) : null}

            
          </div>
        ) : (
            <p style={{ textAlign: 'center' }}>Loading...</p>
          );
    }

    return (

      <Dialog
        className={classes.root}
        onClose={this.handleClose}
        maxWidth={'md'}
        open={this.props.showTVInfo}
        //maxWidth = {'800px'}
        fullScreen={fullScreen}
        PaperProps={dialogPaper}
        {...other}
      >
        {this.props.currentUser.isLoggedIn ? (
          <div className="markSectionTop">


            <IconButton
              style={{
                backgroundColor: 'rgb(224,224,224)',
                '&:hover': { backgroundColor: 'rgb(224,224,224)' },
                marginLeft: '50px',
                marginTop: '-15px'
              }}
              aria-label="Expand"
              onClick={() => this.bookmarkSelect(movieSQL)}
              marginLeft="150px"
            >
              {/*<BookmarkIcon style={{ color: 'rgb(254,201,0)', fontSize: 30 }}/>*/}
              {this.state.bookmark ?
                <BookmarkIcon style={{ color: 'rgb(254,201,0)', fontSize: 30 }} />
                :
                <BookmarkIcon style={{ color: '#aaaaaa', fontSize: 30 }} />}
            </IconButton>

          </div>) : null}

          

        <LoadingOverlay active={false} spinner>
          <div className={isMobile ? 'content-mobile' : 'content'}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <IconButton
                  style={{ margin: '10px', textTransform: 'none' }}
                  aria-label="Expand"
                  onClick={this.ShowInfo}
                >
                  <InfoIcon style={{ fontSize: 25 }} />
                </IconButton>
              </div>

              <div className="titleMD" onClick={this.easterEgg}>
                {age_restriction_country ? (
                  <h3 style={{ textAlign: 'center' }}>
                    {movieSQL.primaryTitle} ({age_restriction_country})
                  </h3>
                ) : (
                    <h3>{movieSQL.primaryTitle} </h3>
                  )}
              </div>

              <DialogActions>
                <IconButton
                  style={{ margin: '10px', textTransform: 'none' }}
                  onClick={this.handleClose}
                >
                  <CloseIcon style={{ fontSize: 25 }} />
                </IconButton>
              </DialogActions>
            </div>


            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingBottom: '20px',
              }}
            >
              {this.owlIcons(stars)}
            </div>



              {/*DJ MEDIA*/}
            {this.state.easterEggActive ? (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Button
                  className={classes.ShowMuteButton}
                  style={{
                    backgroundColor: 'rgb(254,201,0)',
                    '&:hover': { backgroundColor: 'white' },
                  }}
                  aria-label="Expand"
                  onClick={this.ShowMedia}
                  variant="contained"
                >
                  <MediaIcon marginLeft="10px" style={{ fontSize: 30 }} />
                  {showMedia ? 'Hide Muting System' : 'Show Muting System'}
                </Button>
              </div>
            ) : null}

             
            {content}
                
            <div className="imdbSection">
            <Button
                  style={{ margin: '10px', textTransform: 'none', height: '38px' }}
                  variant="contained"
                  href={imdbParent}
                  target='_blank'
                >
                  <ImdbIcon /> <span style={{marginLeft: '10px'}} >Parents Guide</span>
                </Button>
                {/*<Button
                  style={{ margin: '10px', textTransform: 'none', height: '38px' }}
                  variant="contained"
                  href={imdbInfo}
                  target='_blank'
                >
                  <ImdbIcon /> <span style={{marginLeft: '10px'}} >Info</span>
                </Button>*/}
            </div>

            <div className="SeriesEpisodes">
              <div className="seriesList">
                {seriesList ? (
                  <Select
                    value={seriesChoose}
                    onChange={this.handleSeriesChange}
                    inputProps={{
                      name: 'series',
                      id: 'series-simple',
                    }}
                  >
                    {seriesList.map(key => (
                      <MenuItem value={key}>
                        <em>Season {key}</em>
                      </MenuItem>
                    ))}
                  </Select>
                ) : null}
              </div>

              <div className="episodeList">
                {episodeList ? (
                  <Select
                    value={episodeChoose}
                    onChange={this.handleEpisodeChange}
                    inputProps={{
                      name: 'episode',
                      id: 'episode-simple',
                    }}
                  >
                    {episodeList.map(key => (
                      <MenuItem value={key.imdbID}>
                        <em>Episode {key.episode}</em>
                      </MenuItem>
                    ))}
                  </Select>
                ) : null}
              </div>
            </div>

            {blockedWordList && showMedia ? (
              <div className="blockedWords">
                <div className={showMedia ? 'Media-visible' : 'Media-invisible'}>
                  <BlockedDisplay
                    words={blocked_live['words']}
                    subset={
                      JSON.stringify(this.state.blocked) != JSON.stringify(this.state.blocked_live)
                    }
                    lineChange={this.UpdateSubtitleLine}
                    timelineChange={this.UpdateTimeline}
                    PlayPressed={this.PlayPressed}
                    ShowSubs={this.ShowSubtitles}
                    pos={posSubtitles}
                    timeShift={timeShift}
                    timeStretch={timeStretch}
                    subtitles_times={subtitles_times}
                    TBefore={2000}
                    TAfter={2000}
                    audioOn={this.state.muteAudio}
                    tvOn={this.state.muteDevice}
                    timeFactor={timeFactor}
                  ></BlockedDisplay>

                  <FormGroup row>
                    <FormControlLabel
                      classes={{ label: classes.labelA }}
                      control={
                        <Checkbox
                          checked={this.state.muteAudio}
                          onChange={this.handleChangeCheckbox('muteAudio')}
                          value="muteAudio"
                          color="default"
                        />
                      }
                      label="Audio before Mute"
                    />
                    <FormControlLabel
                      classes={{ label: classes.labelA }}
                      control={
                        <Checkbox
                          checked={this.state.muteDevice}
                          onChange={this.handleChangeCheckbox('muteDevice')}
                          value="muteDevice"
                          color="default"
                        />
                      }
                      label="mute TV"
                    />

                    <FormControlLabel
                      classes={{ label: classes.labelA }}
                      control={
                        <Switch
                          checked={this.state.subsVisible}
                          onChange={this.handleChangeSubs('subsShow')}
                          color="default"
                        />
                      }
                      label="Show subtitles & Sync"
                    />
                  </FormGroup>

                  {/*<TextField
                    classes={{ label: classes.labelA }}
                    id="stretch"
                    label="Stretch"
                    value={timeFactor}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />*/}

                  <SubtitleDisplay
                    subtitles_times={subtitles_times}
                    OnTimeChange={this.AdjustTime}
                    OnTimeShift={this.ShiftTime}
                    OnTimeStretch={this.StretchTime}
                    line={lineSubtitles}
                    subs={subsVisible}
                  ></SubtitleDisplay>
                </div>
              </div>
            ) : null}

           

            <LoadingOverlay
              active={this.state.summaryLoading}
              styles={{
                overlay: base => ({
                  ...base,
                  background: 'rgba(100,100,100, 0.3)',
                }),
              }}
              spinner={<BounceLoader />}
            >
              <div className="blockedWords2">
                {blockedWordList ? (
                  <MovieSummaryWrapped
                    words={blocked['words']}
                    OnChange={this.UpdateBlocked}
                    runtime={blocked['runtime']}
                    OnRendered={this.summaryComplete}
                    //timelinePos = {this.state.timelinePos}
                    //runtime ={this.time_convert_hhmmss(movie.runtime)}
                    title={title}
                  />
                ) : null}
              </div>
            </LoadingOverlay>

            <div className="bottomButtons">
              {/*!isMobile? (
              <div>
                {this.owlIcons(stars)}
            </div>
            ):null*/}
              {/*this.props.currentUser.isLoggedIn ? (
                <Button
                  style={{
                    margin: '10px',
                    textTransform: 'none',
                    backgroundColor: 'rgb(254,201,0)',
                  }}
                  onClick={this.Watched}
                >
                  Add
                </Button>
              ) : (
                  <div></div>
              )*/}
              {netflixLink ? (
                <Button
                  style={{ margin: '10px', textTransform: 'none', height: '38px' }}
                  variant="contained"
                  href={netflixLink}
                  target='_blank'
                >

                  <NetflixIcon viewBox="0 0 1025 276" style={{ fontSize: 45 }} />
                </Button>
              ) : <div></div>}
              <DialogActions>


                <Button
                  style={{ margin: '10px', textTransform: 'none' }}
                  onClick={this.handleFeedback}
                  variant="contained"
                >
                  Feedback
                </Button>

                <Button
                  style={{ margin: '10px', textTransform: 'none' }}
                  onClick={this.handleClose}
                  variant="contained"
                >
                  Close
                </Button>
              </DialogActions>
            </div>


          </div>
        </LoadingOverlay>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.currentUser,
    movie: state.movies_redux.movie,
    movieSQL: state.movies_redux.movieSQL,
    credits: state.movies_redux.credits,
    video: state.movies_redux.video,
    blocked: state.movies_redux.blocked,
    tvSeriesEpisodes: state.movies_redux.tvSeriesEpisodes,
    showTVInfo: state.movies_redux.showTVInfo,
    bookmarks: state.movies_redux.bookmarks,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeTVInfo: () => dispatch(closeTVInfo()),
    addBookmarkTitle: content => dispatch(addBookmarkTitle(content)),
    delBookmarkTitle: content => dispatch(delBookmarkTitle(content)),
    setTVID: content => dispatch(setTVID(content))
  };
};

const WrapTVInfoDialog = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(TVInfoDialog));

export default sizeMe()(WrapTVInfoDialog);
