import React, { Component } from 'react';

import ScrollMenu from 'react-horizontal-scrolling-menu';
import ContentLoader from "react-content-loader"
//import ScrollMenu from '../../../components/Widgets/ScrollMenu/scrollMenu.tsx'
import WrapSingleMovie from './SingleMovie.js';
import axios from 'axios';
import * as common from '../../../common.js';
import { isMobile } from 'react-device-detect';
import LoadingOverlay from 'react-loading-overlay';

import IconButton from '@material-ui/core/IconButton';
import SwipeableHook from './SwipeableHook.js';
import {
  addBookmarkTitle,
  delBookmarkTitle,
  initBookmarkTitle
} from '../../../redux/actions/index';
import { connect } from 'react-redux';
import isEqual from "react-fast-compare";
import './Lists.css'

const RIGHT = '-1';
const LEFT = '+1';
const MAXTITLES = 50;

const Arrow = ({ text, className }) => {
  return <div className={className}>{text}</div>;
};

const ArrowRightSVG = ({ size, className }) => {
  return (
    <div className={className}>
      <svg height="16" width="16">
        <polygon points="0,0 16,8 0,16" fill="darkgrey" />
      </svg>
    </div>
  );
};

const ArrowLeftSVG = ({ size, className }) => {
  return (
    <div className={className}>
      <svg height="16" width="16">
        <polygon points="16,0 0,8 16,16" fill="darkgrey" />
      </svg>
    </div>
  );
};

function ArrowLeftSVGF() {
  return (
    <div className={'arrow-prev'}>
      <svg height="16" width="16">
        <polygon points="16,0 0,8 16,16" fill="darkgrey" />
      </svg>
    </div>
  );
}

function ArrowRightSVGF() {
  return (
    <div className={'arrow-prev'}>
      <svg height="16" width="16">
        <polygon points="0,0 16,8 0,16" fill="darkgrey" />
      </svg>
    </div>
  );
}

const styles = theme => ({
  horizontalScroll: {
    overflowY: 'scroll',
    overflowX: 'scroll',
    overflow: 'scroll',
    zIndex: 0,
    scrollBehavior: 'smooth',
  },
});

const persistSyntheticEvent = (func, persist) => {
  return (e, ...rest) => {
    if (persist && e.persist) e.persist();
    return func(e, ...rest);
  };
};

//const ArrowLeft = Arrow({ text: "<", className: "arrow-prev" });
//const ArrowRight = Arrow({ text: ">", className: "arrow-next" });
const ArrowLeft = ArrowLeftSVG({ size: 100, className: 'arrow-prev' });
const ArrowRight = ArrowRightSVG({ size: 100, className: 'arrow-next' });

const DIRECTIONS = ['Left', 'Right', 'Up', 'Down'];



class ListHistory extends Component {
  constructor(props) {
    super(props);
    this.menu1 = null;
    this.mounted = false;
    this.showMovieInfoPrevious = false;
  }

  state = {
    themovies: [],
    movie: {},
    showInProcess: false,
    loading: false,
    lastItemVisible: false,
    firstItemVisible: false,
    inertiascrolling: true,
    slowdownFactor: 0.7,
    titleCount: 50,
    pendingRightShift: false,
    reachedLastTitle: false,
    totalTitlesFetched: 0,
    newSearch: false,
    swipeDirection: 'none',
    persistEvent: true,
  };



  /*SearchTitle = (userID, titleCount, lazyLoad, newSearch) => {
    this.setState({
      themovies: this.props.bookmarks,
      loading: false,
      pendingRightShift: false,
      totalTitlesFetched: this.props.bookmarks.length,
      titleCount: 10,
      newSearch: true,
    })
  }*/


  componentDidMount() {
    //console.log('ListHistory.js component mount')
    this.mounted = true;
    this.showMovieInfoPrevious = this.props.showMovieInfo;
    this.setState({
      themovies: this.props.bookmarks,
      loading: false,
      pendingRightShift: false,
      totalTitlesFetched: this.props.bookmarks.length,
      titleCount: 50,
      newSearch: true,
    })

    //this.SearchTitle(this.props.userID, 10, false, true);
  }

  componentWillUnmount() {
    //console.log('List.js component unmount')
    this.mounted = false;
  }

  componentWillReceiveProps(nextProps) {
    //console.log('Received props: ', nextProps)
    this.setState({
      themovies: nextProps.bookmarks,
      loading: false,
      pendingRightShift: false,
      totalTitlesFetched: nextProps.bookmarks.length,
      titleCount: 50,
      newSearch: true,
    })
  }


  checkArrowVisibilityCondition = () => {
    //console.log('check Arrow Visibility')
    setTimeout(() => {
      var newLastVisible = this.menu1.checkFirstLastItemVisibility({})['lastItemVisible'];
      this.setState({ lastItemVisible: newLastVisible });
    }, 500);
  }

  LeftArrowProcess = () => {
    this.checkArrowVisibilityCondition()
  };

  TitlesClickLeftArrow = () => {
    //console.log('Search: Titlesclickleftarrow')
    //console.log('Search: Met condition Titlesclickleftarrow')
    this.menu1.handleArrowClick();
    this.LeftArrowProcess();
  };

  RightArrowProcess = () => {
    this.checkArrowVisibilityCondition()
  };

  TitlesClickRightArrow = () => {
    //console.log'List.js: Titlesclickrightarrow: ', this.menu1)
    //console.log('Search: Met condition Titlesclickleftarrow')
    this.menu1.handleArrowClickRight();
    this.RightArrowProcess();
  };



  shouldComponentUpdate(nextProps, nextState) {
    //console.log('ListHistory.js shouldComponentUpdate', nextState, this.state);
    //console.log('List.js shouldComponentUpdate' );
    if (this.mounted) return true;
    /*if (!isEqual(nextState.themovies, this.state.themovies) && (this.mounted))
      return true;*/
    /*if (this.state.themovies === nextState.themovies) {
      return false;
    } else {
      return true;
    }*/
  }

  buildBlankMovie = index => {
    var rtime = Math.floor(Math.random() * 4) + 1;

    //console.log('buildBlankMovie index: ',index)
    return (

      <div className={((index == 1) && (this.state.themovies.length > 0)) ? "menu-item-first-loader" : "menu-item"} key={index}>
        <ContentLoader
          speed={rtime}
          width={150}
          height={225}
          viewBox="0 0 150 225"
          backgroundColor="#222222"
          foregroundColor="#000000"

        >
          <rect x="0" y="0" rx="2" ry="2" width="150" height="225" />
        </ContentLoader>
      </div>
    );
  };




  render() {
    const { persistEvent, loading } = this.state;

    //console.log('List.js movies: ', this.state.loading, this.state.themovies)
    //console.log ('ListHistory: ', this.props)

    const mlen = this.state.themovies.length;
    const movieEmpty = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    var movieData = [];
    const movieFull = this.state.themovies.map((movie, index) => {
      //console.log('ListHistory.js: movie ', movie);
      if (movie)
        if (index == mlen - 1) {
          //console.log('List.js reached last movie: ', movie,index)
          return (
            <div className="menu-item-last" key={movie.imdbID}>
              <WrapSingleMovie movie={movie} netflix_region={this.props.region} />
            </div>
          );
        } else {
          return (
            <div className="menu-item" key={movie.imdbID}>
              <WrapSingleMovie movie={movie} netflix_region={this.props.region} />
            </div>
          );
        }
      else {
        return (
          <div className="menu-item"></div>
        )
      }
    });

    const movieLoading = movieEmpty.map(index => {
      return this.buildBlankMovie(index);
    });

    //if (this.state.themovies.length > 0)
    //console.log('List.js: movieFull: ', movieFull)
    if (!loading) {
      movieData = movieFull;
      //console.log('List.js: movieData not loading ', movieData)
    } else {
      //movieData = movieLoading;

      if (movieFull.length > 0) movieData = movieFull.concat(movieLoading);
      else movieData = movieLoading;
      //console.log('List.js: movieData loading ', movieData)
    }


    return (
      <div>

        {(movieData.length > 0) ? <div>
          {isMobile ? (
            <div style={{ marginTop: '40px' }}>
              <h4 style={{ marginLeft: '10px', color: 'black' }}>{this.props.heading}</h4>
              <ScrollMenu
                ref={el => {
                  this.menu1 = el;
                }}
                data={movieData}
                wheel={false}
                dragging={true}
                alignCenter={false}
                clickWhenDrag={false}
                innerWrapperStyle={{ display: 'flex', alignItems: 'center' }}
                inertiaScrolling={true}
                inertiaScrollingSlowdown={0.7}
                transition={1}

              />
            </div>
          ) : (
            <div className="lists-block">
              <h4>{this.props.heading}</h4>

              <div className="lists-layout">
                <div className="lists-button">
                  <IconButton
                    style={{ backgroundColor: '#dddddd' }}
                    aria-label="Expand"
                    onClick={this.TitlesClickLeftArrow}
                  >
                    <ArrowLeftSVGF />
                  </IconButton>
                </div>

                <div className="lists-scrollmenu">
                  <ScrollMenu
                    ref={el => {
                      this.menu1 = el;
                    }}
                    translate={0}
                    data={movieData}
                    wheel={false}
                    dragging={false}
                    alignCenter={false}
                    innerWrapperStyle={{ display: 'flex', alignItems: 'center' }}
                    clickWhenDrag={false}

                  />
                </div>

                <div className="button">
                  {!(this.state.lastItemVisible) && (
                    <IconButton
                      style={{ backgroundColor: '#dddddd' }}
                      aria-label="Expand"
                      onClick={this.TitlesClickRightArrow}
                    >
                      <ArrowRightSVGF />
                    </IconButton>
                  )}
                </div>
              </div>
            </div>
          )}

        </div> : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    bookmarks: state.movies_redux.bookmarks,
  };
};


const mapDispatchToProps = dispatch => {
  return {
    addBookmarkTitle: content => dispatch(addBookmarkTitle(content)),
    delBookmarkTitle: content => dispatch(delBookmarkTitle(content)),
    initBookmarkTitle: content => dispatch(initBookmarkTitle(content)),
  };
};

const WrapListHistory = connect(mapStateToProps, mapDispatchToProps)(ListHistory);
export default WrapListHistory
