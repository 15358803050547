// MOVIE INFO

export const SHOW_MOVIEINFO = 'SHOW_MOVIEINFO';
export const SHOW_TVINFO = 'SHOW_TVINFO';
export const CLOSE_MOVIEINFO = 'CLOSE_MOVIEINFO';
export const CLOSE_TVINFO = 'CLOSE_TVINFO';
export const SET_MOVIEID = 'SET_MOVIEID';
export const SET_TVID = 'SET_TVID';
export const SET_MOVIEINFO = 'SET_MOVIEINFO';
export const SET_TVINFO = 'SET_TVINFO';
export const SET_CREDITSINFO = 'SET_CREDITSINFO';
export const SET_BLOCKEDINFO = 'SET_BLOCKEDINFO';
export const SET_VIDEOINFO = 'SET_VIDEOINFO';
export const SET_MOVIESQLINFO = 'SET_MOVIESQLINFO';
export const SET_TVSERIESINFO = 'SET_TVSERIESINFO';
export const ADD_BOOKMARKTITLE = 'ADD_BOOKMARKTITLE';
export const DEL_BOOKMARKTITLE = 'DEL_BOOKMARKTITLE';
export const INIT_BOOKMARKTITLE = 'INIT_BOOKMARKTITLE';
export const ADD_BOOKMARKTITLE_SUCCESS = 'ADD_BOOKMARKTITLE_SUCCESS';
export const ADD_BOOKMARKTITLE_FAILURE = 'ADD_BOOKMARKTITLE_FAILURE';
export const ZERO_BOOKMARKTITLE = 'ZERO_BOOKMARKTITLE';


// STARTUP
export const STARTUP_REQUEST = 'STARTUP_REQUEST';
export const STARTUP_SUCCESS = 'STARTUP_SUCCESS';
export const STARTUP_FAILURE = 'STARTUP_FAILURE';
export const SCREEN_RESIZE = 'SCREEN_RESIZE';

// USER AUTH

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';

export const USER_FETCH_REQUEST = 'USER_FETCH_REQUEST';
export const USER_FETCH_SUCCESS = 'USER_FETCH_SUCCESS';
export const USER_FETCH_FAILURE = 'USER_FETCH_FAILURE';

export const USERDB_FETCH_REQUEST = 'USERDB_FETCH_REQUEST';
export const USERDB_FETCH_SUCCESS = 'USERDB_FETCH_SUCCESS';
export const USERDB_FETCH_FAILURE = 'USERDB_FETCH_FAILURE';
export const USERDB_FETCH_SUCCESS_WITH_FILTER = 'USERDB_FETCH_SUCCESS_WITH_FILTER';
export const USERDB_ADD_SUCCESS = 'USERDB_ADD_SUCCESS'


export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_FAILURE = 'USER_LOGOUT_FAILURE';

export const USER_LOGIN_WITH_PROVIDER_REQUEST = 'USER_LOGIN_WITH_PROVIDER_REQUEST';
export const USER_LOGIN_WITH_PROVIDER_SUCCESS = 'USER_LOGIN_WITH_PROVIDER_SUCCESS';
export const USER_LOGIN_WITH_PROVIDER_FAILURE = 'USER_LOGIN_WITH_PROVIDER_FAILURE';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE';

export const PASSWORD_UPDATE_SUCCESS = 'PASSWORD_UPDATE_SUCCESS';
export const PASSWORD_UPDATE_FAILURE = 'PASSWORD_UPDATE_FAILURE';

export const USER_FILTER_UPDATE = 'USER_FILTER_UPDATE';
export const USER_FILTER_SAVE = 'USER_FILTER_SAVE';
export const USER_FILTER_ADD = 'USER_FILTER_ADD';
export const USER_FILTER_DEL = 'USER_FILTER_DEL';
export const USER_FILTER_SELECT = 'USER_FILTER_SELECT';


export const USER_FILTER_ADD_REQUEST = 'USER_FILTER_ADD_REQUEST';
export const USER_FILTER_ADD_SUCCESS = 'USER_FILTER_ADD_SUCCESS';
export const USER_FILTER_ADD_FAILURE = 'USER_FILTER_ADD_FAILURE';

export const COUNTRY_FETCH_SUCCESS = 'COUNTRY_FETCH_SUCCESS';
export const COUNTRY_FETCH_FAILURE = 'COUNTRY_FETCH_FAILURE';

export const SET_REGION = 'SET_REGION';

export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_WITH_PROVIDER_SUCCESS = 'USER_REGISTER_WITH_PROVIDER_SUCCESS';
export const USER_REGISTER_WITH_PROVIDER_FAILURE = 'USER_REGISTER_WITH_PROVIDER_FAILURE';


// FIREBASE ACTIONS

export const REGISTER_FIREBASE_USER_WITH_PROVIDER = 'REGISTER_FIREBASE_USER_WITH_PROVIDER';
export const REGISTER_FIREBASE_USER = 'REGISTER_FIREBASE_USER';

export const LOGIN_WITH_PROVIDER_FIREBASE = 'LOGIN_WITH_PROVIDER_FIREBASE';

export const LOGIN_FIREBASE_USER = 'LOGIN_FIREBASE_USER';
export const FETCH_FIREBASE_USER = 'FETCH_FIREBASE_USER';
export const UPDATE_FIREBASE_USER = 'UPDATE_FIREBASE_USER';
export const CHANGE_FIREBASE_USER_PASSWORD = 'CHANGE_FIREBASE_USER_PASSWORD';
export const FIREBASE_PASSWORD_RESET_EMAIL = 'FIREBASE_PASSWORD_RESET_EMAIL';
export const LOGOUT_FIREBASE_USER = 'LOGOUT_FIREBASE_USER';

// DB ACTIONS
export const USER_UPDATE_DATABASE = "USER_UPDATE_DATABASE"